/*
 *
 * App reducer
 *
 */

import filter from 'lodash/filter'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import mapValues from 'lodash/mapValues'
import includes from 'lodash/includes'
import head from 'lodash/head'
import some from 'lodash/some'
import {
  BULKSEARCH_DELETE_LESSON,
  BULKSEARCH_SET_BOOKING_STARTED,
  BULKSEARCH_SET_DATE,
  BULKSEARCH_SET_DATELIST,
  BULKSEARCH_SET_MONTH,
  BULKSEARCH_SET_PARAMS,
  BULKSEARCH_SET_PROGRAM,
  BULKSEARCH_SET_SEARCH_RESULTS,
  BULKSEARCH_SET_SEARCH_UNITS,
  BULKSEARCH_SET_SELECTED,
  BULKSEARCH_SET_SESSIONS,
  BULKSEARCH_SET_SELECTED_SESSION,
  BULKSEARCH_SET_SESSIONS_SELECTED,
} from '../actions/bulksearch'
import { BookingSession } from '@interfaces/SearchSessions'

export const initialState = {
  params: false,
  sessions: false,
  dateList: false,
  selected: false,
  month: false,
  date: false,
  program: false,
  searchResults: false,
  searchUnits: false,
  bookingStarted: false,
  sessionsSelected: false,
}

function bulksearchReducer(state = initialState, action) {
  switch (action.type) {
    case BULKSEARCH_SET_SESSIONS:
      return {
        ...state,
        sessions: action.sessions,
      }

    case BULKSEARCH_SET_DATELIST:
      return {
        ...state,
        dateList: action.dateList,
      }

    case BULKSEARCH_SET_PARAMS:
      return {
        ...state,
        params: action.params,
      }

    case BULKSEARCH_SET_SELECTED:
      return {
        ...state,
        selected: action.selected,
      }

    case BULKSEARCH_SET_MONTH:
      return {
        ...state,
        month: action.month,
      }

    case BULKSEARCH_SET_DATE:
      return {
        ...state,
        date: action.date,
      }

    case BULKSEARCH_DELETE_LESSON:
      return {
        ...state,
        // @ts-ignore
        sessions: mapValues(state.sessions, (values, key) => {
          if (key === action.month) {
            return filter(values, value => !isEqual(value, get(action.schedules, '[0]')))
          }

          return values
        }),
      }

    case BULKSEARCH_SET_PROGRAM:
      return {
        ...state,
        program: action.program,
      }

    case BULKSEARCH_SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: {
          ...action.searchResults,
          Available: [
            ...action.searchResults.Available.map(data => {
              const duplicates = action.searchResults.Available.filter(
                ({ StartDatetime }) => StartDatetime === data.StartDatetime
              )
              const headDuplicate: BookingSession | undefined = head(duplicates)

              return {
                ...data,
                selected:
                  duplicates.length && some(duplicates, ['StartDatetime', data.StartDatetime])
                    ? headDuplicate && (headDuplicate.SessionId || '') === data.SessionId
                    : true,
              }
            }),
          ],
        },
      }

    case BULKSEARCH_SET_SEARCH_UNITS:
      return {
        ...state,
        searchUnits: action.searchUnits,
      }

    case BULKSEARCH_SET_BOOKING_STARTED:
      return {
        ...state,
        bookingStarted: action.bookingStarted,
      }

    case BULKSEARCH_SET_SELECTED_SESSION:
      return {
        ...state,
        // @ts-ignore
        sessions: mapValues(state.sessions, (values: BookingSession[] = [], key) => {
          if (key === action.month) {
            return values.map(data => {
              if (includes(action.schedules, data.SessionId)) {
                return {
                  ...data,
                  selected: data.SessionId === action.SessionId,
                }
              }

              return data
            })
          }

          return values
        }),
      }

    case BULKSEARCH_SET_SESSIONS_SELECTED:
      return {
        ...state,
        sessionsSelected: action.sessionsSelected,
      }

    default:
      return state
  }
}

export default bulksearchReducer
