import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks'
import AtRiskCheck from '@components/AtRiskCheck'
import GlobalModalCheck from '@components/GlobalModalCheck'
import { ModalProvider } from '@components/Modal'
import PageOverlay from '@components/PageOverlay'
import TimezoneCheck from '@components/TimezoneCheck'
import { ToasterProvider } from '@components/Toaster'
import ErrorBoundary from '@hocs/ErrorBoundary'
import AutoRaiseCase from '@layouts/Scheduling/AutoRaiseCase'
import { getClient } from '@utils/aws-graphql'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import Router from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { Provider } from './Context'
import PortalLayout from './PortalLayout'
import UnsavedModal from './UnsavedModal'
import moment from 'moment-timezone'

NProgress.configure({
  showSpinner: false,
})

const removeBodyStyling = () => {
  document.querySelector('body')?.removeAttribute('class')
  document.querySelector('body')?.removeAttribute('style')
}

Router.events.on('routeChangeStart', () => {
  removeBodyStyling() // modals leave styling in the body which causes scrolling issues
  NProgress.start()
})
Router.events.on('routeChangeError', () => NProgress.done())
Router.events.on('routeChangeComplete', () => {
  removeBodyStyling() // modals leave styling in the body which causes scrolling issues
  NProgress.done()
})
interface PageLayoutProps {
  children: React.ReactElement
}

interface PageLayoutState {
  isFormDirty: boolean
  modalOpen: boolean
  path: string
  dynamicPath?: string
  client: any
}
class PageLayout extends React.PureComponent<PageLayoutProps, PageLayoutState> {
  constructor(props: PageLayoutProps) {
    super(props)
    this.state = {
      isFormDirty: false,
      modalOpen: false,
      path: '/',
      client: null,
      dynamicPath: undefined,
    }
    // always cleanup unsave modal flags after route change
    Router.events.on('routeChangeComplete', () => {
      this.state.isFormDirty && this.setState({ isFormDirty: false, modalOpen: false })
    })
  }

  async componentDidMount() {
    const apollo = await getClient()
    this.setState({
      client: apollo,
    })

    const cache = new InMemoryCache()

    // TODO: add condition when storage is same as cache to avoid redux-persists errors
    await persistCache({
      cache,
      // @ts-ignore
      storage: window.localStorage,
    })
  }

  setFormDirtyState = (isFormDirty) => {
    this.setState({ isFormDirty })
  }

  displayUnsavedModal = (path, dynamicPath) => {
    this.setState({ modalOpen: true, path, dynamicPath })
  }

  handleModalDontSave = () => {
    const { path, dynamicPath } = this.state
    this.setState({ isFormDirty: false, modalOpen: false })
    if (path && path !== 'back') {
      Router.push(dynamicPath || path, path)
    } else if (path === 'back') {
      Router.back()
    }
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false })
  }

  configureCustomTz = () => {
    moment.tz.link('America/Cuiaba|America/Chile/Santiago')
  }

  render() {
    const { isFormDirty, modalOpen, client } = this.state
    const { children } = this.props
    this.configureCustomTz()
    const value = {
      isFormDirty,
      setFormDirtyState: this.setFormDirtyState,
      displayUnsavedModal: this.displayUnsavedModal,
    }

    if (!client) {
      return <PageOverlay />
    }

    return (
      <ApolloProvider client={client}>
        <ApolloHookProvider client={client}>
          <Provider value={value}>
            <PortalLayout>
              <ToasterProvider>
                <ModalProvider>
                  <ErrorBoundary>{children}</ErrorBoundary>
                  <AutoRaiseCase />
                  <GlobalModalCheck />
                  <UnsavedModal
                    open={modalOpen}
                    onDontSave={this.handleModalDontSave}
                    onClose={this.handleModalClose}
                  />
                </ModalProvider>
              </ToasterProvider>
            </PortalLayout>
          </Provider>
        </ApolloHookProvider>
      </ApolloProvider>
    )
  }
}

export default PageLayout
