import React from 'react'
import { useIntl } from 'react-intl'

export interface IFormattedHTMLMessage {
  id: string
  defaultMessage: string
  values?: object
  ParentComponent?: React.FC
}

const DefaultDiv: React.FC<{ dangerouslySetInnerHTML: { __html: string } }> = (props) => <div {...props}></div>

const FormattedHTMLMessage: React.FC<IFormattedHTMLMessage> = ({
  id,
  defaultMessage,
  values,
  ParentComponent = DefaultDiv,
}) => {
  const intl = useIntl()
  const convert = (data) => {
    if (Array.isArray(data)) {
      return data.map((text) => text.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>'))
    }

    return data.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>')
  }

  return (
    <ParentComponent
      dangerouslySetInnerHTML={{
        __html: convert(intl.formatMessage({ id, defaultMessage }, { ...values })),
      }}
    ></ParentComponent>
  )
}

export default FormattedHTMLMessage
