import Button from '@berlitz/button'
import { H2, P } from '@berlitz/globals'
import { Tick } from '@berlitz/icons'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import PoratlAvailabilityForm from '@classes/AvailabilityForm'
import { IAvailabilityResponse, IUnAvailabilityResponse } from '@components/InstructorScheduler/interface'
import ProfileClock from '@components/ProfileClock'
import { DATE_FORMAT } from '@utils/constants'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Empty from './Empty'
import Form from './Form'
import { FormValueProps, IAvailabilityModal, IPutAvailabilities, IPutUnAvailabilities } from './interface'
import { Wrapper } from './style'
import { useMutation } from '@apollo/react-hooks'
import { PUT_AVAILABILITIES } from '@mutations/instructorAvailability'
import { PUT_UNAVAILABILITIES } from '@mutations/instructorUnAvailability'

moment.locale('en', {
  week: {
    dow: 1,
    doy: 1,
  },
})

const AvailabilityModal: React.FC<IAvailabilityModal> = ({
  open,
  onClose,
  onDelete,
  onSaving = () => {},
  getUnAvailabilityPayload = () => undefined,
  getAvailabilityPayload = () => undefined,
  onSaved,
  hasConflicts = () => false,
  isOverwritable = () => false,
  resource,
  Instructor,
  selectedTimeRange,
  isAdmin,
  refetch,
}) => {
  const [initialValues, setInitialValues] = useState<FormValueProps | undefined>()
  const [isTimeOff, setIsTimeOff] = useState(false)
  const [timeRange, setTimeRange] = useState(selectedTimeRange)

  const onCompleted = (data: IAvailabilityResponse & IUnAvailabilityResponse) =>
    onSaved(
      (data?.putUnAvailabilities || data?.putAvailabilities || data?.mergeAvailabilities || data?.replaceAvailabilities)
        ?.ServiceStatus?.Success || false
    )
  const onError = () => onSaved(false)

  const [saveUnAvailabilities] = useMutation<IUnAvailabilityResponse, IPutUnAvailabilities>(PUT_UNAVAILABILITIES, {
    onCompleted,
    onError,
  })

  const [saveAvailabilities] = useMutation<IUnAvailabilityResponse, IPutAvailabilities>(PUT_AVAILABILITIES, {
    onCompleted,
    onError,
  })

  const AvailabilityForm = new PoratlAvailabilityForm(resource, Instructor, selectedTimeRange, isTimeOff, isAdmin)
  const isEdit = !isEmpty(resource)
  const readOnly = AvailabilityForm.ReadOnly
  const viewSched = readOnly ? 'View' : 'Edit'

  useEffect(() => {
    setIsTimeOff(resource?.IsUnAvailability || false)
    setInitialValues(isEdit ? resource?.FormValues : AvailabilityForm.getDefaultFormValues(isTimeOff))
    setTimeRange(selectedTimeRange)
  }, [resource, selectedTimeRange])

  const onTabsChange = (isTimeOff: boolean) => {
    setIsTimeOff(isTimeOff)
    setInitialValues(isEdit ? resource?.FormValues : AvailabilityForm.getDefaultFormValues(isTimeOff))
  }

  return (
    <Modal open={open} onClose={onClose} size="md">
      <Wrapper>
        <H2>
          <FormattedMessage id={`${isEdit ? viewSched : 'New'} schedule`} />
        </H2>

        <P>
          <FormattedMessage id="Date selected" />:{' '}
          {moment(resource?.StartDate || timeRange.start).format(`dddd ${DATE_FORMAT.DEFAULT}`)}
        </P>

        <div className="nav-tabs">
          <div className="tabs">
            <Button
              onClick={() => onTabsChange(false)}
              className={`${!isTimeOff && 'selected'}`}
              disabled={isEdit && isTimeOff}
            >
              <div className="inline-text">
                {!isTimeOff && <Tick size="xs" />}
                <FormattedMessage id="Availability" />
              </div>
            </Button>
            <Button
              onClick={() => onTabsChange(true)}
              className={`${isTimeOff && 'selected'}`}
              disabled={isEdit && !isTimeOff}
            >
              <div className="inline-text">
                {isTimeOff && <Tick size="xs" />}
                <FormattedMessage id="Time off" />
              </div>
            </Button>
          </div>

          <ProfileClock
            hideChangeLink={isAdmin}
            onChangeClick={() => {
              Router.push('/instructors/settings/account')
            }}
          />
        </div>

        <Spacer size="lg" />

        {!isTimeOff && AvailabilityForm.HideAvailabilityForm ? (
          <Empty onClose={onClose} />
        ) : (
          <Form
            initialValues={initialValues}
            onClose={onClose}
            onDelete={onDelete}
            onSave={async (values) => {
              try {
                const conflicts = hasConflicts(values, isTimeOff)
                if (isTimeOff && !conflicts) {
                  onSaving()
                  return saveUnAvailabilities({
                    variables: getUnAvailabilityPayload(values),
                  })
                }

                if (!conflicts) {
                  onSaving()
                  await refetch(
                    values.StartDate.clone().subtract(1, 'week').format('YYYY-MM-DD'),
                    values.EndDate.clone().add(1, 'day').format('YYYY-MM-DD')
                  )

                  const overwritable = isOverwritable(values, isTimeOff)
                  if (!overwritable) {
                    onSaving()
                    return saveAvailabilities({
                      variables: getAvailabilityPayload(values),
                    })
                  }
                }
              } catch (error) {
                onSaved(false)
              }
            }}
            isEdit={isEdit}
            isTimeOff={isTimeOff}
            isFlexOnly={Instructor.IsFlexOnly}
            readOnly={readOnly}
            AvailabilityForm={AvailabilityForm}
            resource={resource}
            Instructor={Instructor}
            isAdmin={isAdmin}
          />
        )}
      </Wrapper>
    </Modal>
  )
}

export default AvailabilityModal
