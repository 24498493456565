import { css } from 'styled-components'

export const _placeholder = (style: any) => css`
  &::-webkit-input-placeholder {
    ${style}
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    ${style};
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    ${style};
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    ${style};
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    ${style}
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    ${style}
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    ${style}
  }
`

export const _attention = (style: any) => css`
  &:hover,
  &:active,
  &:focus {
    ${style}
  }
`
