import { Auth } from '@aws-amplify/auth'
import AWSAppSyncClient from 'aws-appsync'
import { AUTH_TYPE } from 'aws-appsync/lib'
import { API_URL } from './api-url'
import { signOutUser } from './aws-api'
import { getSession } from 'utils/aws-usersession'

export const getClient = async () =>
  new AWSAppSyncClient(
    {
      // @ts-ignore
      url: API_URL,
      // @ts-ignore
      region: process.env.AWS_S3_REGION,
      // @ts-ignore
      auth: {
        type: process.env.CHINA_DEPLOYMENT ? AUTH_TYPE.OPENID_CONNECT : AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        // @ts-ignore
        jwtToken: async () => {
          try {
            await getSession('getClient')
            let session = await Auth.currentSession()
            const expiry = session.getIdToken().getExpiration()
            const diff = expiry * 1000 - Date.now()

            if (diff <= 0) {
              session = await Auth.currentSession()
            }

            return session.getIdToken().getJwtToken()
          } catch (e) {
            // go back to sso when credentials expire
            // signout user here
            await signOutUser()
            return null
          }
        },
      },
      credentials: () => Auth.currentCredentials(),
      // Uses Amazon IAM credentials to authorize requests to S3.
      complexObjectsCredentials: () => Auth.currentCredentials(),
      disableOffline: true,
    },
    {
      resolvers: {
        Mutation: {
          resetFlex: (_root, variables, { cache }) => {
            const { resetFlex } = variables
            cache.writeData({ data: { resetFlex } })
            return null
          },
        },
      },
    }
  )
