import * as React from 'react'

import { ToastersProps } from './interface'
import { ToasterBox, ToasterItemBox } from './style'
import ToasterItem from './ToasterItem'

const Toaster: React.FC<ToastersProps> = ({ toasts, onRemove }) => {
  const topLeftToasts = toasts.filter(({ position }) => position === 'topLeft')
  const topCenterToasts = toasts.filter(({ position }) => position === 'topCenter')
  const topRightToasts = toasts.filter(({ position }) => position === 'topRight')

  const bottomLeftToasts = toasts.filter(({ position }) => position === 'bottomLeft')
  const bottomCenterToasts = toasts.filter(({ position }) => position === 'bottomCenter')
  const bottomRightToasts = toasts.filter(({ position }) => position === 'bottomRight')

  return (
    <ToasterBox>
      <ToasterItemBox position="topLeft">
        {topLeftToasts.reverse().map(({ id, ...rest }) => (
          <ToasterItem key={id} id={id} {...rest} onRemove={onRemove} />
        ))}
      </ToasterItemBox>

      <ToasterItemBox position="topCenter">
        {topCenterToasts.reverse().map(({ id, ...rest }) => (
          <ToasterItem key={id} id={id} {...rest} onRemove={onRemove} />
        ))}
      </ToasterItemBox>

      <ToasterItemBox position="topRight">
        {topRightToasts.reverse().map(({ id, ...rest }) => (
          <ToasterItem key={id} id={id} {...rest} onRemove={onRemove} />
        ))}
      </ToasterItemBox>

      <ToasterItemBox position="bottomLeft">
        {bottomLeftToasts.map(({ id, ...rest }) => (
          <ToasterItem key={id} id={id} {...rest} onRemove={onRemove} />
        ))}
      </ToasterItemBox>

      <ToasterItemBox position="bottomCenter">
        {bottomCenterToasts.map(({ id, ...rest }) => (
          <ToasterItem key={id} id={id} {...rest} onRemove={onRemove} />
        ))}
      </ToasterItemBox>

      <ToasterItemBox position="bottomRight">
        {bottomRightToasts.map(({ id, ...rest }) => (
          <ToasterItem key={id} id={id} {...rest} onRemove={onRemove} />
        ))}
      </ToasterItemBox>
    </ToasterBox>
  )
}

export default Toaster
