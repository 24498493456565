import BerlitzLogo from '@berlitz/logo'
import RoleBasedView from '@components/RoleBasedView'
import { StoreProps } from '@interfaces/StoreState'
import { AdminFooter } from '@layouts/PageLayout/style'
import { SSO_URL } from '@utils/api-url'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import RouteLink from '../RouteLink'
import DropdownAvatar from './DropdownAvatar'
import { AdminSpacer, AvatarWrapper, Logo, Root, Wrapper } from './style'

const Header = () => {
  const { profile, authorized, isAdmin, isFetching } = useSelector(({ session, userProfile }: StoreProps) => ({
    profile: userProfile.info,
    authorized: session.authorized,
    isAdmin: session.isAdmin,
    isFetching: userProfile.isFetching,
  }))

  const router = useRouter()

  const content =
    isAdmin || authorized || (profile && !isEmpty(profile)) ? (
      <Wrapper>
        <AvatarWrapper data-e2e="avatar-logo" data-qa="btn__profile">
          <DropdownAvatar profile={profile} isAdmin={isAdmin} />
        </AvatarWrapper>
      </Wrapper>
    ) : (
      <a href={SSO_URL}>
        <FormattedMessage id="Login" />
      </a>
    )

  const FirstName =
    profile?.StudentProfile?.StudentProfileInfo?.FirstName ||
    profile?.InstructorProfile?.instructorInfo?.contactInfo?.FirstName

  const LastName =
    profile?.StudentProfile?.StudentProfileInfo?.LastName ||
    profile?.InstructorProfile?.instructorInfo?.contactInfo?.LastName

  let currentUser = isFetching ? `Fetching user` : `${FirstName} ${LastName}`
  const configId = 'c::body::admin::header'

  if (!(profile?.InstructorProfile || profile?.StudentProfile) && !isFetching) {
    currentUser = 'Invalid user'
  }

  return (
    <>
      <RoleBasedView configId={configId}>
        <AdminFooter>
          Admin login as: <span>{authorized ? `${currentUser} [${authorized}]` : 'NONE'}</span>
        </AdminFooter>
      </RoleBasedView>
      <RoleBasedView configId={configId}>
        <AdminSpacer />
      </RoleBasedView>
      <Root fullscreen={['/activity'].includes(router.pathname)}>
        <RouteLink path="/">
          <Logo>
            <BerlitzLogo type="tertiary" size="xs" color="brandPrimary" />
          </Logo>
        </RouteLink>
        {content}
      </Root>
    </>
  )
}

export default Header
