import { Info, WarningTriangle } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import Link from 'next/link'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { TipsAlertProp } from './interface'
import { Box, ContactUs, Title } from './style'

const TipsAlert: React.FC<TipsAlertProp> = ({
  title,
  description,
  tipList = [],
  withContactUs,
  className,
  withRaiseACase,
  raiseACaseLink,
  type = 'info',
}) => (
  <Box className={`${className} tip-alert-${type}`}>
    <div>
      <Title>
        {type === 'info' ? <Info size="xs" color="brand01" /> : <WarningTriangle size="xs" color="brand01" />}
        {title}
      </Title>

      {description && (
        <>
          <Spacer size="sm" />
          {description}
        </>
      )}

      {tipList.length > 0 && (
        <>
          <Spacer size="sm" />
          <ul>
            {tipList.map((tip, index) => (
              <li key={`tip-${tip}-${index}`}>
                {tip}
                {tipList.length !== index + 1 && <Spacer size="xs" />}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>

    {withContactUs && (
      <>
        <Spacer size="sm" />
        <ContactUs>
          <div>
            <FormattedMessage id="Still having problems?" />{' '}
            <Link href="/support/contact-us">
              <FormattedMessage id="Contact us" />
            </Link>
          </div>
        </ContactUs>
      </>
    )}

    {withRaiseACase && (
      <>
        <Spacer size="sm" />
        <ContactUs>
          <div>
            <FormattedMessage id="Still having problems?" />{' '}
            <Link href={raiseACaseLink || `/support/contact-us/?id=my-lessons`}>
              <FormattedMessage id="Raise a case" />
            </Link>
          </div>
        </ContactUs>
      </>
    )}
  </Box>
)

export default TipsAlert
