/**
 * At the moment, this class should not be directly used.
 * Please access it through the student class
 */

import { CURRICULUM_TEST_STATUS, OPTLPID, PROGRAM_TYPES, REG_STATUS } from '@utils/constants'
import { RegistrationWrapper, StudentProfileInfo } from '@interfaces/Student'
import PortalProgram from './Program'

type OPTType = 'adults' | 'kids'
type UserType = 'Registration'

const LanguageMapping = {
  en: 'en',
  'zh-CN': 'zhs',
  fr: 'fr',
  de: 'de',
  'he-IL': 'en',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  'pl-PL': 'pl',
  pt: 'pt',
  es: 'es',
  'es-MX': 'es',
}

class OnlinePlacementTest {
  private _type: OPTType = 'adults'
  private _userType: UserType = 'Registration'
  private _targetLanguage: string = ''
  private _portalLanguage: string = ''
  private _programType: string = ''
  private _placementTestRequired: string = ''
  private _studentId: string
  private _level: string

  static generatePlaceholder(registration: RegistrationWrapper) {
    let reg = {
      ...registration,
    }
    if (!registration.RegistrationInfo.Materials.length) {
      if (
        PROGRAM_TYPES.FLEX_BOD.includes(registration.RegistrationInfo.ProgramType) &&
        ![REG_STATUS.PAUSED, REG_STATUS.CANCELLED, REG_STATUS.DRAFT].includes(registration.RegistrationInfo.Status) &&
        registration.RegistrationInfo.PlacementTestRequired === 'Required'
      ) {
        const placeholderName = [
          registration.RegistrationInfo.Language,
          'level',
          registration.RegistrationInfo.LanguageLevel?.toLocaleLowerCase(),
        ].join(' ')

        const LPID = registration.RegistrationInfo.RegistrationId

        reg = {
          ...registration,
          RegistrationInfo: {
            ...registration.RegistrationInfo,
            Materials: [
              {
                HasTest: true,
                IsElective: false,
                IsTest: false,
                LPExternalName: placeholderName,
                LPID: LPID,
                MaterialId: placeholderName,
                MaterialName: placeholderName,
                MaterialSubType: placeholderName,
                MaterialType: placeholderName,
                Name: placeholderName,
                RecordId: registration.RegistrationInfo.RegistrationId,
                RegistrationId: registration.RegistrationInfo.RegistrationId,
                Status: 'Assigned',
                ProgramId: registration.RegistrationInfo.Programs[0]?.ProgramId,
                TestStatus: CURRICULUM_TEST_STATUS.LOCKED,
                FromRegistration: registration.RegistrationInfo,
              },
            ],
          },
        }
      }
    }
    return reg
  }

  constructor(student: StudentProfileInfo, activeReg?: RegistrationWrapper, selectedProg?: PortalProgram) {
    let registration = activeReg?.RegistrationInfo || selectedProg?.program?.FromRegistration
    if (!registration) {
      registration = student?.RegistrationWrappers?.[0]?.RegistrationInfo
    }

    const StudentId = registration?.RegistrationId
    const PortalLanguage = student?.PortalLanguage ?? 'en'
    const TargetLanguage = registration?.Language ?? 'English'
    const ProgramType = registration?.ProgramType ?? ''
    const PlacementTestRequired = registration?.PlacementTestRequired ?? ''

    const portalLanguage = LanguageMapping[PortalLanguage] ?? 'en'
    this._studentId = StudentId
    this._targetLanguage = TargetLanguage
    this._portalLanguage = portalLanguage
    this._programType = ProgramType
    this._placementTestRequired = PlacementTestRequired
    this._level = registration?.LanguageLevel ?? ''
  }

  get IsRequired() {
    /**
     * If Type of Program = Berlitz Flex - Private Online Activation  AND "Placement Test Required?" =  Required
     * then return true
     */
    return PROGRAM_TYPES.FLEX_BOD.includes(this._programType) && this._placementTestRequired === 'Required'
  }

  get URL() {
    return `${__OPT_URL__}/${this._type}/${this._portalLanguage}?userType=${this._userType}&userId=${this._studentId}&targetLanguage=${this._targetLanguage}`
  }
}

export default OnlinePlacementTest
