import { NON_STUDENT_GROUP, PAGE } from '@utils/constants'
import {
  ADMIN_UPDATE_GROUP,
  UPDATE_AUTHORIZE_VALUE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_NOT_AUTHORISED,
} from '../actions/session'
import { CognitoUserProps } from '@interfaces/StoreState'
import { getIdAndTypeForProfForAdmin, getIdAndTypeforProfile } from '@utils/helpers'

interface IUserIdType {
  id: string
  type: string
}

export const initialState = {
  appLoaded: false,
  loading: true,
  authorized: null,
  userDetails: {},
  cognitoGroups: [],
  user: {} as CognitoUserProps,
  isAdmin: false,
  isSuperAdmin: false,
  error: false,
}

function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT:
      return Object.assign({}, state, {
        loading: true,
        authorized: false,
        error: false,
        userDetails: {},
        isAdmin: false,
        isSuperAdmin: false,
        cognitoGroups: [],
        user: {},
      })

    case USER_LOGIN_SUCCESS:
      const STUDENT_GROUP = 'Students'
      let cognitoGroups = action?.user?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? []
      const isAdmin = cognitoGroups.some((group) => PAGE.ROLE_ADMINS.includes(group))
      const isSuperAdmin = cognitoGroups.some((group) => group === __USER_GROUP_SUPERADMIN__)
      // Read custom:customerId for non-admins only
      let userIdObj: IUserIdType = { id: '0', type: 'Student' }
      if (isAdmin) {
        userIdObj = getIdAndTypeForProfForAdmin(action.accessAs)
      } else {
        userIdObj = getIdAndTypeforProfile(action?.user)
      }
      const tempSFID = userIdObj.id

      /**
       * Changed from push to concat
       * to avoid redux state mutation
       * and prevent unnecessary page refresh
       */
      if (isAdmin && action.accessAs.aai) {
        //cognitoGroups = cognitoGroups.concat(__USER_GROUP_INSTRUCTORS__)
        cognitoGroups.push(__USER_GROUP_INSTRUCTORS__)
      } else if (isAdmin && action.accessAs.aas) {
        //cognitoGroups = cognitoGroups.concat(__USER_GROUP_STUDENTS__)
        cognitoGroups.push(__USER_GROUP_STUDENTS__)
      }
      // for federated users. this is in the assumption that these users only have 1 group
      // temporary: check if the user's cognito group is not part of ADMIN OR Instructor
      // if not then assume the user is a student. Force the group
      const forceStudent = cognitoGroups.includes(STUDENT_GROUP) ? [] : [STUDENT_GROUP]
      cognitoGroups = cognitoGroups.some((i) => !NON_STUDENT_GROUP.includes(i))
        ? [...cognitoGroups, ...forceStudent]
        : cognitoGroups

      return Object.assign({}, state, {
        loading: false,
        authorized: tempSFID,
        authorizedKey: userIdObj.type,
        appLoaded: true,
        user: action.user,
        error: false,
        isAdmin,
        isSuperAdmin,
        cognitoGroups: [...cognitoGroups],
      })

    case USER_NOT_AUTHORISED:
      return Object.assign({}, state, {
        loading: false,
        appLoaded: true,
        error: action.error,
        cognitoGroups: [],
        isAdmin: false,
        isSuperAdmin: false,
        user: {},
      })
    case UPDATE_AUTHORIZE_VALUE:
      return Object.assign({}, state, {
        authorized: action.authorized,
        authorizedKey: action.authorizedKey,
      })

    case ADMIN_UPDATE_GROUP:
      return Object.assign({}, state, {
        cognitoGroups: [process.env.USER_GROUP_ADMIN, action.cognitoGroup],
        authorized: action.SFId,
      })

    default:
      return state
  }
}

export default sessionReducer
