import styled, { css } from 'styled-components'

import { LabelProp } from './interface'
import { Col } from 'react-styled-flexboxgrid'

export const ComingSoon = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space.sm};
  background-color: ${({ theme }) => theme.palette.info20};
  width: max-content;
  border-radius: 3px;

  div {
    margin-left: ${({ theme }) => theme.space.sm};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  img {
    width: 90px;
  }
`

export const DisableForm = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
`

export const FormWrapper = styled.div`
  position: relative;
`

export const ErrorMsg = styled.div`
  display: inline-flex;
  word-wrap: break-word;
  overflow-wrap: break-word;
  span:first-child {
    font-size: ${({ theme }) => theme.fontSize.xxxs};
    margin-right: ${({ theme }) => theme.space.xxs};
  }
  span:last-child {
    line-height: 1.2;
  }
  color: ${({ theme }) => theme.colors.uiError};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
`

export const FormCol = styled(Col)<{ hasError?: boolean }>`
  & > div {
    padding-bottom: 0;
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
  ${(props) =>
    props.hasError &&
    css`
      svg {
        color: red;
      }
      input {
        border: 1px solid ${({ theme }) => theme.colors.uiError};
        color: ${({ theme }) => theme.colors.uiError};
        &:focus {
          outline: none !important;
          border: 2px solid ${({ theme }) => theme.colors.uiError};
          border-radius: ${({ theme }) => theme.radius.md};
        }
      }
    `}
`
// colors & values in CustomPicker are not from theme as this css is targeted to react-datepicker
export const CustomPicker = styled(FormCol)<{ is12HourFormat?: boolean }>`
  .react-datepicker__time-list-item:last-child {
    color: transparent !important;
    text-indent: ${({ is12HourFormat }) => (is12HourFormat ? `-63px` : '-40px')};

    &:after {
      content: ${({ is12HourFormat }) => (is12HourFormat ? `'12:00 AM'` : `'24:00'`)};
      color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    }
  }

  .react-datepicker__time-list-item:last-child:hover {
    &:after {
      color: ${({ theme }) => theme.colors.brandSupporting01Contrast};
    }
  }

  .react-datepicker__time-list-item--selected {
    &:after {
      color: ${({ theme }) => theme.colors.brandSupporting01Contrast} !important;
    }
  }
`
export const Wrapper = styled.div`
  p {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  .nav-tabs {
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;

      button {
        color: ${({ theme }) => theme.colors.brandPrimary};
        background-color: ${({ theme }) => theme.palette.blue20};

        &:last-child {
          margin-left: ${({ theme }) => theme.space.md};
        }

        &.selected {
          color: ${({ theme }) => theme.colors.brandPrimaryContrast};
          background-color: ${({ theme }) => theme.palette.blue100};
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: column;

      .tabs {
        margin-bottom: 14px;
      }
    }
  }

  .inline-text {
    display: flex;
    align-items: center;

    svg {
      color: ${({ theme }) => theme.colors.brandPrimaryContrast};
      margin-right: 4px;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
`

export const Label = styled.div<LabelProp>`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xxs};

  ${({ warning }) =>
    warning &&
    css`
      color: #d87515;
      margin-top: ${({ theme }) => theme.space.xxs};

      span {
        svg {
          margin-left: 0;
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.uiError};
      margin-top: ${({ theme }) => theme.space.xxs};

      span {
        svg {
          margin-left: 0;
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  span {
    svg {
      margin-left: ${({ theme }) => theme.space.xxs};
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-right: ${({ theme }) => theme.space.xxs};
    margin-left: ${({ theme }) => theme.space.xxs};
  }
`
