export const DEFAULTS = {
  END_TIME_SAVE: '00:00',
  END_TIME_DISPLAY: '23:59',
}

export const ACTIONS = {
  DELETE: 'Delete',
  APPROVED: 'Approved',
  SAVING: 'saving' as 'saving',
  SAVED: 'saved' as 'saved',
  UPDATED: 'updated' as 'updated',
  UPDATING: 'updating' as 'updating',
  DELETING: 'deleting' as 'deleting',
  DELETED: 'deleted' as 'deleted',
}

export const F2F = {
  shortName: 'F2F',
  fullName: 'Face to face',
}

export const ONLINE = {
  shortName: 'Online',
  fullName: 'Online',
}

export const AVAILABILITY_TYPE = {
  F2F,
  ONLINE,
  BOTH: {
    shortName: `${F2F.shortName}/${ONLINE.shortName}`,
    fullName: `${F2F.fullName}/${ONLINE.fullName}`,
  },
  INVALID_MSG: 'Invalid Availability Type',
}

export const LESSON_TYPE = {
  FLEX: 'Flex',
  CORE: 'Core',
}

export const LEARNING_CENTER = {
  LIVE_ONLINE_VIRTUAL_CENTER: 'Live Online Virtual Center',
}

export const CONTRACT_TYPE = {
  CONTRACTED_NOT_GUARANTEED: 'Contracted Not Guaranteed',
  FREELANCE: 'Freelance',
  CONTRACTED_GUARANTEED: 'Contracted Guaranteed',
}

export const AVAILABILITY_OPTION = {
  AVAILABLE_CONTRACTED_F2F: 'Available(Contracted) - F2F',
  AVAILABLE_CONTRACTED_ONLINE: 'Available(Contracted) - Online',
  AVAILABLE_GUARANTEED_F2F: 'Available(Guaranteed) - F2F',
  AVAILABLE_GUARANTEED_ONLINE: 'Available(Guaranteed) - Online',
  TENTATIVE_F2F: 'Tentative - Face to Face',
  TENTATIVE_ONLINE: 'Tentative - Online',
}

export const REPEAT_OPTIONS = [
  {
    id: 0,
    label: 'Monday',
    value: 0,
  },
  {
    id: 1,
    label: 'Tuesday',
    value: 1,
  },
  {
    id: 2,
    label: 'Wednesday',
    value: 2,
  },
  {
    id: 3,
    label: 'Thursday',
    value: 3,
  },
  {
    id: 4,
    label: 'Friday',
    value: 4,
  },
  {
    id: 5,
    label: 'Saturday',
    value: 5,
  },
  {
    id: 6,
    label: 'Sunday',
    value: 6,
  },
]

export const FLEX_ONLY_CONTRACT_TYPES = {
  America: [CONTRACT_TYPE.CONTRACTED_GUARANTEED, CONTRACT_TYPE.CONTRACTED_NOT_GUARANTEED, CONTRACT_TYPE.FREELANCE],
}

export const AVAILABILITY_TYPE_OPTIONS = [
  {
    id: AVAILABILITY_TYPE.F2F.fullName,
    label: AVAILABILITY_TYPE.F2F.fullName,
    value: AVAILABILITY_TYPE.F2F.fullName,
  },
  {
    id: AVAILABILITY_TYPE.ONLINE.fullName,
    label: AVAILABILITY_TYPE.ONLINE.fullName,
    value: AVAILABILITY_TYPE.ONLINE.fullName,
  },
]

export const LESSON_TYPE_OPTIONS = [
  {
    id: LESSON_TYPE.CORE,
    key: LESSON_TYPE.CORE,
    label: LESSON_TYPE.CORE,
    value: LESSON_TYPE.CORE,
  },
  {
    id: LESSON_TYPE.FLEX,
    key: LESSON_TYPE.FLEX,
    label: LESSON_TYPE.FLEX,
    value: LESSON_TYPE.FLEX,
  },
]
