import { H1, H4 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spinner from '@berlitz/spinner'
import * as React from 'react'
import { CenterText, SpinnerWrapper } from './style'

export interface LoadingContentProps {
  title: string
  subtitle?: string
}

export interface LoadingModalProps extends LoadingContentProps {
  open: boolean
  onClose: Function
}

export const LoadingContent: React.FC<LoadingContentProps> = ({ title, subtitle }) => (
  <>
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
    <CenterText>
      <H1 disableMargin>{title}</H1>
      <H4>{subtitle}</H4>
    </CenterText>
  </>
)

class LoadingModal extends React.Component<LoadingModalProps> {
  static Content = LoadingContent

  render() {
    const { open, onClose, title, subtitle } = this.props
    return (
      <Modal open={open} onClose={onClose}>
        <LoadingContent title={title} subtitle={subtitle} />
      </Modal>
    )
  }
}

export default LoadingModal
