import moment from 'moment'
import { InstructorAvailability } from '@interfaces/InstructorAvailability'
import {
  ACTIONS,
  AVAILABILITY_TYPE,
  DEFAULTS,
  F2F,
  LEARNING_CENTER,
  LESSON_TYPE,
  ONLINE,
} from '@utils/constants/availability'
import PortalInstructor from '@classes/Instructor'
import { convertMomentToDate } from '@utils/helpers'
import { DATE_FORMAT } from '@utils/constants'

moment.locale('en', {
  week: {
    dow: 1,
    doy: 1,
  },
})

class PortalInstructorAvailability {
  availability: InstructorAvailability
  Instructor: PortalInstructor

  constructor(availability: InstructorAvailability, Instructor: PortalInstructor) {
    this.availability = availability || null
    this.Instructor = Instructor
  }

  isPastEvent = (date: Date) => {
    return moment(this.CalendarStart).isBefore(date)
  }

  isDeletable = (Instructor: PortalInstructor, isAdmin?: boolean) => {
    // based on https://berlitz.atlassian.net/browse/PTL-1300
    const primaryLCName = Instructor.PrimaryLearningCenter?.Name.toLowerCase()
    const isLiveOnlineInstructor = primaryLCName?.includes(LEARNING_CENTER.LIVE_ONLINE_VIRTUAL_CENTER.toLowerCase())
    const isFlexAvailability = this.IsFlex || !this.Availability

    const cng = Instructor.IsContractNotGuaranteed
    const freelance = Instructor.IsFreelance
    const cg = Instructor.IsContractedGuaranteed
    const flex = Instructor.IsFlex
    const contractedF2F = Instructor.IsAvailableContractedF2F
    const contractedOnline = Instructor.IsAvailableContractedOnline
    const guaranteedOnline = Instructor.IsAvailableGuaranteedOnline
    const tentativeOnline = Instructor.IsTentativeOnline

    // options based on https://berlitz.atlassian.net/wiki/spaces/B2/pages/687570955/Bulk+delete
    const option1 = cng && flex && contractedF2F
    const option2 = cng && flex && contractedOnline
    const option3 = freelance && flex && tentativeOnline
    const option4 = isAdmin && cg && !flex && guaranteedOnline
    const option5 = isAdmin && cg && flex && guaranteedOnline
    const option6 = isAdmin && cng && !flex && contractedOnline
    // options 7 based on https://berlitz.atlassian.net/browse/PTL-1300
    const option7 = !isAdmin && cng && isFlexAvailability && isLiveOnlineInstructor

    return (option1 || option2 || option3 || option4 || option5 || option6) && !option7
  }

  get InstructorAvailabilityInfo() {
    return this.availability
  }

  get Availability() {
    return this.availability?.InstructorAvailabilityInfo
  }

  get Id() {
    return this.Availability.Id ?? ''
  }

  get InstructorProfileId() {
    return this.Availability.InstructorProfileId
  }

  get IsAvailability() {
    return true
  }

  get IsUnAvailability() {
    return false
  }

  get StartDate() {
    return this.Availability.StartDate
  }

  get StartDateTime() {
    return this.Availability.StartDateTime
  }

  get EndDateTime() {
    return this.Availability.EndDateTime
  }

  get StartTime() {
    return this.Availability.StartTime ?? ''
  }

  get EndTime() {
    return this.Availability.EndTime ?? ''
  }

  get IsEndTimeEndOfDay() {
    return this.Availability.EndTime === DEFAULTS.END_TIME_SAVE
  }

  get IsF2F() {
    return this.Availability.AvailabilityType?.toLowerCase().indexOf(F2F.shortName.toLowerCase()) !== -1
  }

  get IsOnline() {
    return this.Availability.AvailabilityType?.toLowerCase().indexOf(ONLINE.shortName.toLowerCase()) !== -1
  }

  get IsCore() {
    return this.Availability.Type?.includes(LESSON_TYPE.CORE)
  }

  get IsFlex() {
    return this.Availability.Type?.includes(LESSON_TYPE.FLEX)
  }

  get TimeZone() {
    return this.Availability.TimeZone || moment.tz.guess()
  }

  get LearningCenterId() {
    return this.Availability.LearningCenterId ?? ''
  }

  get LearningCenterName() {
    return this.Availability?.LearningCenterName ?? ''
  }

  get Type() {
    return this.Availability?.Type ?? ''
  }

  get ApprovalStatus() {
    return ''
  }

  get CalendarTitle() {
    const { F2F, ONLINE, BOTH, INVALID_MSG } = AVAILABILITY_TYPE
    const types = [F2F, ONLINE, BOTH]
    const AvailabilityType = this.Availability.AvailabilityType || ''
    let title = INVALID_MSG

    types.forEach(type => {
      if (AvailabilityType?.toLowerCase().includes(type.shortName.toLowerCase())) {
        title = type.fullName
      }
    })

    return this.IsF2F && this.IsOnline ? F2F.fullName : title
  }

  get CalendarStart() {
    return convertMomentToDate(moment(this.Availability.StartDateTime))
  }

  get CalendarEnd() {
    const EndDateTime = moment(this.Availability.EndDateTime)
    return convertMomentToDate(this.IsEndTimeEndOfDay ? EndDateTime.subtract(1, 'minute') : EndDateTime)
  }

  get CalendarEvent() {
    return {
      id: this.Id,
      title: this.CalendarTitle,
      start: this.CalendarStart,
      end: this.CalendarEnd,
      resource: new PortalInstructorAvailability(this.availability, this.Instructor),
    }
  }

  // if F2F && Online split them to 2 events
  get CalendarEvents() {
    let events = [this.CalendarEvent]

    if (this.IsF2F && this.IsOnline) {
      events = [
        ...events,
        {
          ...this.CalendarEvent,
          title: AVAILABILITY_TYPE.ONLINE.fullName,
        },
      ]
    }

    return events
  }

  get F2FClassName() {
    return this.IsF2F ? 'f2f' : ''
  }

  get CoreClassName() {
    return this.IsCore ? 'base' : ''
  }

  get TypeClassName() {
    return 'availability'
  }

  get TimeClassName() {
    return `time-${moment(this.CalendarStart).format(DATE_FORMAT.HHmm)} `
  }

  get LessonTypeFormValues() {
    const type = (this.Availability.Type || '')?.toLowerCase()
    const f2f = AVAILABILITY_TYPE.F2F.fullName.toLowerCase()
    const online = AVAILABILITY_TYPE.ONLINE.fullName.toLowerCase()

    if (type.includes(AVAILABILITY_TYPE.BOTH.fullName.toLowerCase())) {
      return [f2f, online]
    }

    return [type.includes(f2f) ? AVAILABILITY_TYPE.F2F.fullName : AVAILABILITY_TYPE.ONLINE.fullName]
  }

  get FormStartTime() {
    const StartTime = moment(this.CalendarStart).tz(this.TimeZone)
    return moment(StartTime.format(DATE_FORMAT.HHmm), DATE_FORMAT.HHmm)
  }

  get FormEndTime() {
    const EndTime = moment(this.CalendarEnd).tz(this.TimeZone)
    return moment(EndTime.format(DATE_FORMAT.HHmm), DATE_FORMAT.HHmm)
  }

  get FormEndDate() {
    return this.IsEndTimeEndOfDay && !moment(this.CalendarStart).isSame(this.CalendarEnd, 'day')
      ? moment(this.CalendarStart).endOf('day')
      : moment(this.CalendarEnd)
  }

  get FormRepeatOn() {
    return [
      parseInt(
        moment(this.CalendarStart)
          .tz(this.Instructor.TimeZone)
          .format('e'),
        10
      ),
    ]
  }

  get FormValues() {
    return {
      StartTime: this.FormStartTime,
      EndTime: this.FormEndTime,
      AllDay: this.Availability.AllDay || false,
      LessonType: this.LessonTypeFormValues?.[0],
      AvailabilityType: this.Availability.Type || '',
      StartDate: moment(this.CalendarStart),
      EndDate: this.FormEndDate,
      RepeatOn: this.FormRepeatOn,
      KeepLessons: true,
      UnAvailabilityType: '',
      Id: this.Id,
      LearningCenterId: this.LearningCenterId,
      LearningCenterName: this.LearningCenterName,
    }
  }

  getCalendarEventClassName(instructorCurrentDate: moment.Moment | Date, isAdmin?: boolean) {
    return `${this.F2FClassName} ${this.CoreClassName} ${this.TypeClassName} ${this.getPastTimeClassName(
      instructorCurrentDate
    )} ${this.getPointerClassname(instructorCurrentDate, isAdmin)} ${this.TimeClassName}`
  }

  getPastTimeClassName(instructorCurrentDate: moment.Moment | Date) {
    return moment(this.CalendarStart).isBefore(instructorCurrentDate) ? 'past-event' : ''
  }

  getPointerClassname(instructorCurrentDate: moment.Moment | Date, isAdmin?: boolean) {
    return isAdmin && moment(this.CalendarStart).isAfter(instructorCurrentDate) ? 'cursor' : ''
  }

  getDeletePayload(CancelSessionFlag: boolean) {
    return {
      InstructorAvailabilityInfo: {
        InstructorProfileId: this.Availability.InstructorProfileId,
        StartDate: this.Availability.StartDate,
        Id: this.Id,
        Action: ACTIONS.DELETE,
        CancelSessionFlag,
      },
    }
  }

  getBulkDeletePayload(keepLessons: boolean = false) {
    return {
      InstructorAvailabilityInfo: {
        InstructorProfileId: this.InstructorProfileId,
        StartDate: this.StartDate,
        Id: this.Id,
        Action: ACTIONS.DELETE,
        CancelSessionFlag: !keepLessons,
      },
    }
  }
}

export default PortalInstructorAvailability
