import { GET_LEARNING_PATH as GET_PATH } from '@queries/learningPath'
import gql from 'graphql-tag'

export const GET_INSTRUCTOR_DATA = gql`
  query GetInstructorData($InstructorId: String!) {
    getInstructor(InstructorId: $InstructorId) {
      Description
      Avatar
      contactInfo {
        LastName
        Id
        FirstName
        Phone
        Email
      }
    }
  }
`

export const GET_INSTRUCTORS_DATA = gql`
  query GetInstructorData($InstructorIds: [String!]!) {
    getInstructors(InstructorIds: $InstructorIds) {
      Id
      Description
      Avatar
      contactInfo {
        LastName
        Id
        FirstName
        Phone
        Email
      }
    }
  }
`

export const GET_LEARNING_CENTRE_DATA = gql`
  query GetLearningCentreData($StudentId: String!, $LearningCenterId: String!) {
    getLearningCenters(StudentId: $StudentId, LearningCenterId: $LearningCenterId) {
      Id
      Name
      Address
      Photo
      Phone
      Preferred
      Description
      Instructors {
        Id
        Name
        Description
      }
    }
  }
`

export const GET_STUDENT_DATA = gql`
  query GetStudents($StudentId: String!) {
    getStudents(StudentId: $StudentId) {
      StudentProfileInfo {
        ProgramStudentWrappers {
          ProgramStudentInfo {
            OnlineLessonURL
            ProgramStudentId
            ProgramId
            LessonsTaken
            TotalLessons
            LessonsRemaining
            LessonFrequency
            DeliveryMode
            ProgramType
            Language
            ProgramName
            ProgramEnrollDate
          }
        }
      }
    }
  }
`

export const MY_CALENDAR_DATA = gql`
  query GetSchedulesId($FromDate: String!, $FilterType: String!, $Limit: Float!, $EndDate: String!, $Id: String!) {
    getSchedulesId(FROM_DATE: $FromDate, FILTER_TYPE: $FilterType, LIMIT: $Limit, END_DATE: $EndDate, Id: $Id) {
      Attachments {
        CreatedDatetime
        FileUrl
        LastModifieDatetime
        Name
        RecordId
        Status
        Title
      }
      FeedbackId
      DeliveryMode
      EndTime
      Id
      InstructorEmail
      InstructorId
      InstructorName
      LanguageLevel
      LearningCenter
      LearningCenterAddress
      LearningCenterId
      Material
      OnlineLessonURL
      ProgramEnrolDate
      ProgramId
      ProgramName
      Room
      StartTime
      Status
      StudentNote
      Timezone
      Type
      VirtualClassRoomProvider
      SessionId
      ForCounselling
      Attendance
      NoOfLessons
    }
  }
`

export const GET_STUDENT_PROFILE = gql`
  query GetStudents($StudentId: String!) {
    getStudents(StudentId: $StudentId) {
      StudentProfileInfo {
        FirstName
        LastName
        NickName
        Profession
        Location
        Street
        City
        State
        PostalCode
        Country
        Email
        MobilePhone
        PreferredTimezone
        PortalLanguage
        NativeLanguage
        Birthdate
        CustomerId
        RecordId
        Student
        ProgramStudentWrappers {
          ProgramStudentInfo {
            OnlineLessonURL
            ProgramStudentId
            ProgramId
            LessonsTaken
            TotalLessons
            LessonsRemaining
            LessonFrequency
            Language
            DeliveryMode
            ProgramType
            ProgramEnrollDate
            ProgramName
          }
        }
      }
    }
  }
`

export const GET_STUDENT_NOTIFICATIONS = gql`
  query GetStudents($StudentId: String!) {
    getStudents(StudentId: $StudentId) {
      StudentProfileInfo {
        Student
        RecordId
        ContactMethod
      }
    }
  }
`

export const GET_STUDENT_LANGUAGE_PROFILE = gql`
  query GetStudents($StudentId: String!) {
    getStudents(StudentId: $StudentId) {
      StudentProfileInfo {
        Student
        RecordId
        Purpose
        DetailsOfGoal
        LearningGoal
        LastLessonDate
        FirstLessonDate
        LessonExpectations
        OtherDetails
        StudentLanguageProfileInfos {
          AllNotesWrittenOnTheTakeaway
          CustomizedToLifeSituations
          CustomizedToWorkSituation
          DoesnotWantStrictCorrection
          FocusOnPronunciation
          GetHintsOrSupport
          HaveRolePlays
          KeepSelfIntroductionsToMinimum
          Language
          LearnPoliteFormalExpressions
          LearnWordsAndExpressions
          NoNaturalWayToSaySomething
          RecordId
          RepeatCorrectedWordsOrPhrases
          SpeakAsMuchAsPossible
          SpeakAtNaturalSpeed
          StrictCorrection
          StudentProfileId
          TaughtFullSentences
        }
      }
    }
  }
`

export const GET_LEARNING_PROFILES = gql`
  query GetLearningProfiles($StudentId: String!, $Language: String!) {
    getLearningProfiles(params: { StudentId: $StudentId, Language: $Language }) {
      StudentLanguageProfileInfo {
        RecordId
        StudentProfileId
        StudentName
        Language
        ByWhen
        AllNotesWrittenOnTheTakeaway
        CustomizedToLifeSituations
        CustomizedToWorkSituation
        Details
        DoesnotWantStrictCorrection
        FocusOnPronunciation
        GetHintsOrSupport
        HaveRolePlays
        KeepSelfIntroductionsToMinimum
        LearnPoliteFormalExpressions
        LearnWordsAndExpressions
        NoNaturalWayToSaySomething
        RepeatCorrectedWordsOrPhrases
        SpeakAsMuchAsPossible
        SpeakAtNaturalSpeed
        StrictCorrection
        TaughtFullSentences
        Purpose
        ByWhen
        FirstLessonDate
        LessonTaken
        LearningGoals
        Goal
        Goals {
          RecordId
          Description
          Item
          Language
          Order
          Type
        }
      }
    }
  }
`

const SCHEDULES_FRAGMENT = gql`
  fragment SchedulesFragment on SessionInfo {
    LPId
    UnitId
    UnitShortName
    UnitFullName
    Attachments {
      CreatedDatetime
      FileUrl
      LastModifieDatetime
      Name
      RecordId
      Status
    }
    Material
    GoalNotAchieved
    Materials {
      Chapter
      ChapterName
      Material
      MaterialName
      Page
      Point
      RecordId
      SessionSchedule
      Status
      Type
      URL
    }
    DeliveryMode
    EndTime
    Id
    InstructorEmail
    InstructorId
    InstructorName
    InstructorNote
    LanguageLevel
    LearningCenter
    LearningCenterAddress
    LearningCenterId
    Material
    OnlineLessonURL
    ProgramEnrolDate
    ProgramId
    ProgramName
    Room
    StartTime
    Status
    StudentNote
    Timezone
    Type
    VirtualClassRoomProvider
    VirtualClassRoomId
    ZoomUsername
    Attendance
    CustomerId
    Homework
    SessionId
    IsLastHomeworkDone
    Language
    StudentName
    Description
    IsGoalAchieved
    LateMinutes
    LessonRegistered
    Grading
    ForCounselling
    SpeakingGoals {
      RecordId
      Title
      IsGoalAchieved
      GoalNotAchieved
    }
    MaxNumberofStudentsInGroup
    NoOfLessons
  }
`

export const GET_SCHEDULES_WITH_NEXT_SESSION = gql`
  ${SCHEDULES_FRAGMENT}
  query GetSchedulesId($Id: String!, $Limit: Float!, $FromDate: String, $EndDate: String, $ProgramIds: String = "") {
    historyLessons: getSchedulesId(
      Id: $Id
      FILTER_TYPE: "FILTER_LESSON_HISTORY"
      LIMIT: $Limit
      FROM_DATE: $FromDate
      END_DATE: $EndDate
      ProgramIds: $ProgramIds
    ) {
      ...SchedulesFragment
    }

    nextLessons: getSchedulesId(
      Id: $Id
      FILTER_TYPE: "FILTER_STUDENT_NEXT2_SESSION"
      LIMIT: $Limit
      FROM_DATE: $FromDate
      END_DATE: $EndDate
      ProgramIds: $ProgramIds
    ) {
      ...SchedulesFragment
    }
  }
`

export const GET_SCHEDULES_ID = gql`
  query GetSchedulesId(
    $Id: String!
    $FilterType: String!
    $Limit: Float!
    $FromDate: String
    $EndDate: String
    $ProgramIds: String = ""
    $WithStudentNote: Boolean
  ) {
    getSchedulesId(
      Id: $Id
      FILTER_TYPE: $FilterType
      LIMIT: $Limit
      FROM_DATE: $FromDate
      END_DATE: $EndDate
      ProgramIds: $ProgramIds
      WithStudentNote: $WithStudentNote
    ) {
      LPId
      UnitId
      UnitShortName
      UnitFullName
      Attachments {
        CreatedDatetime
        FileUrl
        LastModifieDatetime
        Name
        RecordId
        Status
      }
      Material
      GoalNotAchieved
      Materials {
        Chapter
        ChapterName
        Material
        MaterialName
        Page
        Point
        RecordId
        SessionSchedule
        Status
        Type
        URL
      }
      DeliveryMode
      EndTime
      Id
      InstructorEmail
      InstructorId
      InstructorName
      InstructorNote
      LanguageLevel
      LearningCenter
      LearningCenterAddress
      LearningCenterId
      Material
      OnlineLessonURL
      ProgramEnrolDate
      ProgramId
      ProgramName
      Room
      StartTime
      Status
      StudentNote
      Timezone
      Type
      VirtualClassRoomProvider
      VirtualClassRoomId
      ZoomUsername
      Attendance
      CustomerId
      Homework
      SessionId
      IsLastHomeworkDone
      Language
      StudentName
      Description
      IsGoalAchieved
      LateMinutes
      LessonRegistered
      Grading
      ForCounselling
      SpeakingGoals {
        RecordId
        Title
        IsGoalAchieved
        GoalNotAchieved
      }
      MaxNumberofStudentsInGroup
      NoOfLessons
    }
  }
`

export interface GET_VIRTUAL_CLASSROOM_INPUT {
  provider: string
  userId: string
  userRole: string
  userEmail: string
  userFirstName?: string
  userLastName?: string
  roomId: string
  roomTitle: string
  playlistId?: string
  locale?: string
  lessonStartTime: string
  userTimezone?: string
  instructorEmail: string
  onDemandRoom?: boolean
}

export const GET_LEARNING_PATH = GET_PATH

export const GET_MATERIAL = gql`
  query GetLearningPathById($Id: String!) {
    getLearningPathById(Id: $Id) {
      shortname
      fullname {
        fullname
      }
      units {
        unitid
        fullname
        activities {
          type
          url
        }
      }
    }
  }
`

export const GET_LESSON_GROUPS = gql`
  query GetLessonGroups($params: LessonGroupParam!) {
    getLessonGroups(params: $params) {
      InstructorGroupInfo {
        Id
        ZoomUsername
        GroupClassList {
          SessionId
          Id
          VirtualClassRoomProvider
          OnlineLessonURL
          VirtualClassRoomId
          StartTime
          EndTime
          GroupName
          Homework
          StudentGroupId
          Language
          LessonGoal
          SessionComments
          NextSteps
          CounsellingSessionComments
          CounsellingNextSteps
          Type
          GapFiller
          Status
          LearningCenter
          ProgramType
          ProgramId
          ProgramName
          Note
          MINote
          InstructorToMINote
          InstructorToStaffNote
          InstructorToSellingLCNote
          LessonRegistered
          Classroom
          MaterialList {
            Material
            MaterialName
            Chapter
            ChapterName
            Status
            Page
            Point
            SessionSchedule
            Type
            URL
            RecordId
          }
          LastMaterialList {
            Material
            MaterialName
            Chapter
            ChapterName
            Status
            Page
            Point
            SessionSchedule
            Type
            URL
            RecordId
          }
          SessionInfoList {
            LPId
            UnitId
            UnitShortName
            UnitFullName
            StudentName
            StudentNote
            StartTime
            IsLastHomeworkDone
            IsGoalAchieved
            Id
            Attendance
            AbsenceType
            CounsellingAttendance
            GoalAchieved
            GoalNotAchieved
            LateMinutes
            EndTime
            StudentCnt
            Timezone
            Type
            Status
            SessionId
            GroupName
            CustomerId
            ReadyForNextLevel
            AtRisk
            MyBerlitzEnvironment
            OnlineLessonURL
            SpeakingGoals {
              RecordId
              Title
              IsGoalAchieved
              GoalNotAchieved
              Status
            }
            Attachments {
              CreatedDatetime
              FileUrl
              LastModifieDatetime
              Title
              RecordId
              Name
            }
          }
          DeliveryMode
          DeliveryLocationType
          MaxNumberofStudentsInGroup
        }
      }
    }
  }
`

export const GET_LESSON_GROUPS_INSESSION = gql`
  query GetLessonGroupsInSession($params: LessonGroupParam!) {
    getLessonGroupsInSession(params: $params) {
      InstructorGroupInfo {
        Id
        GroupClassList {
          SessionId
          Id
          VirtualClassRoomProvider
          OnlineLessonURL
          StartTime
          EndTime
          GroupName
          StudentGroupId
          Language
          LessonGoal
          Type
          GapFiller
          Status
          LearningCenter
          ProgramType
          ProgramId
          ProgramName
          Note
          MINote
          InstructorToMINote
          InstructorToStaffNote
          LessonRegistered
          Classroom
          MaterialList {
            Material
            Chapter
            Status
            Page
            Point
            SessionSchedule
            RecordId
          }
          LastMaterialList {
            Material
            Chapter
            Status
            Page
            Point
            SessionSchedule
            RecordId
            URL
          }
          SessionInfoList {
            StudentName
            StartTime
            IsLastHomeworkDone
            IsGoalAchieved
            Id
            Attendance
            GoalAchieved
            GoalNotAchieved
            LateMinutes
            CustomerId
            OnlineLessonURL
            SpeakingGoals {
              RecordId
              Title
              IsGoalAchieved
              GoalNotAchieved
            }
          }
        }
      }
    }
  }
`

export const GET_LESSON_GROUP_DETAIL = gql`
  query GetLessonGroupDetail($params: LessonGroupParam!) {
    getLessonGroups(params: $params) {
      InstructorGroupInfo {
        Id
        GroupClassList {
          VirtualClassRoomProvider
          OnlineLessonURL
          ProgramId
          InstructorNote {
            InstructorName
            Note
          }
          StudentNotes {
            Note
            StudentName
          }
          LanguageProfileInfos {
            AllNotesWrittenOnTheTakeaway
            CustomizedToLifeSituations
            CustomizedToWorkSituation
            Details
            DoesnotWantStrictCorrection
            FocusOnPronunciation
            GetHintsOrSupport
            Goals {
              Description
              Item
              Language
              Order
              RecordId
              Type
            }
            HaveRolePlays
            KeepSelfIntroductionsToMinimum
            Language
            LearnPoliteFormalExpressions
            LearnWordsAndExpressions
            NoNaturalWayToSaySomething
            Purpose
            RecordId
            RepeatCorrectedWordsOrPhrases
            SpeakAsMuchAsPossible
            SpeakAtNaturalSpeed
            StrictCorrection
            StudentName
            StudentProfileId
            TaughtFullSentences
          }
          LessonHistory {
            LastLessonWithMe {
              CustomerId
              Description
              Id
              InstructorName
              IsGoalAchieved
              GoalNotAchieved
              Attendance
              IsLastHomeworkDone
              Materials {
                Material
                Chapter
                Status
                Page
                Point
                SessionSchedule
                RecordId
                URL
              }
              StartTime
              StudentName
              Type
            }
            LastLessons {
              CustomerId
              Description
              Id
              InstructorName
              IsGoalAchieved
              GoalNotAchieved
              Attendance
              IsLastHomeworkDone
              InstructorNote
              Materials {
                Material
                Chapter
                Status
                Page
                Point
                SessionSchedule
                RecordId
                URL
              }
              StartTime
              StudentName
              Type
            }
          }
          MaterialList {
            Material
            Chapter
            Status
            Page
            Point
            SessionSchedule
            RecordId
            URL
          }
          SessionInfoList {
            OnlineLessonURL
          }
        }
      }
    }
  }
`
