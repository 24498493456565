import { Info } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import * as React from 'react'

import { BorderlessAlertProp } from './interface'
import { Box, Description, Title } from './style'

const BorderlessAlert: React.FC<BorderlessAlertProp> = ({ title, description, className }) => (
  <Box className={className}>
    <div>
      <Info size="xs" color="brand01" />
    </div>

    <div>
      {title && (
        <Title>
          {title}
          <Spacer size="sm" />
        </Title>
      )}

      <Description>{description}</Description>
    </div>
  </Box>
)

export default BorderlessAlert
