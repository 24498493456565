import PreloadedData from '@interfaces/PreloadedData'

export const UPDATE_PRELOADED_DATA = 'preloadedData/UPDATE_PRELOADED_DATA'
export const RESET_PRELOADED_DATA = 'preloadedData/RESET_PRELOADED_DATA'

export const setPreloadedData = (data: PreloadedData) => ({
  type: UPDATE_PRELOADED_DATA,
  data,
})

export const resetPreloadedData = () => ({
  type: RESET_PRELOADED_DATA,
})
