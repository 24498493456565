import styled from 'styled-components'

export const Box = styled.div`
  background-color: ${({ theme }) => theme.palette.info20};
  height: max-content;
  padding: ${({ theme }) => theme.space.md};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &.width-max-content {
    width: max-content;
  }

  ul {
    list-style: initial;
    list-style-position: inside;
  }

  svg {
    margin-top: ${({ theme }) => theme.space.xxxs};
    margin-right: ${({ theme }) => theme.space.xs};
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.blue80};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const Description = styled.div`
  line-height: 1.38;
`
