import Button from '@berlitz/button'
import { H3, P } from '@berlitz/globals'
import Spacer from '@berlitz/spacer'
import { ToasterProps, withToaster } from '@components/Toaster'
import { TextWrapper, Wrapper } from '@hocs/ErrorBoundary/style'
import { CognitoUserProps, StoreProps } from '@interfaces/StoreState'
import get from 'lodash/get'
import Router from 'next/router'
import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'react-styled-flexboxgrid'

export interface ErrorBoundaryProps extends ToasterProps {
  children: React.ReactNode
  showChildrenOnError: boolean
  authorized: string
  user: CognitoUserProps

  onErrorCaught?(): void
}

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info): void {
    const { toaster, user, authorized } = this.props
    toaster.error(error.message)

    const errorDetails = {
      event: 'errorBoundaryCatch',
      errorType: 'jsError',
      errorCognitoUser: get(user, 'username'),
      errorSFuser: authorized,
      errorStacktrace: info.componentStack,
      errorAdditionalErrorInformation: error.message,
    }
    // @ts-ignore
    window.dataLayer.push(errorDetails)

    this.setState({ hasError: true })
  }

  render(): React.ReactNode {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <Wrapper>
        <Row>
          <Col lg={6} xs={12}>
            <img src="/images/404.svg" />
          </Col>
          <Col lg={6} xs={12}>
            <TextWrapper>
              <div>
                <H3>
                  <FormattedMessage id="Oops, something went wrong." />
                </H3>
                <P>
                  <FormattedMessage id="Try refreshing your page or try again later." />
                </P>
                <P>
                  <FormattedMessage id="If you still have problems feel free to let us know." />
                </P>
                <Spacer size="lg" />
                <Button onClick={() => Router.push('/support/contact-us')}>
                  <FormattedMessage id="Contact support" />
                </Button>
              </div>
            </TextWrapper>
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({ session }: StoreProps) => {
  return {
    authorized: session.authorized,
    user: session.user,
  }
}

export default connect(mapStateToProps)(injectIntl(withToaster(ErrorBoundary)))
