import { getCurrentPermission } from '@utils/helpers/permissions'
import isEmpty from 'lodash/isEmpty'
import exclude from './exclude-config'
import Rules from '@utils/rbacs'
import { getOrInitializeStore } from '@redux/redux'

const selectUsername = (state) => state?.session?.user?.username
const selectRoles = (state) => state?.session?.cognitoGroups ?? []
const selectProfile = (state) => state?.userProfile.info
const selectUserPermissions = (state) => state?.permissions.permissions

/**
 * a function that would validate configId permission, it uses imperative redux
 * to pull data since this is outside react cycle
 */
const accessValidator = (configId: string): boolean => {
  const store = getOrInitializeStore({})
  const state = store.getState()

  const userName = selectUsername(state)
  const roles = selectRoles(state)
  const profile = selectProfile(state)
  const userPermissions = selectUserPermissions(state)

  if (!roles.length) {
    return false
  }

  let userPermission = userName && roles.length && !isEmpty(userPermissions) && userPermissions[configId]

  let dynamicFn = ''
  if (userPermission && userPermission?.dynamicFn) {
    dynamicFn = userPermission.dynamicFn

    // at this point, we don't need the dynamic function to be
    // executed in getCurrentPermission
    userPermission = {
      ...userPermission,
      dynamicFn: undefined,
    }
  }

  if (
    !profile.FeatureType?.some((feature) => (exclude[feature] || []).indexOf(configId) >= 0) &&
    userPermission &&
    getCurrentPermission({
      roles,
      userPermission,
      userName,
      profile,
      data: null,
    })
  ) {
    return true
  }

  let dynamicOverride = false
  // @ts-ignore
  if (dynamicFn && typeof Rules[dynamicFn] === 'function') {
    // @ts-ignore
    dynamicOverride = Rules[dynamicFn]({ profile, data: null, roles })
  }

  return dynamicOverride
}

export default accessValidator
