import { H1, H3 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import { CenterText } from '@components/Modal/style'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

interface IPFetchingRoomModal {
  open: boolean
}

export const Image = styled.div`
  padding: ${({ theme }) => theme.space.md};
  max-width: 250px;
`

const FetchingRoomContent: React.FC = () => (
  <CenterText>
    <Image>
      <img src="/images/room-search.svg" width={'100%'} />
    </Image>
    <H1 disableMargin>
      <FormattedMessage id="Fetching room details..." />
    </H1>
    <Spacer size="md" />
    <H3>
      <FormattedMessage id="Please wait a moment." />
    </H3>
  </CenterText>
)

class FetchingRoomModal extends React.Component<IPFetchingRoomModal> {
  static Content = FetchingRoomContent

  render() {
    const { open } = this.props
    return (
      <Modal open={open} onClose={() => {}}>
        <FetchingRoomContent />
      </Modal>
    )
  }
}
export default FetchingRoomModal
