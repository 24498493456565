import Button from '@berlitz/button'
import { H1, P } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import { PROFILE_TYPE } from '@utils/constants'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

interface IPRoomLockedContent {
  onClose: Function
  role?: string
}

interface IPRoomLockedModal extends IPRoomLockedContent {
  open: boolean
  role?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 320px;
  }

  button {
    &.ok-btn {
      width: 67px;
    }
  }
`

export const CustomP = styled(P)`
  text-align: center;
  margin-bottom: 0;
`

const RoomLockedContent: React.FC<IPRoomLockedContent> = ({ onClose, role }) => (
  <Container>
    <img src="/images/locked.svg" alt="Room locked" />

    <Spacer size="xxxl" />

    <H1 disableMargin>
      <FormattedMessage id="Room locked" />
    </H1>

    <CustomP>
      {role?.toLowerCase() === PROFILE_TYPE.INSTRUCTOR.toLocaleLowerCase() ? (
        <FormattedMessage id="Access will be enabled 15 minutes before the start time." />
      ) : (
        <FormattedMessage id="Access will be enabled 5 minutes before the start time." />
      )}
    </CustomP>
    <CustomP>
      <FormattedMessage id="Try again later." defaultMessage="Try again later." />
    </CustomP>
    <Spacer size={'lg'} />

    <Button onClick={onClose}>
      <FormattedMessage id="Close" />
    </Button>
  </Container>
)

class RoomLockedModal extends React.Component<IPRoomLockedModal> {
  static Content = RoomLockedContent

  render() {
    const { open, onClose, role } = this.props
    return (
      <Modal open={open} onClose={onClose}>
        <RoomLockedContent onClose={onClose} role={role} />
      </Modal>
    )
  }
}
export default RoomLockedModal
