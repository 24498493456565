import { InstructorSearchResultInfo } from '@interfaces/Instructor'

export const UPDATE_FMI_BULK_FORM_INSTRUCTORS = 'fmiBulkForm/UPDATE_FMI_BULK_FORM_INSTRUCTORS'
export const UPDATE_FMI_BULK_FORM_INSTRUCTOR_TYPES = 'fmiBulkForm/UPDATE_FMI_BULK_FORM_INSTRUCTOR_TYPES'
export const UPDATE_FMI_BULK_FORM_SELECTED_DATE = 'fmiBulkForm/UPDATE_FMI_BULK_FORM_SELECTED_DATE'

export const updateFMIBulkFormInstructors = (instructors: InstructorSearchResultInfo[]) => ({
  type: UPDATE_FMI_BULK_FORM_INSTRUCTORS,
  instructors,
})

export const updateFMIBulkFormInstructorTypes = (
  favouriteInstructors: InstructorSearchResultInfo[],
  pastInstructors: InstructorSearchResultInfo[],
  mostAvailableInstructors: InstructorSearchResultInfo[]
) => ({
  type: UPDATE_FMI_BULK_FORM_INSTRUCTOR_TYPES,
  favouriteInstructors,
  pastInstructors,
  mostAvailableInstructors,
})

export const updateFMIBulkFormSelectedDate = (selectedDate: string) => ({
  type: UPDATE_FMI_BULK_FORM_SELECTED_DATE,
  selectedDate,
})
