import styled, { css } from 'styled-components'
import { StyledLinkProps } from './interface'

export const StyledLink = styled.a<StyledLinkProps>`
  cursor: pointer;
  text-decoration: none;

  ${({ theme, withHoverEffect }) =>
    withHoverEffect &&
    css`
      &:hover {
        color: ${theme.colors.brandPrimary};
        background: transparent;
      }
    `}
`
