import styled, { css } from 'styled-components'

import { radiusXxxl } from '@utils/constants'
import { _attention } from '@utils/mixins'

export const Input = styled.input`
  display: none;
`

interface IGroupLabel {
  pills?: boolean
  checked?: boolean
  disabled?: boolean
  small?: boolean
  flow?: boolean
  fullWidth?: boolean
}

export const GroupLabel = styled.label<IGroupLabel>`
  user-select: none;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.lg}`};
  border-right: ${({ theme }) => `1px solid ${theme.palette.white}`};

  ${({ pills }) =>
    !pills
      ? css`
          background-color: ${({ theme }) => theme.palette.blue20};
          a {
            color: ${({ theme }) => theme.colors.brandPrimary};
          }
          &:first-of-type {
            border-top-left-radius: ${({ theme }) => theme.radius.md};
            border-bottom-left-radius: ${({ theme }) => theme.radius.md};
          }

          &:last-of-type {
            border-top-right-radius: ${({ theme }) => theme.radius.md};
            border-bottom-right-radius: ${({ theme }) => theme.radius.md};
            border-right: none;
          }
        `
      : css`
          padding-top: 6px;
          padding-bottom: 6px;
          color: ${({ theme }) => theme.colors.brandPrimary};
          border-radius: ${({ theme }) => theme.radius.md};
          border: 2px solid ${({ theme }) => theme.colors.brandPrimary};
          margin-right: ${({ theme }) => theme.space.xs};
          margin-bottom: ${({ theme }) => theme.space.xs};

          &:last-child {
            margin-right: 0;
          }
        `}

  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.colors.brandPrimaryContrast};
      a {
        color: ${({ theme }) => theme.colors.brandPrimaryContrast};
        ${() =>
          _attention(css`
            color: ${({ theme }) => theme.colors.brandPrimaryContrast};
          `)};
      }
      svg {
        color: ${({ theme }) => theme.colors.brandPrimaryContrast};
      }
      background-color: ${({ theme }) => theme.colors.brandPrimary};
    `}

  ${({ checked, pills }) =>
    checked &&
    pills &&
    css`
      color: ${({ theme }) => theme.colors.brandPrimaryContrast};
      background-color: ${({ theme }) => theme.colors.brandPrimary};
    `}

  ${({ disabled, pills }) =>
    disabled &&
    css`
      cursor: initial;
      opacity: 0.6;
      pointer-events: none;

      ${pills &&
      css`
          background-color ${({ theme }) => theme.palette.gray20};
          border-color ${({ theme }) => theme.palette.gray100};
          color ${({ theme }) => theme.palette.gray100};
      `}
    `}

  ${({ small, fullWidth }) =>
    small &&
    css`
      padding-left: ${({ theme }) => theme.space.md};
      padding-right: ${({ theme }) => theme.space.md};

      ${fullWidth &&
      css`
        max-width: 100%;
      `}
    `}
`

export const RadioCircle = styled.span`
  border: 1px solid ${({ theme }) => theme.palette.gray100};
  display: inline-block;
  border-radius: ${radiusXxxl};
  vertical-align: top;
  text-align: center;
  padding: 3px;
  margin: 3px ${({ theme }) => theme.space.xs} 3px 3px;
`

export const RadioDot = styled.div`
  border: none;
  background-color: ${({ theme }) => theme.palette.white};
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin: auto;
`

export const RadioLabel = styled.label<IGroupLabel>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  vertical-align: top;
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ checked }) =>
    checked &&
    css`
      ${RadioCircle} {
        border: 1px solid ${({ theme }) => theme.palette.blue100};
      }

      ${RadioDot} {
        background-color: ${({ theme }) => theme.palette.blue100};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: no-drop;
      color: ${({ theme }) => theme.palette.gray100};
    `}
`

const FullWidthCss = css`
  display: flex;
  width: 100%;

  ${GroupLabel} {
    width: 100%;
    position: relative;
    text-align: center;
  }
`

export const RadioGroup = styled.div<IGroupLabel>`
  margin-bottom: ${({ theme }) => theme.space.lg};

  ${({ flow, fullWidth }) =>
    !flow
      ? css`
          ${fullWidth && FullWidthCss};
          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            ${FullWidthCss};
          }
        `
      : css``}
`
