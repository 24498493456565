import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import { isEmpty } from 'lodash'
import { PORTAL_12_HOUR_TIME_FORMAT } from '@utils/constants/time'
import { TIME_FORMAT_COGNITO_GROUPS } from '@utils/constants/time-format'
import { BERLITZ_STAFFS, getUserTimeFormat } from '@utils/helpers/time-format'
import { DATE_FORMAT } from '@utils/constants'
import { accountFields } from '@layouts/Settings/Account/utils'

export type TTimeFormats = string[]

const useTimeFormat = () => {
  const { timeFormatFlag, allowedUser, isTwelveHour, isBerlitzStaff } = useSelector(
    ({ permissions, userProfile, session }: StoreProps) => {
      const userPermission = permissions?.permissions
      const timeFormatFlag = userPermission?.['flag::TimeFormat']
      const allowedUsers = timeFormatFlag?.allowedUsers
      const disallowUsers = timeFormatFlag?.disallowUsers
      const profileInfo = userProfile?.info
      const is12HourCustom = userPermission?.['flag::TimeFormat-12HourCustom']?.show || false
      const isAdmin = session?.isAdmin || false
      const isBerlitzStaff = session?.cognitoGroups?.some(cg => BERLITZ_STAFFS.includes(cg))

      return {
        timeFormatFlag,
        allowedUser:
          (timeFormatFlag?.allowedCognitoGroup?.some(cg =>
            TIME_FORMAT_COGNITO_GROUPS?.[profileInfo?.Type]?.includes(cg)
          ) ||
            allowedUsers?.some((username = '') => username.toLowerCase() === session?.user?.username?.toLowerCase())) &&
          !disallowUsers?.some((username = '') => username.toLowerCase() === session?.user?.username?.toLowerCase()),
        isTwelveHour:
          (!isBerlitzStaff &&
            (profileInfo?.StudentProfile?.StudentProfileInfo?.PortalTimeformat === PORTAL_12_HOUR_TIME_FORMAT ||
              profileInfo?.InstructorProfile?.instructorInfo?.PortalTimeformat === PORTAL_12_HOUR_TIME_FORMAT)) ||
          (isAdmin && is12HourCustom),
        isBerlitzStaff,
      }
    }
  )

  const isTimeFormatDisabled = isEmpty(timeFormatFlag) || !allowedUser

  const convertToUserTimeFormats = <T extends string | TTimeFormats>(
    times: T,
    formats: T extends string ? string : TTimeFormats,
    custom12HourFormat?: T extends string ? string : (string | 'default')[]
  ): T extends string ? string : TTimeFormats => {
    if (isTimeFormatDisabled) {
      return (times as unknown) as T extends string ? string : TTimeFormats
    }

    if (Array.isArray(times) && Array.isArray(formats)) {
      return times.map((time, index) =>
        getUserTimeFormat(
          time,
          formats[index],
          isTwelveHour,
          Array.isArray(custom12HourFormat) ? custom12HourFormat[index] : custom12HourFormat
        )
      ) as T extends string ? string : TTimeFormats
    }

    if (typeof times === 'string' && typeof formats === 'string') {
      return getUserTimeFormat(
        times,
        formats,
        isTwelveHour,
        typeof custom12HourFormat === 'string' ? custom12HourFormat : undefined
      ) as T extends string ? string : TTimeFormats
    }

    return (times as unknown) as T extends string ? string : TTimeFormats
  }

  const getTimePickerFormat = () => (isTimeFormatDisabled || isTwelveHour ? 'h:mm aa' : DATE_FORMAT.HM)
  const getTimeFormat = (custom12HourFormat?: string) => {
    const twelveHourFormat =
      (custom12HourFormat === 'default' ? DATE_FORMAT.hmmA : custom12HourFormat) || DATE_FORMAT.hmmA
    return isTwelveHour ? twelveHourFormat : DATE_FORMAT.HM
  }
  const getAccountFormFields = () => [...accountFields.concat(isTimeFormatDisabled ? [] : ['PortalTimeformat'])]

  return {
    convertToUserTimeFormats,
    getTimePickerFormat,
    getTimeFormat,
    getAccountFormFields,
    isTimeFormatDisabled,
    isBerlitzStaff,
    isTwelveHour,
  }
}

export default useTimeFormat
