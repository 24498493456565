import * as React from 'react'
import ContentLoader from 'react-content-loader'
import { Col, Row } from 'react-styled-flexboxgrid'

import Resizable from '../../../interfaces/Resizable'

const LoadUI: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={42}
    width={500}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  />
)

const Loader: React.FC = () => (
  <Row>
    <Col xs={9} md={5}>
      <LoadUI height="30px" />
      <br />
      <br />
    </Col>
    <Col xs={3} md={7} />
    <Col xs={5} md={2} />
    <Col xs={7} md={10} />
    <Col xs={7} md={3}>
      <LoadUI height="20px" />
      <br />
      <br />
    </Col>
    <Col xs={5} md={9} />
    {[...Array(3).keys()].map((key: number) => (
      <React.Fragment key={`group-field-${key}`}>
        <Col xs={4} md={2}>
          <LoadUI height="20px" />
        </Col>
        <Col xs={7} md={8}>
          <LoadUI height="20px" />
          <br />
        </Col>
        <Col xs={12} />
      </React.Fragment>
    ))}
    <Col xs={6} md={3}>
      <br />
      <LoadUI height="20px" />
      <br />
      <br />
    </Col>
    <Col xs={6} md={9} />
    {[...Array(3).keys()].map((key: number) => (
      <React.Fragment key={`group-field-${key}`}>
        <Col xs={5} md={3}>
          <LoadUI height="20px" />
        </Col>
        <Col xs={7} md={false} />
        <br />
      </React.Fragment>
    ))}
  </Row>
)

export default Loader
