import moment from 'moment'
import { InstructorInfo } from '@interfaces/Instructor'
import {
  AVAILABILITY_OPTION,
  CONTRACT_TYPE,
  LEARNING_CENTER,
  LESSON_TYPE,
  FLEX_ONLY_CONTRACT_TYPES,
} from '@utils/constants/availability'
import { convertMomentToDate } from '@utils/helpers'
import { DATE_FORMAT } from '@utils/constants'

class PortalInstructor {
  profile?: InstructorInfo

  private freelancers: string[]

  constructor(instructor?: InstructorInfo) {
    this.profile = instructor
    this.freelancers = ['Freelance']
  }

  isFreelancer = () => this.freelancers.includes(this.profile?.ContractType ?? '')

  showPendingLessons = () => this.isFreelancer()

  canCreateAvailability = (start?: Date) => {
    return (
      (!!this.EndDateWithBerlitz &&
        moment(this.EndDateWithBerlitz).isValid() &&
        moment(this.EndDateWithBerlitz).isSameOrAfter(start, 'date')) ||
      !this.EndDateWithBerlitz
    )
  }

  get Id() {
    return this.profile?.Id
  }

  get Profile() {
    return this.profile
  }

  get FirstName() {
    return this.profile?.contactInfo?.FirstName
  }

  get LastName() {
    return this.profile?.contactInfo?.LastName
  }

  get FullName() {
    return `${this.FirstName} ${this.LastName}`
  }

  get PrimaryLearningCenter() {
    return this.profile?.learningcenters?.find(({ IsPrimary }) => IsPrimary !== void 0)
  }

  get ContactInfo() {
    return this.profile?.contactInfo
  }

  get TimeZone() {
    return this.profile?.PreferredTimezone || moment.tz.guess()
  }

  get CurrentDate() {
    return convertMomentToDate(moment().tz(this.TimeZone))
  }

  get ContractType() {
    return this.profile?.ContractType || ''
  }

  get AvailabilityType() {
    return this.profile?.AvailabilityType || ''
  }

  get AvailabilityOption() {
    return this.profile?.AvailabilityOption || ''
  }

  get IsContractNotGuaranteed() {
    return this.ContractType?.toLowerCase() === CONTRACT_TYPE.CONTRACTED_NOT_GUARANTEED.toLowerCase()
  }

  get IsFreelance() {
    return this.ContractType?.toLowerCase() === CONTRACT_TYPE.FREELANCE.toLowerCase()
  }

  get IsContractedGuaranteed() {
    return this.ContractType?.toLowerCase() === CONTRACT_TYPE.CONTRACTED_GUARANTEED.toLowerCase()
  }

  get IsFlex() {
    return this.profile?.AvailabilityType?.toLowerCase().includes(LESSON_TYPE.FLEX.toLowerCase())
  }

  get IsFlexOnly() {
    const country = this.TimeZone.split('/')[0]
    const flexOnlyTypes = FLEX_ONLY_CONTRACT_TYPES[country] || [
      CONTRACT_TYPE.CONTRACTED_NOT_GUARANTEED,
      CONTRACT_TYPE.FREELANCE,
    ]
    return flexOnlyTypes.includes(this.ContractType)
  }

  get IsAvailableContractedF2F() {
    return this.AvailabilityOption?.toLowerCase().includes(AVAILABILITY_OPTION.AVAILABLE_CONTRACTED_F2F.toLowerCase())
  }

  get IsAvailableContractedOnline() {
    return this.AvailabilityOption?.toLowerCase().includes(
      AVAILABILITY_OPTION.AVAILABLE_CONTRACTED_ONLINE.toLowerCase()
    )
  }

  get IsAvailableGuaranteedOnline() {
    return this.AvailabilityOption?.toLowerCase().includes(
      AVAILABILITY_OPTION.AVAILABLE_GUARANTEED_ONLINE.toLowerCase()
    )
  }

  get IsTentativeOnline() {
    return this.AvailabilityOption?.toLowerCase().includes(AVAILABILITY_OPTION.TENTATIVE_ONLINE.toLowerCase())
  }

  get IsPrimaryLCLiveOnline() {
    return (
      (this.PrimaryLearningCenter?.Name || '').toLowerCase() ===
      LEARNING_CENTER.LIVE_ONLINE_VIRTUAL_CENTER.toLowerCase()
    )
  }

  get EndDateWithBerlitz() {
    return this.profile?.EndDateWithBerlitz || ''
  }

  get LearningCenters() {
    return this.profile?.learningcenters || []
  }

  get ContractEndDate() {
    return (
      this.profile?.EndDateWithBerlitz ||
      moment()
        .add(2, 'years')
        .endOf('day')
        .format(DATE_FORMAT.BASIC)
    )
  }
}

export default PortalInstructor
