import { ProgramStudent } from '@interfaces/Student'
import { PROGRAM_TYPES } from '@utils/constants'

export const tlc = (s: string = ''): string => s.toLowerCase()

export const getBlockedPrograms = (programs: ProgramStudent[]) =>
  programs.filter(({ ProgramStudentInfo }) => ProgramStudentInfo.BlockScheduling)

export const allProgramsBlocks = (programs: ProgramStudent[]) => getBlockedPrograms(programs).length === programs.length

export const isProgramOnline = (programType: string = '', deliveryMode: string = ''): boolean => {
  const type = programType && programType.toLowerCase()
  const mode = deliveryMode && deliveryMode.toLowerCase() === 'online'
  const lowerTypes = types => types.map(lType => lType.toLowerCase())
  return lowerTypes(PROGRAM_TYPES.ONLINE).includes(type) || mode
}

export const hasNotifications = (ProgramWrappers: ProgramStudent[] = []): boolean => {
  return (
    ProgramWrappers.length > 0 &&
    ProgramWrappers.some(
      ({ ProgramStudentInfo }) =>
        ProgramStudentInfo.ValidforCounselling &&
        tlc(ProgramStudentInfo.CounsellingStatus || '') === tlc('Available') &&
        !ProgramStudentInfo.IsGroup &&
        ProgramStudentInfo.ProgramType !== 'Berlitz Flex - Private Online Activation' &&
        ProgramStudentInfo.LessonsTaken / ProgramStudentInfo.TotalLessons >= 0.5
    )
  )
}

export const getProgramChildren = (currProgramId: string, programs: ProgramStudent[] = []): ProgramStudent[] =>
  programs.filter(
    ({ ProgramStudentInfo }) => ProgramStudentInfo.IsChild && ProgramStudentInfo.ParentProgramId === currProgramId
  )
