import { PortalSelectProps } from '@interfaces/Select'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, SelectOverrides, StyledSelect, SubLabel } from './style'
// @ts-ignore
const { Option, OptGroup } = StyledSelect

const generateClassname = (option: any) => {
  let className = ''

  if (option.highlighted) {
    className += 'highlighted-option'
  }

  if (option.partialDisabled) {
    className += 'ant-select-dropdown-menu-item-partial-disabled'
  }

  return className
}

const PortalSelect: React.FunctionComponent<PortalSelectProps> = (props) => {
  const ComponentBody = (
    <>
      <SelectOverrides />
      {props.subLabel && <SubLabel>{props.subLabel}</SubLabel>}
      {
        // @ts-ignore
        <StyledSelect {...props} dropdownClassName="multi-option-selector">
          {props.options &&
            props.options.map((option, i) =>
              option?.suboptions?.length > 1 ? (
                <OptGroup key={i}>
                  {option.suboptions.map((subopt) => {
                    return (
                      <Option
                        name={subopt.label}
                        value={subopt.value || subopt.label}
                        key={subopt.key || subopt.value || subopt.label}
                        disabled={subopt.disabled}
                        className={generateClassname(subopt)}
                      >
                        {subopt.label ? <FormattedMessage id={subopt.label} defaultMessage={subopt.label} /> : null}
                      </Option>
                    )
                  })}
                </OptGroup>
              ) : (
                <Option
                  name={option.label}
                  value={option.value || option.label}
                  key={option.key || option.value || option.label}
                  disabled={option.disabled}
                  className={generateClassname(option)}
                >
                  {option.label ? <FormattedMessage id={option.label} defaultMessage={option.label} /> : null}
                </Option>
              )
            )}
        </StyledSelect>
      }
    </>
  )
  if (props.customLabel) {
    return (
      <>
        <Field disableMargin={props.disableMargin}>
          <>
            {props.customLabel}
            {ComponentBody}
          </>
        </Field>
      </>
    )
  }

  if (props.label) {
    return (
      <>
        <Field label={props.label} disableMargin={props.disableMargin}>
          {ComponentBody}
        </Field>
      </>
    )
  }

  return ComponentBody
}

export default PortalSelect
