import { useMutation } from '@apollo/react-hooks'
import { StoreProps } from '@interfaces/StoreState'
import { POST_CASE } from '@mutations/cases'
import { autoRaiseCompleted } from '@redux/actions/case'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AutoRaiseCase: React.FC = () => {
  const { SFId, isRaisingCase, programId } = useSelector(({ userProfile, cases }: StoreProps) => ({
    SFId: userProfile?.info?.SFId,
    isRaisingCase: cases.isRaisingCase,
    programId: cases.programId,
  }))
  const dispatch = useDispatch()

  const [postCase] = useMutation(POST_CASE, {
    variables: {
      caseInput: [
        {
          FileInfos: [],
          CaseInfo: {
            Type: 'My lesson',
            SubType: 'Other',
            Subject: 'Admin scheduling required',
            Description:
              'Student has remaining lessons, but not enough to schedule a session themselves. Please contact the student to review and finalize schedule.',
            LessonNow: 'No',
            StudentId: SFId,
            caseCommentInfoList: [],
            OtherSubType: 'Scheduling',
            ProgramId: programId,
          },
        },
      ],
    },
    onCompleted: () => {
      dispatch(autoRaiseCompleted())
    },
  })

  useEffect(() => {
    isRaisingCase && postCase()
  }, [isRaisingCase])
  // display nothing to "hide" process from user
  return null
}

export default React.memo(AutoRaiseCase)
