import { H2 } from '@berlitz/globals'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;

  .avatar {
    margin-right: ${({ theme }) => theme.space.lg};
  }

  ${H2} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  .phone {
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.space.xs};

    svg {
      margin-right: ${({ theme }) => theme.space.xs};
    }
  }
`
