import { SessionProps, StoreProps } from '@interfaces/StoreState'
import { getCurrentPermission, getOverridePermission } from '@utils/helpers/permissions'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useSelector } from 'react-redux'
import exclude from './exclude-config'
import usePortalFeatures from './usePortalFeatures'
import accessValidator from './accessValidator'

export { accessValidator, usePortalFeatures }

interface IRoleBasedView extends SessionProps {
  configId: string
  children: React.ReactElement
  data?: any
  // Override the child component if it's hidden
  // Example usage is Maintenance Components
  OverrideComponent?: React.ReactElement
}

const RoleBasedView = ({ children, configId, data, OverrideComponent }: IRoleBasedView) => {
  const { userName, roles, profile, userPermissions } = useSelector(
    ({ session, userProfile, permissions }: StoreProps) => ({
      roles: session?.cognitoGroups ?? [],
      userName: session?.user?.username,
      profile: userProfile.info,
      userPermissions: permissions.permissions,
    })
  )

  if (!roles.length) {
    return null
  }

  const userPermission = userName && roles.length && !isEmpty(userPermissions) && userPermissions[configId]
  if (
    !profile.FeatureType?.some((feature) => (exclude[feature] || []).indexOf(configId) >= 0) &&
    userPermission &&
    getCurrentPermission({
      roles,
      userPermission,
      userName,
      profile,
      data,
    })
  ) {
    return <>{React.cloneElement(children, { 'data-c-permission': configId })}</>
  }

  if (
    userPermission &&
    getOverridePermission({
      roles,
      userPermission,
      userName,
      profile,
      data,
    }) &&
    OverrideComponent &&
    React.isValidElement(OverrideComponent)
  ) {
    return <>{React.cloneElement(OverrideComponent)}</>
  }

  return null
}

export default React.memo(RoleBasedView)
