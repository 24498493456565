import { Tick } from '@berlitz/icons'
import Avatar from '@components/Avatar'
import { avatarSm } from '@utils/constants'
import styled, { css } from 'styled-components'

/* TODO: - static variables to be added in max-theme-portals later */
export const Root = styled.header<{ isAdmin?: boolean; fullscreen?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: flex;
  flex-direction: row;
  min-height: ${({ theme }) => theme.layout.lg};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.ui00};
  /* move to max-portal */
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.space.md};

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      display: none;
    `}
`

/* TODO: - static variables to be added in max-theme-portals later */
export const Logo = styled.div`
  height: ${({ theme }) => theme.layout.lg};
  width: ${({ theme }) => theme.layout.lg};
  /* move to max-portal */
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: none;
  outline: none;
`

export const StyledAvatar = styled(Avatar)<{ size?: string }>`
  svg {
    color: white;
    font-size: ${({ theme }) => theme.fontSize.xs};
    ${({ size }) =>
      size === avatarSm &&
      css`
        font-size: ${({ theme }) => theme.fontSize.lg};
      `};
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AvatarWrapper = styled.div`
  cursor: pointer;
`

export const CourseLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CourseItem = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTick = styled(Tick)`
  background: ${({ theme }) => theme.palette.success100};
  border-radius: 50%;
  padding: ${({ theme }) => theme.space.xxxs};
  bottom: -6px;
  top: auto !important;
  right: 3px !important;
  font-size: 8px;
`

export const StyledSpan = styled.span`
  padding-left: ${({ theme }) => theme.space.xs};
`
export const CourseLinkDescription = styled.p`
  max-width: 80px;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.space.xs};
  text-align: right;
  line-height: 1;
  span {
    display: block;
    color: ${({ theme }) => theme.palette.blueGray60};
  }
`

export const StyledA = styled.a`
  text-decoration: none;
`
export const AdminSpacer = styled.div`
  height: 40px;
`
