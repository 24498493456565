import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.ui06};
  z-index: 3;
`

export const ModalOverlay = styled.div`
  overflow: hidden;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: ${({ theme }) => theme.layout.lg};
  height: ${({ theme }) => theme.layout.lg};
  padding: 16px;
  border-radius: ${({ theme }) => theme.radius.sm};
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 999;
`
