import { Building, Camera, Profile } from '@berlitz/icons'
import { IAvatarProps } from '@components/Avatar/interface'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'react-styled-flexboxgrid'

import { AvatarLoader } from './Loader'
import { Icon, Wrapper } from './style'

// Avatar Prio
// 1 - Show image
// 2 - if no image, check type
// 3 - if no type, use name
const Avatar = (props: IAvatarProps) => {
  const { loading, img, className, size, md, lg, xl, squared, type, grayed, editable, onEditClick } = props
  // const { onHover } = this.state
  const [onHover, setOnHover] = useState(false)
  let avatarIcon: React.ReactNode | null = null

  if (img) {
    avatarIcon = null
  } else if (type === 'building') {
    avatarIcon = <Building />
  } else if (type === 'profile') {
    avatarIcon = <Profile />
  }

  if (loading) {
    return (
      <Row>
        <Col xs={false} md={12}>
          <AvatarLoader height="102px" />
        </Col>
        <Col xs={12} md={false}>
          <AvatarLoader height={size} />
        </Col>
      </Row>
    )
  }
  return (
    // @ts-ignore
    <Wrapper
      className={className}
      img={img}
      size={size}
      md={md}
      lg={lg}
      xl={xl}
      squared={squared}
      type={type}
      grayed={grayed}
      editable={editable}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      {img
        ? editable &&
          onHover && (
            <Icon onClick={onEditClick}>
              <Camera color="brand03" />
              <FormattedMessage id="Edit" />
            </Icon>
          )
        : avatarIcon || avatarText}
    </Wrapper>
  )
}

export default React.memo(Avatar)
