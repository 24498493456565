import { StoreProps } from '@interfaces/StoreState'
import { PORTAL_24_HOUR_TIME_FORMAT } from '@utils/constants/time'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalTransactional from '@berlitzplatforms/micro.modal.transactional'
import ImageOutOfTime from '@berlitzplatforms/micro.image.out-of-time'
import { useIntl } from 'react-intl'
import { getMessages } from './constants'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import { useMutation } from '@apollo/react-hooks'
import { PUT_STUDENTS } from '@mutations/index'
import useRefetchProfile from '@hooks/useRefetchProfile'
import Spinner from '@berlitz/spinner'
import useTimeFormat from '@hooks/useTimeFormat'
import pick from 'lodash/pick'
import Check from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/33-Form-Validation/Check'
import { SpinnerWrapper, SuccessWrapper, PortalTimeFormatModalWrapper, Header } from './style'
import { useRouter } from 'next/router'
import { PUT_INSTRUCTOR } from '@mutations/instructor'

interface IPortalTimeFormatModal {}

const PortalTimeFormatModal: React.FC<IPortalTimeFormatModal> = () => {
  const intl = useIntl()
  const messages = getMessages()
  const router = useRouter()
  const { isTimeFormatDisabled, getAccountFormFields, isBerlitzStaff } = useTimeFormat()
  const { SFId, StudentProfileInfo, instructorInfo, PortalTimeformat } = useSelector(({ userProfile }: StoreProps) => {
    const StudentProfileInfo = userProfile?.info?.StudentProfile?.StudentProfileInfo
    const instructorInfo = userProfile?.info?.InstructorProfile?.instructorInfo
    const PortalTimeformat = StudentProfileInfo?.PortalTimeformat || instructorInfo?.PortalTimeformat
    return {
      SFId: userProfile?.info?.SFId,
      StudentProfileInfo,
      instructorInfo,
      PortalTimeformat,
    }
  })

  const [open, setOpen] = useState(false)
  const [saved, setSaved] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const refetchProfile = useRefetchProfile()

  const redirectUser = () => router.push(`${instructorInfo ? '/instructors' : ''}/settings/account`)
  const onCompleted = async () => {
    setSaved(!redirect)
    const [updateUI] = await refetchProfile()
    await updateUI()
    setOpen(false)
    redirect && redirectUser()
  }
  const [putStudent, { loading: studentLoading }] = useMutation(PUT_STUDENTS, {
    onCompleted,
    onError: onCompleted,
  })
  const [putInstructor, { loading: instructorLoading }] = useMutation(PUT_INSTRUCTOR, {
    onCompleted,
    onError: onCompleted,
  })
  const saving = studentLoading || instructorLoading || redirect

  useEffect(() => {
    setOpen(!isTimeFormatDisabled && !PortalTimeformat && !isBerlitzStaff)
  }, [isTimeFormatDisabled, PortalTimeformat, isBerlitzStaff])

  useEffect(() => {
    return () => setOpen(false)
  }, [])

  const confirmFeature = (redirect: boolean = false) => {
    setRedirect(redirect)
    setSaved(false)

    if (StudentProfileInfo) {
      putStudent({
        variables: {
          studentInput: [
            {
              StudentProfileInfo: {
                ...pick(StudentProfileInfo, getAccountFormFields()),
                PortalTimeformat: PORTAL_24_HOUR_TIME_FORMAT,
              },
            },
          ],
        },
      })
    }

    if (instructorInfo) {
      putInstructor({
        variables: {
          putInstructorsInput: [
            {
              instructorInfo: {
                PreferredTimezone: instructorInfo?.PreferredTimezone,
                Id: SFId,
                PortalLanguage: instructorInfo?.PortalLanguage,
                PortalTimeformat: PORTAL_24_HOUR_TIME_FORMAT,
              },
            },
          ],
        },
      })
    }
  }

  return (
    <PortalTimeFormatModalWrapper saved={saved}>
      <Modal open={open} onClose={() => saved && setOpen(false)}>
        <ModalTransactional
          image={
            saving ? (
              <SpinnerWrapper>
                <Spinner size="md" />
              </SpinnerWrapper>
            ) : saved ? (
              <SuccessWrapper>
                <Check />
              </SuccessWrapper>
            ) : (
              <ImageOutOfTime width="300" />
            )
          }
          description={
            <>
              {saving ? (
                <>
                  <Spacer size="md" />
                  <Header>{intl.formatMessage(redirect ? messages.redirecting : messages.saving)}</Header>
                  <Spacer size="md" />
                  <p>{intl.formatMessage(messages.wait)}</p>
                </>
              ) : saved ? (
                <Header>{intl.formatMessage(saving ? messages.saving : messages.saved)}</Header>
              ) : (
                <>
                  <Header>{intl.formatMessage(saving ? messages.saving : messages.title1)}</Header>
                  <Spacer size="md" />
                  <h2>{intl.formatMessage(messages.title2)}</h2>
                  <Spacer size="md" />
                  <p>{intl.formatMessage(messages.body)}</p>
                </>
              )}
            </>
          }
          onProceed={confirmFeature}
          proceedBtnLabel={saving || saved ? '' : intl.formatMessage(messages.button1)}
          onClose={() => confirmFeature(true)}
          closeBtnLabel={saving || saved ? '' : intl.formatMessage(messages.button2)}
        />
        {(saving || saved) && <div style={{ marginBottom: '-30px' }}></div>}
      </Modal>
    </PortalTimeFormatModalWrapper>
  )
}

export default PortalTimeFormatModal
