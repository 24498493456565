import { H1, H2, P } from '@berlitz/globals'
import { Phone } from '@berlitz/icons'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import get from 'lodash/get'
import * as React from 'react'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { LearningCenterType } from '../../../interfaces/LearningCenter'
import { GET_LEARNING_CENTRE_DATA } from '../../../queries'
import { avatarMd, avatarSm } from '@utils/constants'

import Avatar from '../../Avatar'
import HR from '../../HR'
import { RectLoader } from '../../Loader'
import { AboutLoader, ApiErrorMessage } from '../Common'
import { Header } from './style'

interface LearningCenterModalProps {
  open: boolean
  onClose: Function
  StudentId: string
  LearningCenterId: string
}

const LearningCenterModal: React.FC<LearningCenterModalProps> = ({ open, onClose, StudentId, LearningCenterId }) => (
  <Modal open={open} onClose={onClose}>
    <Query
      query={GET_LEARNING_CENTRE_DATA}
      variables={{
        StudentId,
        LearningCenterId,
      }}
      fetchPolicy="network-only"
    >
      {({ loading, data, error }) => {
        const learningCenter: LearningCenterType = get(data, 'getLearningCenters[0]', {})

        if (error) {
          return <ApiErrorMessage />
        }

        return (
          <>
            <Header>
              <div className="avatar">
                <Avatar loading={loading} type="building" img={learningCenter.Photo} size={avatarSm} md={avatarMd} />
              </div>

              {loading ? (
                <div>
                  <RectLoader width="250" height="40" />
                  <br />
                  <RectLoader width="110" height="20" />
                  <br />
                  <RectLoader width="100" height="20" />
                </div>
              ) : (
                <div>
                  <H1 disableMargin>{learningCenter.Name}</H1>
                  <div>{learningCenter.Address}</div>
                  <div className="phone">
                    <Phone /> {learningCenter.Phone}
                  </div>
                </div>
              )}
            </Header>

            <Spacer />
            <HR />
            <Spacer size="sm" />

            {loading ? (
              <AboutLoader />
            ) : (
              <>
                <H2>
                  <FormattedMessage id="About Us" />
                </H2>
                <P>{learningCenter.Description}</P>
              </>
            )}
          </>
        )
      }}
    </Query>
  </Modal>
)

export default LearningCenterModal
