import gql from 'graphql-tag'

export const GET_DECRYPTS = gql`
  query Decrypt($encrypts: [String!]!) {
    decrypt(encrypts: $encrypts) {
      decrypts
    }
  }
`

export const GET_DECRYPTS_WITH_TYPE = gql`
  query Decrypt($encrypts: [String!]!, $type: String) {
    decrypt(encrypts: $encrypts, type: $type) {
      decrypts
    }
  }
`
