import Button from '@berlitz/button'
import styled, { css } from 'styled-components'

import { LabelProp } from './interface'

export const ModalWrapper = styled.div`
  div[role='dialog'] {
    overflow: initial;
  }
`

export const FormWrapper = styled.div`
  position: relative;
  justify-content: space-between;
`

export const StyledText = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    margin-right: 4px;
  }

  ${({ selected, theme }) => `
    color: ${selected ? theme.colors.brandPrimaryContrast : theme.colors.brandPrimary};
  `}
`

export const StyledButton = styled(Button)<{ selected?: boolean }>`
  &:last-child {
    margin-left: ${({ theme }) => theme.space.md};
  }

  ${({ selected, theme }) => `
    background-color: ${selected ? theme.palette.blue100 : theme.palette.blue20} !important;
    ${
      selected &&
      `padding-top: ${({ theme }) => theme.space.sm};
      padding-bottom: ${({ theme }) => theme.space.sm};
    `
    }
  `}
`

export const StyledTabs = styled.div`
  display: flex;
`

export const Wrapper = styled.div`
  p {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  .nav-tabs {
    display: flex;
    justify-content: space-between;

    &.bulk-delete {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: column;

      &.bulk-delete {
        align-items: unset;

        h2 {
          margin-bottom: ${({ theme }) => theme.space.xs};
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
`

export const Label = styled.div<LabelProp>`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xxs};

  ${({ warning }) =>
    warning &&
    css`
      color: #d87515;
      margin-top: ${({ theme }) => theme.space.xxs};

      span {
        svg {
          margin-left: 0;
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.uiError};
      margin-top: ${({ theme }) => theme.space.xxs};

      span {
        svg {
          margin-left: 0;
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  span {
    svg {
      margin-left: ${({ theme }) => theme.space.xxs};
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-right: ${({ theme }) => theme.space.xxs};
    margin-left: ${({ theme }) => theme.space.xxs};
  }
`

export const StyledForm = styled.form`
  .ant-dropdown {
    border: none !important;
  }
`
