import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ isAdmin?: boolean; hasUser?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  ${({ isAdmin, hasUser }) => {
    if (isAdmin) {
      return css`
        height: calc(100vh - 190px);
      `
    } else if (hasUser) {
      return css`
        height: calc(100vh - 150px);
      `
    } else {
      return css`
        height: 100vh;
      `
    }
  }}

  h1 {
    color: ${({ theme }) => theme.colors.brandPrimary};
    font-size: 90px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  p {
    color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  img {
    width: 492px;
  }
`
