import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import PortalInstructorAvailability from '@classes/InstructorAvailability'
import { InstructorAvailability } from '@interfaces/InstructorAvailability'
import PortalInstructor from './Instructor'
import { FormValueProps, IPutAvailabilities } from '@components/Modal/AvailabilityModal/interface'
import { convertMomentToDate, getFilteredBulkDeletePayload } from '@utils/helpers'
import { DATE_FORMAT } from '@utils/constants'
import omitDeep from '@utils/omit-deep'
import {
  FormValueProps as BulkFormProps,
  IAvailabilityBulkPayload,
} from '@components/Modal/AvailabilityBulkDeleteModal/interface'

const moment = extendMoment(Moment)

class PortalInstructorAvailabilities {
  availabilities: InstructorAvailability[]
  Instructor: PortalInstructor
  AvailabilityConflicts: PortalInstructorAvailability[]

  constructor(availabilities: InstructorAvailability[] = [], Instructor: PortalInstructor) {
    this.availabilities = availabilities
    this.Instructor = Instructor
    this.AvailabilityConflicts = []
  }

  get Availabilities() {
    return (
      this.availabilities?.map(availability => new PortalInstructorAvailability(availability, this.Instructor)) || []
    )
  }

  getDate(date: Moment.Moment, time: Moment.Moment) {
    return moment(
      `${date.clone().format(DATE_FORMAT.BASIC)} ${time.clone().format(DATE_FORMAT.BASIC_TIME)}`,
      DATE_FORMAT.DT
    )
  }

  getAvailabilityConflicts = (values: FormValueProps) =>
    this.Availabilities.filter(({ CalendarStart, CalendarEnd, Id }) => {
      const start = moment(CalendarStart).tz(this.Instructor.TimeZone)
      const end = moment(CalendarEnd).tz(this.Instructor.TimeZone)

      const formStart = this.getDate(start.clone().tz(this.Instructor.TimeZone), values.StartTime)
      const formEnd = this.getDate(start.clone().tz(this.Instructor.TimeZone), values.EndTime)

      const eventRange = moment.range(convertMomentToDate(start), convertMomentToDate(end))
      const formDateRange = moment.range(
        convertMomentToDate(values.StartDate.clone().startOf('day')),
        convertMomentToDate(values.EndDate.clone().endOf('day'))
      )
      const formRange = moment.range(convertMomentToDate(formStart), convertMomentToDate(formEnd))

      return (
        formDateRange.overlaps(eventRange) &&
        values.RepeatOn.includes(start.weekday()) &&
        formRange.overlaps(eventRange) &&
        values.Id !== Id
      )
    })

  hasAvailabilityTypeConflicts = (values: FormValueProps) =>
    this.AvailabilityConflicts.some(({ Type }) => values.AvailabilityType.toLowerCase() !== Type.toLowerCase())

  hasConflicts(values: FormValueProps) {
    this.AvailabilityConflicts = this.getAvailabilityConflicts(values)
    return this.hasAvailabilityTypeConflicts(values)
  }

  getAvailabilityPayload(values?: FormValueProps | null): IPutAvailabilities {
    return {
      FormValues: {
        ...values,
        StartDate: values?.StartDate.format(DATE_FORMAT.BASIC),
        StartTime: values?.StartTime.format(DATE_FORMAT.HM),
        EndDate: values?.EndDate.format(DATE_FORMAT.BASIC),
        EndTime: values?.EndTime.format(DATE_FORMAT.HM),
      },
      Instructor: {
        InstructorProfileId: this.Instructor.Id || '',
        InstructorName: this.Instructor.FullName,
        AvailabilityOption: this.Instructor.AvailabilityOption,
        TimeZone: this.Instructor.TimeZone,
      },
      Availabilities: this.AvailabilityConflicts.map(({ Availability }) => ({
        InstructorAvailabilityInfo: {
          ...omitDeep(Availability, '__typename'),
        },
      })),
    }
  }

  getBulkDeleteEvents = (values: BulkFormProps): IAvailabilityBulkPayload[] =>
    getFilteredBulkDeletePayload(values, this.Availabilities)

  filterByDateRange(start: Date, end: Date) {
    return this.Availabilities.filter(({ CalendarStart, CalendarEnd }) => {
      const weekRange = moment.range(start, end)
      const schedRange = moment.range(CalendarStart, CalendarEnd)
      return weekRange.overlaps(schedRange)
    })
  }
}

export default PortalInstructorAvailabilities
