import PortalPrograms from '@classes/Programs'
import { StoreProps } from '@interfaces/StoreState'
import { UserProfileProps } from '@interfaces/UserProfile'
import { DATE_FORMAT } from '@utils/constants'
import get from 'lodash/get'
import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'

interface MapStateProps {
  profile: UserProfileProps
}

export interface WithUserTimeProps extends MapStateProps {
  toUserTime: (val: string, displayFormat?: string) => string
  toUserDate: (date: string, format?: string, displayFormat?: string) => string
  isProgramContractExpired: (programId?: string) => boolean
  isProfileTimeSameWithBrowser: (timezone?: string) => boolean
  getUserDate: (timezone?: string) => moment.Moment
  getDateWithUserTimezone: (date?: string) => moment.Moment
  getCutOffTime: (date?: string) => moment.Moment
  toDate: (date: moment.Moment) => Date
}

export default function WithUserTime<T extends WithUserTimeProps>(Component: React.ComponentType<T>) {
  class WithUserTimeHOC extends React.PureComponent<WithUserTimeProps> {
    isProfileTimeSameWithBrowser = (timezone?: string) => {
      return (
        this.getUserDate(timezone) &&
        moment().format(DATE_FORMAT.DT) === this.getUserDate(timezone).format(DATE_FORMAT.DT)
      )
    }

    getUserDate = (timezone?: string) => moment().tz(timezone || this.props.profile.Timezone || moment.tz.guess())

    getDateWithUserTimezone = (date?: string) => {
      const { profile } = this.props
      return moment.tz(date ? date : moment(), profile.Timezone)
    }

    isProgramContractExpired = programId => {
      const program = new PortalPrograms(
        this.props.profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers
      ).getProgram(programId)?.program

      return (
        program &&
        moment(program.ContractEndDate, 'YYYY-MM-DD').diff(this.getDateWithUserTimezone(), 'days') < 0 &&
        program.Status === 'Active'
      )
    }

    getCutOffTime = () => {
      const { profile } = this.props
      const cotz = get(profile.InstructorProfile, 'instructorInfo.CutOffTimeZone')
      const coTime = get(profile.InstructorProfile, 'instructorInfo.CutOffTime')
      const chunkTime = (moment.tz(coTime, 'hh:mm A', cotz).format('HH:mm:ss') || '').split(':')

      return this.getDateWithUserTimezone()
        .hour(parseInt(chunkTime[0], 10))
        .minutes(parseInt(chunkTime[1], 10))
        .second(parseInt(chunkTime[2], 10))
    }

    toUserTime = (time: string, displayFormat?: string) => {
      const { profile } = this.props
      displayFormat = displayFormat ? displayFormat : 'HH:mm'
      return moment(moment.utc(time, 'HH:mm:ss').toDate())
        .tz(profile.Timezone)
        .format(displayFormat)
    }

    toUserDate = (date: string, format?: string, displayFormat?: string) => {
      const { profile } = this.props
      format = format ? format : DATE_FORMAT.DT
      displayFormat = displayFormat ? displayFormat : DATE_FORMAT.DEFAULT

      return moment(moment.utc(date, format).toDate())
        .tz(profile.Timezone)
        .format(displayFormat)
    }

    toDate = (date: moment.Moment = moment()): Date => new Date(date.format('llll'))

    render() {
      const { ...rest } = this.props
      const enhanceProps = {
        ...rest,
        toUserTime: this.toUserTime,
        toUserDate: this.toUserDate,
        getUserDate: this.getUserDate,
        getDateWithUserTimezone: this.getDateWithUserTimezone,
        getCutOffTime: this.getCutOffTime,
        isProgramContractExpired: this.isProgramContractExpired,
        isProfileTimeSameWithBrowser: this.isProfileTimeSameWithBrowser,
        toDate: this.toDate,
      }

      return <Component {...(enhanceProps as T)} />
    }
  }

  function mapStateToProps({ userProfile }: StoreProps): MapStateProps {
    return {
      profile: userProfile.info,
    }
  }

  return connect<MapStateProps, {}, any, StoreProps>(mapStateToProps)(WithUserTimeHOC)
}
