import Button from '@berlitz/button'
import { H1, H4 } from '@berlitz/globals'
import { Tick } from '@berlitz/icons'
import Modal from '@berlitz/modal'
import PortalPrograms from '@classes/Programs'
import { StoreProps } from '@interfaces/StoreState'
import Router from 'next/router'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ActionButtons, CenterText, IconCircle } from './style'

interface BookingConfirmedProps {
  open: boolean
  onClose: Function
  onCloseRedirect: Function
  clearSearch?: () => void
  programId: string
}

const BookingConfirmed: React.FC<BookingConfirmedProps> = ({
  open,
  onClose,
  onCloseRedirect,
  clearSearch,
  programId,
}) => {
  const isFetchingProfile = useSelector(({ userProfile }: StoreProps) => userProfile.isSilentFetching)
  const StudentProgram = useSelector(({ userProfile }: StoreProps) =>
    new PortalPrograms(userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? []).getProgram(
      programId
    )
  )
  const [fetching, setFetching] = useState(isFetchingProfile)

  useEffect(() => {
    setFetching(isFetchingProfile)

    let timeout = setTimeout(() => {
      setFetching(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [open])

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!fetching) {
          onClose()
          onCloseRedirect()
        }
      }}
    >
      <IconCircle color="success">
        <Tick size="lg" />
      </IconCircle>
      <CenterText>
        <H1>
          <FormattedMessage id="Booking confirmed" />
        </H1>
        <H4>
          <FormattedMessage id="We'll see you soon" />!
        </H4>

        <ActionButtons>
          <Button
            disabled={fetching}
            canLoad
            loading={fetching}
            onClick={() => {
              onClose()
              Router.push('/scheduling/my-calendar')
            }}
          >
            <FormattedMessage id="Go to My calendar" />
          </Button>
          {!StudentProgram.hasBulkOnlyFeature() && !StudentProgram.isFlexProgram() && !fetching && (
            <div>
              <a
                onClick={() => {
                  clearSearch && setTimeout(clearSearch, 100)
                  Router.push('/scheduling/single-lesson')
                }}
              >
                <FormattedMessage id="Book another lesson" />
              </a>
            </div>
          )}
        </ActionButtons>
      </CenterText>
    </Modal>
  )
}

export default BookingConfirmed
