import { IPermissions } from '@interfaces/Permissions'
import { UserProfileProps } from '@interfaces/UserProfile'
import Rules from '@utils/rbacs'

export interface IUserPermissions {
  roles?: string[]
  userPermission?: IPermissions
  userName?: string
  profile?: UserProfileProps
  data?: any
}

export const getPermissionFromStorage = (key: string) => {
  let permissions: IPermissions = JSON.parse(atob(localStorage.getItem('stored-ff') || '{}'))
  return permissions[key]?.show ?? false
}

export const getCurrentPermission = ({ roles, userPermission, userName = '', profile, data }: IUserPermissions) => {
  if (!userPermission || !roles || !roles.length) {
    return false
  }

  const { show } = userPermission
  const disallowedGroups = userPermission?.disallowCognitoGroup ?? []
  const disallowedUsers = userPermission?.disallowUsers ?? []
  const allowedGroups = userPermission?.allowedCognitoGroup ?? []
  const allowedUsers = userPermission?.allowedUsers ?? []
  const dynamicFn = userPermission?.dynamicFn ?? []

  // @ts-ignore
  const isUserDisallowed = disallowedUsers && disallowedUsers.includes(userName)
  // @ts-ignore
  const isUserAllowed = allowedUsers && allowedUsers.includes(userName)
  // @ts-ignore
  const hideOnAllDisallowedGroups = roles.every(r => disallowedGroups.includes(r))
  // @ts-ignore
  const showOnUserGroup = roles.some(r => allowedGroups.includes(r))

  let dynamicOverride = true

  // @ts-ignore
  if (dynamicFn && typeof Rules[dynamicFn] === 'function') {
    // @ts-ignore
    dynamicOverride = Rules[dynamicFn]({ profile, data, roles })
  }

  if (show) {
    if (isUserDisallowed) {
      return false
    }
    if (hideOnAllDisallowedGroups) {
      return dynamicOverride && isUserAllowed
    }
    return dynamicOverride && true
  }

  if (isUserAllowed) {
    return dynamicOverride && true
  }
  if (showOnUserGroup) {
    return dynamicOverride && !isUserDisallowed
  }
}

export const getOverridePermission = ({ roles, userPermission, userName = '', profile, data }: IUserPermissions) => {
  if (!userPermission || !roles || !roles.length) {
    return false
  }

  const { show } = userPermission
  const disallowedGroups = userPermission?.disallowCognitoGroup ?? []
  const disallowedUsers = userPermission?.disallowUsers ?? []
  const dynamicFn = userPermission?.dynamicFn || ''

  const isUserDisallowed = disallowedUsers && disallowedUsers.includes(userName)
  const hideOnAllDisallowedGroups = roles.every(r => disallowedGroups.includes(r))

  let dynamicOverride = true

  if (!show) {
    if (dynamicFn && typeof Rules[dynamicFn] === 'function') {
      dynamicOverride = Rules[dynamicFn]({ profile, data, roles })
    }

    return !hideOnAllDisallowedGroups && !isUserDisallowed && dynamicOverride
  }

  return false
}
