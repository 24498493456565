export const BULKSEARCH_SET_PARAMS = 'bulksearch/BULKSEARCH_SET_PARAMS'
export const BULKSEARCH_SET_SESSIONS = 'bulksearch/BULKSEARCH_SET_SESSIONS'
export const BULKSEARCH_SET_DATELIST = 'bulksearch/BULKSEARCH_SET_DATELIST'
export const BULKSEARCH_SET_SELECTED = 'bulksearch/BULKSEARCH_SET_SELECTED'
export const BULKSEARCH_SET_MONTH = 'bulksearch/BULKSEARCH_SET_MONTH'
export const BULKSEARCH_SET_DATE = 'bulksearch/BULKSEARCH_SET_DATE'
export const BULKSEARCH_DELETE_LESSON = 'bulksearch/BULKSEARCH_DELETE_LESSON'
export const BULKSEARCH_SET_PROGRAM = 'bulksearch/BULKSEARCH_SET_PROGRAM'
export const BULKSEARCH_SET_SEARCH_RESULTS = 'bulksearch/BULKSEARCH_SET_SEARCH_RESULTS'
export const BULKSEARCH_SET_SEARCH_UNITS = 'bulksearch/BULKSEARCH_SET_SEARCH_UNITS'
export const BULKSEARCH_SET_BOOKING_STARTED = 'bulksearch/BULKSEARCH_SET_BOOKING_STARTED'
export const BULKSEARCH_SET_SELECTED_SESSION = 'bulksearch/BULKSEARCH_SET_SELECTED_SESSION'
export const BULKSEARCH_SET_SESSIONS_SELECTED = 'bulksearch/BULKSEARCH_SET_SESSIONS_SELECTED'

export function setParams(params) {
  return {
    type: BULKSEARCH_SET_PARAMS,
    params,
  }
}

export function setSessions(sessions) {
  return {
    type: BULKSEARCH_SET_SESSIONS,
    sessions,
  }
}

export function setDateList(dateList) {
  return {
    type: BULKSEARCH_SET_DATELIST,
    dateList,
  }
}

export function setSelected(selected) {
  return {
    type: BULKSEARCH_SET_SELECTED,
    selected,
  }
}

export function setMonth(month) {
  return {
    type: BULKSEARCH_SET_MONTH,
    month,
  }
}

export function setDate(date) {
  return {
    type: BULKSEARCH_SET_DATE,
    date,
  }
}

export function deleteLesson(month, schedules) {
  return {
    type: BULKSEARCH_DELETE_LESSON,
    month,
    schedules,
  }
}

export function setProgram(program) {
  return {
    type: BULKSEARCH_SET_PROGRAM,
    program,
  }
}

export function setSearchResults(searchResults) {
  return {
    type: BULKSEARCH_SET_SEARCH_RESULTS,
    searchResults,
  }
}

export function setSearchUnits(searchUnits) {
  return {
    type: BULKSEARCH_SET_SEARCH_UNITS,
    searchUnits,
  }
}

export function setBookingStarted(bookingStarted) {
  return {
    type: BULKSEARCH_SET_BOOKING_STARTED,
    bookingStarted,
  }
}

export function setSelectedSession(month, schedules, SessionId) {
  return {
    type: BULKSEARCH_SET_SELECTED_SESSION,
    month,
    schedules,
    SessionId,
  }
}

export function setSessionsSelected(sessionsSelected) {
  return {
    type: BULKSEARCH_SET_SESSIONS_SELECTED,
    sessionsSelected,
  }
}
