import { withRedux } from '@redux/redux'
import React, { useEffect, useState } from 'react'

interface NoSSRProps {
  children: React.ReactElement
}

const NoSSRLayout = ({ children }: NoSSRProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return <>{isMounted ? <>{children}</> : null}</>
}

export default withRedux(NoSSRLayout)
