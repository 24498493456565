export const SET_PENDING_COUNT = 'pendingLessons/SET_PENDING_COUNT'
export const SET_PENDING_LOADING = 'pendingLessons/SET_PENDING_LOADING'
export const SET_PENDING_ERROR = 'pendingLessons/SET_PENDING_ERROR'
export const SET_PENDING_LESSONS = 'pendingLessons/SET_PENDING_LESSONS'
export const SET_PENDING_PROCESSED = 'pendingLessons/SET_PENDING_PROCESSED'

export const setPendingLessonCount = pendingLessonCount => ({
  type: SET_PENDING_COUNT,
  pendingLessonCount,
})

export const setPendingLessonLoading = loading => ({
  type: SET_PENDING_LOADING,
  loading,
})

export const setPendingLessonError = error => ({
  type: SET_PENDING_ERROR,
  error,
})

export const setPendingLessons = lessons => ({
  type: SET_PENDING_LESSONS,
  lessons,
})

export const setProcessedPendingLessons = processed => ({
  type: SET_PENDING_PROCESSED,
  processed,
})
