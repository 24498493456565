import gql from 'graphql-tag'

export const GET_USER_PERMISSIONS = gql`
  query GetUserPermissions {
    userPermissions {
      id
      type
      show
      disallowUsers
      disallowCognitoGroup
      allowedUsers
      allowedCognitoGroup
      description
      action
      dynamicFn
      redirections {
        id
        path
      }
      route
      modifiedBy {
        userName
        timestamp
      }
    }
  }
`
