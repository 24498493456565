export const UPDATE_DASHBOARD_ACTIVE_PROGRAM = 'dashboard/UPDATE_DASHBOARD_ACTIVE_PROGRAM'
export const UPDATE_DASHBOARD_ACTIVE_MATERIAL = 'dashboard/UPDATE_DASHBOARD_ACTIVE_MATERIAL'

export const updateDashboardActiveProgram = (activeProgram: string) => ({
  type: UPDATE_DASHBOARD_ACTIVE_PROGRAM,
  activeProgram,
})

export const updateDashboardActiveMaterial = (activeMaterial: string) => ({
  type: UPDATE_DASHBOARD_ACTIVE_MATERIAL,
  activeMaterial,
})
