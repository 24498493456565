import gql from 'graphql-tag'
import React from 'react'
import { Query } from 'react-apollo'
import QueryResult from '../../../interfaces/QueryResult'

interface QueryPageResults {
  loading: boolean
  data: any
}

interface Props {
  id: string
  children: (results: QueryPageResults) => {}
}

export const GET_GROUP_PROFILE = gql`
  query GetGroupProfile($StudentGroupId: String!) {
    getGroupProfile(StudentGroupId: $StudentGroupId) {
      StudentGroupInfo {
        Id
        Name
        StudentInfos {
          Id
          PurposeOfStudy
          StudentName
          StudentId
        }
      }
    }
  }
`

const QueryGroupProfile: React.FC<Props> = ({ id, children }) => {
  return (
    <Query query={GET_GROUP_PROFILE} variables={{ StudentGroupId: id }} fetchPolicy="network-only">
      {({ loading, data }: QueryResult) =>
        children({
          loading,
          data,
        })
      }
    </Query>
  )
}

export default QueryGroupProfile
