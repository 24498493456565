import { DocumentNode, GraphQLError } from 'graphql'
import GQLRequester from '@utils/helpers/unauth-gql-requester'
import { get } from 'lodash'

class GqlCustomError extends Error {
  message: string
  graphQLErrors: GraphQLError[]
  constructor(graphQLErrors: GraphQLError[], message: string) {
    super(message)
    this.message = message
    this.graphQLErrors = graphQLErrors
  }
}

interface IGqlVariable {
  variables: any
}

async function execute(query: DocumentNode, postVar: IGqlVariable, token?: string) {
  const createError = (message?: string): GraphQLError[] => {
    let defaultErrObj = { code: 1002, message: message || 'Something wnet Wrong in the code' }
    let errorMsg = new GraphQLError(JSON.stringify(defaultErrObj))
    let defaultErrors: GraphQLError[] = [errorMsg]
    return defaultErrors
  }

  try {
    let response = await GQLRequester(query, postVar.variables, token)
    const result = response.data

    if (result?.data) {
      return response.data
    }

    if (response?.data?.errors?.length) {
      let respError = response.data.errors as GraphQLError[]
      throw new GqlCustomError(respError, 'GraphQL error')
    } else {
      throw new GqlCustomError(createError('Something went Wrong'), 'UI Error')
    }
  } catch (error) {
    if (error instanceof GqlCustomError) {
      throw error
    } else {
      let err = error as Error
      throw new GqlCustomError(createError(err.message), err.message)
    }
  }
}

export default execute
