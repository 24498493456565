import { SET_DATE, SET_TITLE } from '../actions/mySchedules'

export const initialState = {
  date: '' as string,
  title: 'Schedule lessons' as string,
}

function mySchedulesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATE:
      return {
        ...state,
        date: action.date,
      }

    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      }

    default:
      return state
  }
}

export default mySchedulesReducer
