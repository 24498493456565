import { H1, H4 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import * as React from 'react'
import { LoadingModalProps } from '../LoadingModal'
import { CenterText, SpinnerWrapper } from '../style'

const LoadingModal: React.FC<LoadingModalProps> = ({ open, onClose, title, subtitle }) => (
  <Modal open={open} onClose={onClose}>
    <SpinnerWrapper>
      <object type="image/svg+xml" data="/images/search-session.svg" width="100%" style={{ maxWidth: '300px' }} />
    </SpinnerWrapper>
    <CenterText>
      <H1 disableMargin>{title}</H1>
      <H4>{subtitle}</H4>
    </CenterText>
  </Modal>
)

export default LoadingModal
