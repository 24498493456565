import gql from 'graphql-tag'

export const PUT_INSTRUCTOR_UNAVAILABILITY = gql`
  mutation PutInstructorUnAvailability($Unavailabilities: [PutInstructorUnAvailabilityInput!]!) {
    putInstructorUnAvailability(Unavailabilities: $Unavailabilities) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
      ServiceResult {
        InstructorUnAvailabilityInfo {
          UnAvailabilityType
          TimeZone
          StartDaterTime
          Recurrance
          LearningCenterName
          LearningCenterId
          KeepExistingLessons
          InstructorProfileId
          InstructorName
          Id
          EndDateTime
          Approved
          ApprovalStatus
          AllDay
        }
      }
    }
  }
`

export const PUT_UNAVAILABILITIES = gql`
  mutation UnAvailabilities(
    $FormValues: PutAvailabilityFormModelInput!
    $Instructor: PutUnAvailabilityInstructorModelInput!
  ) {
    putUnAvailabilities(FormValues: $FormValues, Instructor: $Instructor) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
    }
  }
`

export const BULK_DELETE_UNAVAILABILITIES = gql`
  mutation bulkDeleteUnAvailability($payload: PutInstructorDeleteUnAvailabilityInput!) {
    bulkDeleteUnAvailability(payload: $payload) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
    }
  }
`
