export const USER_COGNITO_INIT = 'cognito/USER_COGNITO_INIT'
export const USER_COGNITO_PREV = 'cognito/USER_COGNITO_PREV'
export const USER_COGNITO_NEXT = 'cognito/USER_COGNITO_NEXT'
export const USER_COGNITO_LOAD = 'cognito/USER_COGNITO_LOAD'
export const USER_COGNITO_ACTION_LOAD = 'USER_COGNITO_ACTION_LOAD'
export const USER_COGNITO_ACTIVATION = 'cognito/USER_COGNITO_ACTIVATION'
export const USER_COGNITO_RESET = 'cognito/USER_COGNITO_RESET'
export const USER_COGNITO_FORCE_PASSWORD = 'USER_COGNITO_FORCE_PASSWORD'
export const USER_COGNITO_MESSAGE = 'USER_COGNITO_MESSAGE'

export const cognitoInit = (users, next, group, search = { searchKey: '', attribute: 'email' }) => ({
  type: USER_COGNITO_INIT,
  users,
  next,
  group,
  search,
})

export const cognitoPrev = prev => ({
  type: USER_COGNITO_PREV,
  prev,
})

export const cognitoNext = (users, next) => ({
  type: USER_COGNITO_NEXT,
  users,
  next,
})

export const cognitoLoad = loading => ({
  type: USER_COGNITO_LOAD,
  loading,
})

export const cognitoActionLoad = loading => ({
  type: USER_COGNITO_ACTION_LOAD,
  loading,
})

export const cognitoUserActivate = (user, status) => ({
  type: USER_COGNITO_ACTIVATION,
  user,
  status,
})

export const cognitoUserReset = user => ({
  type: USER_COGNITO_RESET,
  user,
})

export const cognitoForcePassword = user => ({
  type: USER_COGNITO_FORCE_PASSWORD,
  user,
})

export const cognitoMessage = message => ({
  type: USER_COGNITO_MESSAGE,
  message,
})
