import * as React from 'react'
import ContentLoader from 'react-content-loader'
import Resizable from '../../interfaces/Resizable'

const RectLoader: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={42}
    width={500}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  />
)

const CircleLoader: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={38}
    width={38}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  >
    <circle cx="20" cy="20" r="19" />
  </ContentLoader>
)

export { RectLoader, CircleLoader }
export default RectLoader
