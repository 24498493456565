export const LessonTypeOptions = [
  {
    id: 'Face to face',
    label: 'Face to face',
    value: 'Face to face',
  },
  {
    id: 'Online',
    label: 'Online',
    value: 'Online',
  },
]

export const AvailabilityTypeOptions = [
  {
    id: 'Core',
    key: 'Core',
    label: 'Core',
    value: 'Core',
  },
  {
    id: 'Flex',
    key: 'Flex',
    label: 'Flex',
    value: 'Flex',
  },
]

export const TypeOptions = [
  {
    id: 'Holiday',
    label: 'Holiday',
    value: 'Holiday',
  },
  {
    id: 'Sick',
    label: 'Sick',
    value: 'Sick',
  },
  {
    id: 'Private',
    label: 'Private',
    value: 'Private',
  },
  {
    id: 'Vacation',
    label: 'Vacation',
    value: 'Vacation',
  },
  {
    id: 'Leave Of Absence',
    label: 'Leave Of Absence',
    value: 'Leave Of Absence',
  },
]

export const RepeatOnOptions = [
  {
    id: 0,
    label: 'Monday',
    value: 0,
  },
  {
    id: 1,
    label: 'Tuesday',
    value: 1,
  },
  {
    id: 2,
    label: 'Wednesday',
    value: 2,
  },
  {
    id: 3,
    label: 'Thursday',
    value: 3,
  },
  {
    id: 4,
    label: 'Friday',
    value: 4,
  },
  {
    id: 5,
    label: 'Saturday',
    value: 5,
  },
  {
    id: 6,
    label: 'Sunday',
    value: 6,
  },
]

export const flexOnlyContractTypes = {
  America: ['Contracted Guaranteed', 'Contracted Not Guaranteed', 'Freelance'],
}
