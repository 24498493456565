import * as React from 'react'
import { Wrapper } from './style'

interface IconWrapperProps {
  children: React.ReactNode
  filled?: boolean
}

// TODO: pass props for legend or action probably?
const IconWrapper: React.FunctionComponent<IconWrapperProps> = ({ children, filled }) => (
  <Wrapper filled={!!filled}>{children}</Wrapper>
)

export default IconWrapper
