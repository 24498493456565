// import { Profile } from '@berlitz/icons'
import Button from '@berlitz/button'
import { H3, H4, P } from '@berlitz/globals'
import List from '@berlitz/list'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import Alert from '@components/Alert'
import EmptyPlaceholder from '@components/EmptyPlaceholder'
import HR from '@components/HR'
import isEmpty from 'lodash/isEmpty'
import Link from 'next/link'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SingleProfileModalProps } from './interface'
import Loader from './Loader'
import QuerySingleProfile from './QuerySingleProfile'
import {
  ActionButtons,
  Box,
  EPContainer,
  TextContainer,
  // ViewProfile
} from './style'

const SingleProfileModal: React.FC<SingleProfileModalProps> = ({
  open,
  onClose,
  StudentId,
  StudentName,
  ProgramId,
  Language,
}) => (
  <Modal open={open} onClose={onClose}>
    <QuerySingleProfile StudentId={StudentId} Language={Language}>
      {({ loading, data, lessonExpectations }) => {
        if (loading) {
          return <Loader />
        }

        const Purpose = data?.StudentLanguageProfileInfos?.[0]?.Purpose
        const MICommentProfile = data?.MIComment
        const LearningGoals = data?.StudentLanguageProfileInfos?.[0]?.LearningGoals

        const programs = data?.ProgramStudentWrappers ?? []
        const program = programs.find((_program) => _program.ProgramStudentInfo.ProgramId === ProgramId)
        const MICommentProgram = program?.ProgramStudentInfo?.MIComment

        return (
          <Box>
            {isEmpty(data) && (
              <EPContainer>
                <EmptyPlaceholder msg="Empty student info." />
              </EPContainer>
            )}

            {!isEmpty(data) && (
              <>
                <H3>{StudentName}</H3>

                <H4 disableMargin>
                  <FormattedMessage id="Purpose of study" />
                </H4>
                <Spacer size="sm" />
                <P size="sm" className="no-margin">
                  {Purpose ? Purpose : <FormattedMessage id="Not yet specified" />}
                </P>

                <HR />

                <H4 disableMargin>
                  <FormattedMessage id="Lesson expectations" />
                </H4>
                <Spacer size="sm" />
                <List data={lessonExpectations} cols={1} collapse={['xs', 'sm', 'md']} />

                <HR />

                <H4 disableMargin>
                  <FormattedMessage id="Learning goals" />
                </H4>
                <Spacer size="sm" />
                <P size="sm" className="no-margin">
                  {LearningGoals ? LearningGoals : <FormattedMessage id="Not yet specified" />}
                </P>
              </>
            )}

            {/* <Link href="#">
              <a>
                <ViewProfile>
                  <Profile size="xs" />
                  <FormattedMessage id="View profile" />
                </ViewProfile>
              </a>
            </Link> */}

            <Spacer size="lg" />

            {MICommentProfile && (
              <Alert alertType="error" dismissable={false}>
                <TextContainer>{MICommentProfile}</TextContainer>
              </Alert>
            )}

            {MICommentProgram && (
              <Alert alertType="error" dismissable={false}>
                <TextContainer>{MICommentProgram}</TextContainer>
              </Alert>
            )}

            <Spacer size="lg" />

            <ActionButtons>
              <>
                <Link
                  href={'/instructors/students/[id]/learning'}
                  as={`/instructors/students/${StudentId}/learning`}
                  legacyBehavior
                >
                  <div>
                    <Button outline={true}>
                      <FormattedMessage id="Manage account" />
                    </Button>
                  </div>
                </Link>

                <Button onClick={onClose}>
                  <FormattedMessage id="Close" />
                </Button>
              </>
            </ActionButtons>
          </Box>
        )
      }}
    </QuerySingleProfile>
  </Modal>
)

export default SingleProfileModal
