import languages from '@layouts/PortalTranslations/languages.json'
import { DEFAULT_LANGUAGE } from '@utils/constants'

export const getPortalsLanguage = lang => {
  const userLang = lang ? lang : DEFAULT_LANGUAGE
  const currLang = languages.find(({ id }) => userLang === id)

  if (currLang?.show) {
    return userLang
  }

  const fallbackLang = languages.find(({ id, show }) => id === userLang?.split('-')?.[0] && show)

  return fallbackLang ? fallbackLang?.id : DEFAULT_LANGUAGE
}

export const getLocale = (lang?: string) => getPortalsLanguage(lang || getNavigatorLanguage())

// Do not use navigator language as fallback as discussed with Jorge.
// All should default to English
export const getNavigatorLanguage = () =>
  // (navigator &&
  //   // @ts-ignore
  //   (navigator?.languages?.[0] ?? navigator?.userLanguage ?? navigator?.language ?? navigator?.browserLanguage)) ||
  DEFAULT_LANGUAGE
