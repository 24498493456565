import { StoreProps } from '@interfaces/StoreState'
import { createSelector } from '@reduxjs/toolkit'
import { REG_STATUS } from '@utils/constants'

const selectRegistrationWrappers = (state: StoreProps) =>
  state?.userProfile?.info?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers ?? []
const selectActiveMaterial = (state: StoreProps) => state?.dashboard?.activeMaterial ?? undefined

export const selectRegistration = createSelector(
  [selectRegistrationWrappers, selectActiveMaterial],
  (registrationWrappers, activeMaterial) => {
    let registration = registrationWrappers?.find(
      ({ RegistrationInfo }) => RegistrationInfo?.Materials?.[0]?.LPID === activeMaterial
    )

    /**
     * If registration var is empty,
     * then we might try to match it with RegistrationId
     */
    if (!registration) {
      registration = registrationWrappers?.find(
        ({ RegistrationInfo }) => RegistrationInfo?.RegistrationId === activeMaterial
      )
    }
    return registration?.RegistrationInfo
  }
)

export const selectIsActive = createSelector([selectRegistration], (registration) => {
  return registration?.Status === REG_STATUS.ACTIVE
})

export default { selectRegistration, selectIsActive }
