import { useApolloClient } from '@apollo/react-hooks'
import { StoreProps } from '@interfaces/StoreState'
import { GetUserProfile } from '@interfaces/UserProfile'
import { GET_USER_PROFILE } from '@queries/userProfile'
import { fetchProfileSuccess } from '@redux/actions/userProfile'
import { PROFILE_TYPE } from '@utils/constants'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

type IAction = <Type = any>(query?: Function) => Promise<[() => Promise<void> | undefined, Type]>

const useRefetchProfile = <Type = any>() => {
  const { authorized, roles } = useSelector(({ session }: StoreProps) => ({
    authorized: session?.authorized,
    roles: session.cognitoGroups,
  }))

  const dispatch = useDispatch()
  const client = useApolloClient()

  const role = roles?.find(roleData => roleData === __USER_GROUP_INSTRUCTORS__)
    ? PROFILE_TYPE.INSTRUCTOR
    : PROFILE_TYPE.STUDENT

  const action = useCallback(async asyncFunc => {
    let data: Type | undefined
    let triggerUIUpdate: undefined | (() => Promise<void>)
    try {
      const variables = {
        Type: role,
        Id: authorized,
      }

      if (asyncFunc) {
        data = await asyncFunc()
      }

      const res = await client.query<GetUserProfile>({
        query: GET_USER_PROFILE,
        variables,
        fetchPolicy: 'network-only',
      })

      triggerUIUpdate = async () => {
        return new Promise(resolve => {
          if (res?.data?.getUserProfile) {
            dispatch(fetchProfileSuccess(res.data.getUserProfile))
          }

          setTimeout(resolve, 500) // give time for UI to be updated
        })
      }
    } catch (e) {
      console.log(e)
    }

    return [triggerUIUpdate, data]
  }, [])

  return action as IAction
}

export default useRefetchProfile
