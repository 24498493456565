import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.space.md};
`

export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.no-padding-left {
    padding-left: 0;
  }
`

export const StyledCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.space.sm};

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &.margin-bottom-xxs {
    margin-bottom: ${({ theme }) => theme.space.xxs};
  }

  &.no-margin {
    margin: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.no-padding-left {
    padding-left: 0;
  }

  &.no-padding {
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: ${({ theme }) => theme.space.xxs};
  }
`

export const SpinnerBox = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space.xs};
  margin-right: ${({ theme }) => theme.space.xs};
  justify-content: flex-end;
`

export const DatePickerCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none !important;
  }
`
