import { resizeImage } from '@berlitz/max-utils'
import Head from 'next/head'
import React from 'react'
import { FC } from 'react'

interface imageType {
  url?: string
  description?: string
  title?: string
}

interface SEOProps {
  description?: string
  image?: imageType
  title?: string
  noMainTitle?: boolean
  dontTranslate?: boolean
}

const SEO: FC<SEOProps> = ({ title, description, image, noMainTitle }) => {
  const imageUrl = image && image.url && resizeImage(image.url, 1200, 630)
  const translatedMainTitle = noMainTitle ? `` : process.env.SITE_TITLE
  const separator = title && !noMainTitle ? ' | ' : ``
  const pageTitle = `${title}${separator}${translatedMainTitle}`
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
      <meta name="title" content={pageTitle} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={pageTitle} />
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content="/" />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {imageUrl && (
        <>
          <meta name="twitter:creator" content={imageUrl} />
          <meta property="og:image" content={imageUrl} />
        </>
      )}
    </Head>
  )
}

export default SEO
