import QueryResult from '@interfaces/QueryResult'
import {
  StudentLanguageProfileInfo as StudentLanguageProfileInfoInterface,
  StudentProfileInfo as StudentProfileInfoInterface,
} from '@interfaces/Student'
import { GET_LEARNING_PROFILES } from '@queries/index'
import { languagesDropdown } from '@utils/helpers/languages'
import { lessonExpectations } from '@utils/stubdata/settings.json'
import gql from 'graphql-tag'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isBoolean from 'lodash/isBoolean'
import map from 'lodash/map'
import React from 'react'
import { Query } from 'react-apollo'

interface QueryPageResults {
  loading: boolean
  data: StudentProfileInfoInterface
  lessonExpectations: string[]
}

interface Props {
  StudentId: string
  Language: string
  children: (results: QueryPageResults) => {}
}

export const GET_DATA = gql`
  query GetStudents($StudentId: String!) {
    getStudents(StudentId: $StudentId) {
      StudentProfileInfo {
        Purpose
        PortalLanguage
        MIComment
        StudentLanguageProfileInfos {
          AllNotesWrittenOnTheTakeaway
          CustomizedToLifeSituations
          CustomizedToWorkSituation
          DoesnotWantStrictCorrection
          FocusOnPronunciation
          GetHintsOrSupport
          HaveRolePlays
          KeepSelfIntroductionsToMinimum
          Language
          LearnPoliteFormalExpressions
          LearnWordsAndExpressions
          NoNaturalWayToSaySomething
          RecordId
          RepeatCorrectedWordsOrPhrases
          SpeakAsMuchAsPossible
          SpeakAtNaturalSpeed
          StrictCorrection
          StudentProfileId
          TaughtFullSentences
        }
        ProgramStudentWrappers {
          ProgramStudentInfo {
            OnlineLessonURL
            ProgramStudentId
            ProgramId
            LessonsTaken
            TotalLessons
            LessonsRemaining
            LessonFrequency
            Language
            DeliveryMode
            ProgramType
            ProgramEnrollDate
            ProgramName
            MIComment
            ValidforCounselling
            CounsellingStatus
            Materials {
              RecordId
              NewRowPlaylistId
              LPID
            }
          }
        }
        RegistrationWrappers {
          RegistrationInfo {
            DisplayName
            TotalLessons
            Status
            RegistrationId
            Region
            ElectiveRequired
            Programs {
              VirtualClassRoomProvider
              OnlineLessonURL
              ValidforOrientation
              ValidforCounselling
              UnitsPerLesson
              TotalSessionsComplete
              TotalRescheduleAllowed
              TotalLessons
              TotalCancellationAllowed
              Status
              SingleSessionScheduling
              RescheduleCount
              ProgramType
              ProgramName
              ProgramId
              ProgramEnrollDate
              PremiumPlusRate
              PortalNotNecessary
              Materials {
                RecordId
                NewRowPlaylistId
                LPID
              }
              LessonsTaken
              LessonsRemaining
              LessonsPerSession
              LessonsBooked
              LessonFrequency
              LearningCenterName
              LearningCenterId
              LanguageLevel
              Language
              IsGroup
              IsChild
              HoursBeforeCutOff
              DeliveryLCTimeZone
              CustomerType
              ContractEndDate
              CancelCount
              BulkScheduling
              BlockScheduling
            }
            Name
            Materials {
              Status
              RegistrationId
              RecordId
              Name
              MaterialType
              MaterialSubType
              MaterialName
              MaterialId
              LPID
              LPExternalName
              IsElective
              IsTest
            }
            LessonsRemaining
            LearningCenterName
            LearningCenterId
            LanguageLevel
            Language
            FlexLevelStatus
            FlexVersion
            FlexLastLevel
          }
        }
      }
    }
  }
`

const languages = languagesDropdown()

const QueryGroupProfile: React.FC<Props> = ({ StudentId, Language, children }) => {
  return (
    <Query query={GET_DATA} variables={{ StudentId, Language }} fetchPolicy="network-only">
      {({ loading, data }: QueryResult) => {
        const StudentProfileInfo: StudentProfileInfoInterface = get(data, 'getStudents[0].StudentProfileInfo', {})
        const languageInfo = languages.find(({ id }) => id === Language)
        const lang = languageInfo ? languageInfo.label : Language

        const studentLoading = loading
        return (
          <Query query={GET_LEARNING_PROFILES} variables={{ StudentId, Language: lang }} fetchPolicy="network-only">
            {({ loading: queryLoading, data: queryData }: QueryResult) => {
              let profileInfoIds: Array<string | false>
              const profiles: StudentLanguageProfileInfoInterface[] = get(queryData, 'getLearningProfiles', []).map(
                ({ StudentLanguageProfileInfo }) => StudentLanguageProfileInfo
              )

              if (!studentLoading) {
                const profile = find(profiles, { Language: lang })

                profileInfoIds = map(profile, (value, key) => isBoolean(value) && value && key).filter((id) => id)
              }

              return children({
                loading: studentLoading || queryLoading,
                data: {
                  ...StudentProfileInfo,
                  StudentLanguageProfileInfos: profiles,
                },
                lessonExpectations: lessonExpectations
                  .map(({ id, label }) => (includes(profileInfoIds, id) && label) || '')
                  .filter((label) => label),
              })
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default QueryGroupProfile
