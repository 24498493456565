import gql from 'graphql-tag'

export const GET_TRANSLATIONS = gql`
  query getLocalization($locale: String!) {
    localization(locale: $locale) {
      translations
      locale
    }
  }
`
