import React from 'react'
import { StyledHR } from './style'

export interface Props {
  disableMargin?: boolean
}

const HR = ({ disableMargin }: Props) => <StyledHR disableMargin={disableMargin} />

export default HR
