import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from './GroupContext'
import { RadioGroup } from './style'

Provider.displayName = 'RadioGroupProvider'

const Group = ({ name, value, onChange, children, ...rest }) => (
  <Provider
    value={{
      name,
      value,
      onChange,
    }}
  >
    <RadioGroup role="radiogroup" {...rest}>
      {children}
    </RadioGroup>
  </Provider>
)

Group.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  flow: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Group
