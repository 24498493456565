import styled from 'styled-components'

export const PortalTimeFormatModalWrapper = styled.div<{ saved?: boolean }>`
  ${({ saved }) => `
    button[aria-label='Close Modal'] {
      display: ${!saved ? 'none' : 'block'};
    }
  `}
`

export const SpinnerWrapper = styled.div`
  span {
    width: 72px;
    height: 72px;
    border-width: 8px;
  }
`

export const SuccessWrapper = styled.div`
  ${({ theme }) => `
    width: 106px;
    height: 106px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.success100};
    
    svg {
      width: 64px;
      height: 64px;
      color: ${theme.palette.white};
    }
  `}
`

export const Header = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin-top: -32px;
`
