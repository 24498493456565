import { CognitoUserProps } from '@interfaces/StoreState'

export const USER_LOGOUT = 'session/USER_LOGOUT'
export const USER_LOGIN_SUCCESS = 'session/USER_LOGIN_SUCCESS'
export const USER_NOT_AUTHORISED = 'session/USER_NOT_AUTHORISED'
export const ADMIN_UPDATE_GROUP = 'session/ADMIN_UPDATE_GROUP'
export const UPDATE_AUTHORIZE_VALUE = 'session/UPDATE_AUTHORIZE_VALUE'

export const userLogout = () => ({
  type: USER_LOGOUT,
})

export const userLoggedIn = (user: CognitoUserProps, accessAs = {}) => ({
  type: USER_LOGIN_SUCCESS,
  user,
  accessAs,
})

export const userNotAuthorised = (error) => ({
  type: USER_NOT_AUTHORISED,
  error,
})

export const updateAdminGroup = (group) => ({
  type: ADMIN_UPDATE_GROUP,
  cognitoGroup: group.cognito,
  SFId: group.id,
})

export const updateAuthorized = (authorized: string, authorizedKey = 'StudentId') => ({
  type: UPDATE_AUTHORIZE_VALUE,
  authorized,
  authorizedKey,
})
