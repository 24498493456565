import Modal from '@berlitz/modal'
import React from 'react'

import { TransparentContainer, Wrapper } from './style'

interface TransparentModalProps {
  onClose: () => void
  open: boolean
  children: React.ReactNode
}

const TransparentModal: React.FC<TransparentModalProps> = ({ children, open, onClose }) => (
  <TransparentContainer>
    <Modal open={open} onClose={onClose}>
      <Wrapper>{children}</Wrapper>
    </Modal>
  </TransparentContainer>
)

export default TransparentModal
