import { PortalFeaturesFactory } from '@classes/PortalFeatures'
import PortalPrograms from '@classes/Programs'
import PortalStudentProfile from '@classes/StudentProfile'
import { UserProfileProps } from '@interfaces/UserProfile'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import { REGIONAL_USER_ROLES } from './constants'
import { getQueryParams } from './helpers'
import { IsOnDemand } from './helpers/material'

interface IRolebased {
  profile: UserProfileProps
  roles?: string[]
}

export const instructorHasLiveOnlineLC = ({ profile }: IRolebased): boolean => {
  const learningCenters = profile?.InstructorProfile?.instructorInfo?.learningcenters

  if (learningCenters) {
    return learningCenters.some(lc => {
      const name = get(lc, 'Name', '')
      return name && name.toLowerCase().includes('live online')
    })
  }
  return false
}

export const studentHasOnlineProgram = ({ profile }: IRolebased) =>
  new PortalPrograms(profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers).hasOnlineProgram()

export const studentHasFlexPrograms = ({ profile }: IRolebased) =>
  new PortalPrograms(profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers).hasFlexPrograms() ||
  profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers?.some(
    reg => reg && IsOnDemand(reg?.RegistrationInfo)
  )

export const newLearningExperience = ({ profile }: IRolebased) =>
  studentHasFlexPrograms({ profile }) && PortalFeaturesFactory(profile).hasNewLearningExperience()

export const studentHasLiveOnlinePrograms = ({ profile }: IRolebased) =>
  new PortalPrograms(profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers).hasLiveOnlinePrograms()

export const studentHasFaceToFacePrograms = ({ profile }: IRolebased) =>
  new PortalPrograms(profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers).hasFaceToFacePrograms()

export const studentHasLiveOnlineInGroup = ({ profile }: IRolebased) =>
  new PortalPrograms(profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers).studentHasLiveOnlineInGroup()

export const studentHasliveOnlinePrivate = ({ profile }: IRolebased) => !studentHasLiveOnlineInGroup({ profile })

export const studentHasSchedInstructorsTab = ({ profile }: IRolebased) =>
  new PortalStudentProfile(profile?.StudentProfile?.StudentProfileInfo).hasSchedInstructorsTab()

export const userHasEventsTab = ({ profile }: IRolebased) => {
  if (profile.StudentProfile) {
    return new PortalStudentProfile(profile?.StudentProfile?.StudentProfileInfo).hasEventsTab()
  }

  return !isEmpty(profile.InstructorProfile)
}

export const hasUserProfile = ({ profile }: IRolebased) =>
  !isEmpty(profile.StudentProfile) || !isEmpty(profile.InstructorProfile)

export const userIsRegional = ({ profile, roles = [] }: IRolebased) =>
  !hasUserProfile({ profile }) && roles.some(r => REGIONAL_USER_ROLES.includes(r)) && getQueryParams('sid')

export default {
  studentHasOnlineProgram,
  instructorHasLiveOnlineLC,
  studentHasFlexPrograms,
  studentHasLiveOnlinePrograms,
  studentHasFaceToFacePrograms,
  studentHasLiveOnlineInGroup,
  studentHasliveOnlinePrivate,
  newLearningExperience,
  studentHasSchedInstructorsTab,
  userHasEventsTab,
  hasUserProfile,
  userIsRegional,
}
