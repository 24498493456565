import styled from 'styled-components'

export const EventWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.inline {
    justify-content: flex-start;
  }

  .close {
    cursor: pointer;

    svg {
      font-size: ${({ theme }) => theme.fontSize.xxxs};
      color: ${({ theme }) => theme.palette.danger100} !important;
    }
  }
`

export const EventTitle = styled.div`
  display: flex;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const RadioWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space.sm};
`

export const ButtonWrapper = styled.div`
  margin-top: -14px;
`
