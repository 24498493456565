import React from 'react'

const PreloadImages = () => (
  <>
    <link rel="preload" href="/images/search-session.svg" as="image" />
    <link rel="preload" href="/images/404.svg" as="image" />
    <link rel="preload" href="/images/all-lesson-booked.svg" as="image" />
    <link rel="preload" href="/images/empty-events-24.svg" as="image" />
    <link rel="preload" href="/images/empty-notes.svg" as="image" />
    <link rel="preload" href="/images/group.svg" as="image" />
    <link rel="preload" href="/images/lc-empty-state.svg" as="image" />
    <link rel="preload" href="/images/locked.svg" as="image" />
    <link rel="preload" href="/images/lost.svg" as="image" />
    <link rel="preload" href="/images/reschedule-addon.svg" as="image" />
    <link rel="preload" href="/images/room-search.svg" as="image" />
    <link rel="preload" href="/images/search.svg" as="image" />
    <link rel="preload" href="/images/search-session.svg" as="image" />
    <link rel="preload" href="/images/thank-you.svg" as="image" />
    <link rel="preload" href="/images/bug.svg" as="image" />
    <link rel="preload" href="/images/construction.svg" as="image" />
    <link rel="preload" href="/images/single-session-booked.svg" as="image" />
    <link rel="preload" href="/images/events.svg" as="image" />
  </>
)

export default React.memo(PreloadImages)
