export default (hide?: boolean) => {
  !hide &&
    // tslint:disable-next-line: no-console
    console.log(
      '\n' +
        '                _        _    \n' +
        '  _ __  ___ _ _| |_ __ _| |___\n' +
        " | '_ \\/ _ \\ '_|  _/ _` | (_-<\n" +
        ' | .__/\\___/_|  \\__\\__,_|_/__/\n' +
        ' |_|                          \n'
    )
}
