import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'antd'
import styled from 'styled-components'
import 'antd/lib/badge/style/index.css'

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background: ${({ theme }) => theme.colors.brandPrimary};
    border-radius: ${({ theme }) => theme.radius.xl};
    box-shadow: none;
  }

  .ant-badge-dot {
    width: ${({ theme }) => theme.space.sm};
    height: ${({ theme }) => theme.space.sm};
    border: ${({ theme }) => `1px solid ${theme.colors.ui00}`};
  }
`

const BadgeComponent = (props) => <StyledBadge {...props} />

BadgeComponent.propTypes = {
  count: PropTypes.node,
  dot: PropTypes.bool,
  offset: PropTypes.array,
  overflowCount: PropTypes.number,
  showZero: PropTypes.bool,
  status: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

export default BadgeComponent
