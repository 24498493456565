import { RegistrationInfo, RegistrationMaterial } from '@interfaces/Student'
import { PROGRAM_TYPES } from '@utils/constants'

export const getStoredMaterials = (sfId?: string) => {
  let materials
  try {
    if (sfId) {
      materials = JSON.parse(localStorage.getItem('portal-selected-material') || '')[sfId]
    } else {
      materials = JSON.parse(localStorage.getItem('portal-selected-material') || '')
    }
  } catch (e) {
    // it might not exist yet
    materials = {}
  }

  return materials
}

export const IsOnDemand = (material: RegistrationMaterial | RegistrationInfo) => {
  return [...PROGRAM_TYPES.ON_DEMAND].indexOf(material?.ProgramType || '') >= 0
}

export const IsJapanFlex = (material: RegistrationMaterial | RegistrationInfo) => {
  return (
    (!(material as RegistrationMaterial)?.ProgramId ||
      (!(material as RegistrationInfo)?.Programs && !(material as RegistrationInfo)?.Programs?.length)) &&
    [...PROGRAM_TYPES.JPN_FLEX, ...PROGRAM_TYPES.FLEX].indexOf(material?.ProgramType || '') >= 0
  )
}

export const FlexProgram = (material: RegistrationMaterial | RegistrationInfo) => {
  return (
    material?.ProgramType &&
    PROGRAM_TYPES.FLEX.some(program => program.toLowerCase().includes((material?.ProgramType || '').toLowerCase()))
  )
}

export const IsFlex = (material?: RegistrationMaterial | RegistrationInfo) => {
  return material ? FlexProgram(material) || IsJapanFlex(material) || IsOnDemand(material) : false
}
