import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { Col, Row } from 'react-styled-flexboxgrid'
import enGB from 'date-fns/locale/en-GB'
import { ArrayHelpers, Field, FieldArray, FieldProps, Formik, FormikErrors, FormikProps } from 'formik'
import moment from 'moment'
import Button from '@berlitz/button'
import Spacer from '@berlitz/spacer'
import WithUserTimeHOC, { WithUserTimeProps } from '@hocs/WithUserTime'

import Datepicker from '@components/Datepicker'
import Checkbox from '@components/Checkbox'
import Select from '@components/Select'
import { AvailabilityTypeOptions, RepeatOnOptions } from '@components/Modal/AvailabilityModal/constants'
import { FormProps, FormValueProps } from './interface'
import { Footer, Label } from './style'
import { isEmpty } from 'lodash'
import { DATE_FORMAT } from '@utils/constants'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import PortalInstructor from '@classes/Instructor'

const AvailabilityBulkDeleteForm: React.FC<FormProps & InjectedIntlProps & WithUserTimeProps> = ({
  intl,
  onClose,
  onDelete,
  initialValues,
  isAdmin,
  toDate = () => new Date(),
  currentDate = moment(),
}) => {
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [checkAll, setCheckAll] = useState(false)

  const profile = useSelector(({ userProfile }: StoreProps) => userProfile?.info)
  const Instructor = new PortalInstructor(profile.InstructorProfile.instructorInfo)

  useEffect(() => {
    setStartDate(initialValues?.StartDate?.clone().format(DATE_FORMAT.DATEPICKER))
    setEndDate(initialValues?.EndDate?.clone().format(DATE_FORMAT.DATEPICKER))
  }, [initialValues])

  const changeDate = useCallback((cb) => cb(), [])
  const debouceRequest = useCallback((cb) => changeDate(cb), [])
  const isInvalidDates = () => {
    const mStart = moment(startDate, DATE_FORMAT.DATEPICKER, true)
    const mEnd = moment(endDate, DATE_FORMAT.DATEPICKER, true)
    return (
      !mStart.isValid() || !mEnd.isValid() || mStart.isAfter(mEnd) || mStart.isBefore(Instructor.CurrentDate, 'day')
    )
  }

  const minDate = toDate(currentDate.clone().add(1, 'day'))

  const isDateValid = (date: string = '') => date.match(/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/)
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        onDelete(values)
      }}
      validate={(values: FormValueProps) => {
        const errors: FormikErrors<FormValueProps> = {}

        if (!values.RepeatOn.length) {
          RepeatOnOptions.map((_date, index) => {
            errors[`RepeatOn.${index}`] = 'Required'
          })
        }

        return errors
      }}
      render={({ errors, values, isValid, handleSubmit }: FormikProps<FormValueProps>) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6} md={3}>
              <Field
                name="StartDate"
                render={({ form, field }: FieldProps<FormValueProps>) => (
                  <Datepicker
                    locale={enGB}
                    value={startDate}
                    selected={toDate(values.StartDate.clone())}
                    dateFormat="dd/MM/yyyy"
                    minDate={minDate}
                    maxDate={toDate(values.EndDate)}
                    label={intl.formatMessage({ id: 'Start date' })}
                    openToDate={toDate(values.StartDate)}
                    onSelect={(val, event) => {
                      const d = moment(val)

                      if (val && d.isValid() && event.type === 'click') {
                        form.setFieldValue(field.name, d)
                        setStartDate(d.format(DATE_FORMAT.DATEPICKER))
                      } else {
                        form.setFieldError(field.name, 'Required')
                      }
                    }}
                    onChangeRaw={(e) => {
                      debouceRequest(() => {
                        const value = e.currentTarget.value || ''
                        setStartDate(value)

                        if (isDateValid(value)) {
                          const d = moment(value, DATE_FORMAT.DATEPICKER)
                          form.setFieldValue(field.name, d)
                          setStartDate(d.format(DATE_FORMAT.DATEPICKER))
                        } else {
                          form.setFieldError(field.name, 'Required')
                        }
                      })
                    }}
                    isClearable={false}
                  />
                )}
              />
            </Col>

            <Col xs={6} md={3}>
              <Field
                name="EndDate"
                render={({ form, field }: FieldProps<FormValueProps>) => (
                  <Datepicker
                    locale={enGB}
                    value={endDate}
                    selected={toDate(values.EndDate.clone())}
                    dateFormat="dd/MM/yyyy"
                    minDate={toDate(values.StartDate.clone())}
                    label={`${intl.formatMessage({ id: 'End date' })}*`}
                    openToDate={toDate(values.EndDate)}
                    onSelect={(val, event) => {
                      const d = moment(val)

                      if (val && d.isValid() && event.type === 'click') {
                        form.setFieldValue(field.name, d)
                        setEndDate(d.format(DATE_FORMAT.DATEPICKER))
                      } else {
                        form.setFieldError(field.name, 'Required')
                      }
                    }}
                    onChangeRaw={(e) => {
                      debouceRequest(() => {
                        const value = e.currentTarget.value || ''
                        setEndDate(value)

                        if (isDateValid(value)) {
                          const d = moment(e.currentTarget.value, DATE_FORMAT.DATEPICKER)
                          form.setFieldValue(field.name, d)
                          setEndDate(d.format(DATE_FORMAT.DATEPICKER))
                        } else {
                          form.setFieldError(field.name, 'Required')
                        }
                      })
                    }}
                    isClearable={false}
                  />
                )}
              />
            </Col>

            <Col xs={6} md={3}>
              <Spacer size="lg" />
              <Field
                name="KeepLessons"
                render={({ form, field }: FieldProps<FormValueProps>) => (
                  <Checkbox
                    {...field}
                    checked={values.KeepLessons}
                    value={`${values.KeepLessons}`}
                    label={intl.formatMessage({ id: 'Keep lessons' })}
                    field={{
                      name: intl.formatMessage({ id: 'Keep lessons' }),
                    }}
                    onChange={() => form.setFieldValue(field.name, !values.KeepLessons)}
                  />
                )}
              />
            </Col>

            <Col xs={6} md={3}>
              <Field
                name="AvailabilityType"
                render={({ form, field }: FieldProps<FormValueProps>) => (
                  <>
                    <Label>
                      <FormattedMessage id="Availability type" />
                    </Label>
                    <div id="AvailabilityType">
                      <Select
                        {...field}
                        label=""
                        options={AvailabilityTypeOptions}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value)
                        }}
                        getPopupContainer={() => document.getElementById('AvailabilityType')}
                        disableMargin
                        disabled={!isAdmin}
                      />
                    </div>
                  </>
                )}
              />
            </Col>
            <Col xs={12} lg={false}>
              <Spacer size="md" />
            </Col>
          </Row>

          <Spacer size="xs" />

          <FieldArray
            name="RepeatOn"
            render={(arrayHelpers: ArrayHelpers) => (
              <>
                <Label>
                  <FormattedMessage id="Delete availability on these days:" />
                </Label>

                <Row>
                  <Col xs={12} md={3}>
                    <Checkbox
                      checked={checkAll}
                      name="check-all"
                      label={intl.formatMessage({ id: 'All' })}
                      value="All"
                      field={{
                        name: intl.formatMessage({ id: 'All' }),
                      }}
                      onChange={(e: any) => {
                        const { insert, remove, pop } = arrayHelpers
                        setCheckAll(e.target.checked)
                        values.RepeatOn.map((_val, index) => {
                          remove(index)
                        })

                        RepeatOnOptions.map((day, index) => {
                          if (e.target.checked) {
                            insert(index, day.value)
                          } else {
                            pop()
                          }
                        })
                      }}
                    />
                    <Spacer size="xxs" />
                  </Col>
                  {RepeatOnOptions.map((day, index) => (
                    <Col xs={12} md={3} key={`repeat-on-${index}`}>
                      <Checkbox
                        key={`repeat-on-${index}`}
                        checked={values.RepeatOn.includes(day.value)}
                        name={`RepeatOn.${index}`}
                        label={intl.formatMessage({ id: day.label })}
                        value={`${day.value}`}
                        field={{
                          name: day.label,
                        }}
                        onChange={(e: any) => {
                          const { insert, remove } = arrayHelpers
                          if (e.target.checked) {
                            setCheckAll(values.RepeatOn.length + 1 === RepeatOnOptions.length)
                            insert(index, day.value)
                          } else {
                            const valueIndex = values.RepeatOn.indexOf(day.value)
                            setCheckAll(values.RepeatOn.length - 1 === RepeatOnOptions.length)
                            remove(valueIndex)
                          }
                        }}
                      />
                      <Spacer size="xxs" />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          />

          <Spacer size="xxxl" />

          <Footer>
            <Button type="button" onClick={onClose}>
              <FormattedMessage id="Cancel" />
            </Button>

            <Button
              type="button"
              buttonType="error"
              onClick={() => onDelete(values)}
              disabled={!isValid || !isEmpty(errors) || isInvalidDates()}
            >
              <FormattedMessage id="Delete" />
            </Button>
          </Footer>
        </form>
      )}
    />
  )
}

export default injectIntl(WithUserTimeHOC(AvailabilityBulkDeleteForm))
