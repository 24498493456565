import { IDatePicker } from '@components/Datepicker/interface'
import { isEqual } from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import IconWrapper from '../IconWrapper'
import { DatePickerWrapper, Field, FieldCalendarIcon, FieldTimeIcon } from './style'

const defaultProps: IDatePicker = {
  placeholderText: 'Select Date',
  openToDate: new Date(),
  useWeekdaysShort: true,
  dropdownMode: 'select',
  dateFormat: 'dd MMMM yyyy',
  isClearable: true,
}

// PTL-1268: update UI to show 12:00 AM as the last option in the picker
class CustomDateInput extends React.Component<{
  value?: string
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
}> {
  render() {
    const { value, ...others } = this.props
    return <input value={value === '23:59' ? '24:00' : value} {...others} />
  }
}

class Datepicker extends React.Component<IDatePicker, { selected: Date | null }> {
  static defaultProps: any

  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected,
    }
  }

  handleChange = (value, event) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, event)
    }
    this.setState({ selected: value })
  }

  handleSelect = (value, event) => {
    const { selected } = this.state
    if (typeof this.props.onSelect === 'function') {
      this.props.onSelect(value, event)
      const date = selected ? moment(selected) : moment()
      moment(value).isSame(date) && this.setState({ selected: null })
    }
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props
    if (!selected && prevProps.selected) {
      this.setState({ selected: null })
    }

    if (selected && !isEqual(selected, prevProps.selected)) {
      this.setState({ selected })
    }
  }

  render() {
    let icon = this.props.showTimeSelectOnly ? (
      <IconWrapper>
        <FieldTimeIcon />
      </IconWrapper>
    ) : (
      <IconWrapper filled>
        <FieldCalendarIcon />
      </IconWrapper>
    )

    if (this.props.inline) {
      icon = <></>
    }

    const ComponentBody = (
      <DatePickerWrapper
        borderless={this.props.borderless}
        monthsShown={this.props.monthsShown}
        hideNavigation={this.props.hideNavigation}
        dontBreak={this.props.dontBreak}
        className={this.props.className}
      >
        <DatePicker
          {...this.props}
          selected={this.state.selected}
          onSelect={this.handleSelect}
          onChange={this.handleChange}
          customInput={this.props.isEndDateFix ? <CustomDateInput /> : undefined}
          dayClassName={(date) =>
            `${moment(date).isBefore(moment(), 'day') ? 'previous-date' : ''} ${
              (this.props.dayClassName && this.props.dayClassName(date)) || ''
            }`
          }
        />
        {icon}
      </DatePickerWrapper>
    )

    if (!this.props.label) {
      return ComponentBody
    }
    return <Field label={this.props.label}>{ComponentBody}</Field>
  }
}

Datepicker.defaultProps = defaultProps

export default Datepicker
