import { SessionProps, StoreProps } from '@interfaces/StoreState'
import { getCurrentPermission } from '@utils/helpers/permissions'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useSelector } from 'react-redux'
import exclude from './exclude-config'
import Rules from '@utils/rbacs'
import cr8 from './features/cr8'

interface IRoleBased extends SessionProps {
  configId: string
  data?: any
}

interface IRoleBasedState {
  configId: string
  allowed: boolean
}

/**
 * Custom hook based on RoleBasedView component that handle permissions for portal features.
 *
 * How does it works?
 *
 *  - If feature flag is OFF and student has values in PortalFeaturesEnabled field then
 *    the values in PortalFeaturesEnabled would be used as permission.
 *
 *  - If feature flag is ON, then it will override the rule above
 *
 */
const usePortalFeatures = ({ configId, data }: IRoleBased) => {
  const features = { cr8 }
  const [state, setState] = React.useState<IRoleBasedState>({
    configId,
    allowed: false,
  })

  const { userName, roles, profile, userPermissions } = useSelector(
    ({ session, userProfile, permissions }: StoreProps) => ({
      roles: session?.cognitoGroups ?? [],
      userName: session?.user?.username,
      profile: userProfile.info,
      userPermissions: permissions.permissions,
    })
  )

  React.useEffect(() => {
    if (!roles.length) {
      return
    }

    let userPermission = userName && roles.length && !isEmpty(userPermissions) && userPermissions[configId]

    let dynamicFn = ''
    if (userPermission && userPermission?.dynamicFn) {
      dynamicFn = userPermission.dynamicFn

      // at this point, we don't need the dynamic function to be
      // executed in getCurrentPermission
      userPermission = {
        ...userPermission,
        dynamicFn: undefined,
      }
    }

    let accState = state
    if (
      !profile.FeatureType?.some((feature) => (exclude[feature] || []).indexOf(configId) >= 0) &&
      userPermission &&
      getCurrentPermission({
        roles,
        userPermission,
        userName,
        profile,
        data,
      })
    ) {
      accState = {
        configId,
        allowed: true,
      }
    } else {
      let dynamicOverride = false
      // @ts-ignore
      if (dynamicFn && typeof Rules[dynamicFn] === 'function') {
        // @ts-ignore
        dynamicOverride = Rules[dynamicFn]({ profile, data, roles })
      }

      accState = {
        configId,
        allowed: dynamicOverride,
      }
    }

    setState(accState)
  }, [configId])

  return { state, features }
}

export default usePortalFeatures
