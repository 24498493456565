import { IDropdownSelect } from '@interfaces/IDropdownSelect'
import { StudentLanguageProfileInfo } from '@interfaces/Student'
import languages from '@layouts/PortalTranslations/languages.json'
import countriesAndStates from '@utils/dataset/countries-states.json'

export interface ILanguage {
  id: string
  show: boolean
  language: string
  label?: string
}

export interface IStates {
  active: boolean
  integrationValue: string
  isoCode: string
  label: string
  standard: boolean
  visible: boolean
}

export interface ICountries extends IStates {
  orgDefault: boolean
  states?: IStates[]
}

export const languagesDropdown = () => {
  const select: IDropdownSelect[] = []
  languages.forEach(
    ({ show, id, language, label }: ILanguage) =>
      show &&
      select.push({
        id,
        value: id,
        label: label || language,
      })
  )

  return select.sort((a, b) => a.label.localeCompare(b.label))
}

export const studentLanguageProfileLanguagesDropdown = (studentLanguages: StudentLanguageProfileInfo[] = []) =>
  studentLanguages.map(({ RecordId, Language }) => ({
    id: RecordId,
    name: Language,
    label: Language,
    value: Language,
  }))

export const filterStudentLanguageProfile = (studentLanguages: StudentLanguageProfileInfo[] = []) =>
  studentLanguages.filter(({ Language }) => Language)

// return code back if language is not found
export const getLanguageName = (code = 'en') => languages.find(lang => lang.id === code)?.label ?? code

export const countriesDrodown = () => {
  const select: IDropdownSelect[] = []
  countriesAndStates.forEach(
    ({ visible, isoCode, integrationValue, label }: ICountries) =>
      visible &&
      select.push({
        id: isoCode,
        value: integrationValue,
        label: label || integrationValue,
      })
  )

  return select.sort((a, b) => a.label.localeCompare(b.label))
}

export const statesDropdown = country => {
  const select: IDropdownSelect[] = []
  const currentCountry = countriesAndStates.find(({ integrationValue }) => integrationValue === country)

  currentCountry?.visible &&
    currentCountry?.states?.forEach(
      ({ visible, isoCode, integrationValue, label }: IStates) =>
        visible &&
        select.push({
          id: isoCode,
          value: integrationValue,
          label: label || integrationValue,
        })
    )
  return select
}
