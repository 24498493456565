import * as React from 'react'
import Modal from '@berlitz/modal'

import { Provider } from './ModalContext'
import { TSize } from './interface'

interface ISModalProvider {
  open: boolean
  content?: React.ReactNode
  size?: TSize
}

export default class ModalProvider extends React.Component {
  state: ISModalProvider = {
    open: false,
    content: null,
    size: 'md',
  }

  toggleModal = (open: boolean, content?: React.ReactNode, size: TSize = 'sm') => {
    let state: ISModalProvider = {
      open,
      size,
    }

    if (content) {
      state = { ...state, content }
    }
    this.setState(state)
  }

  changeContent = (content: React.ReactNode, size: TSize = 'sm') =>
    this.setState({
      content,
      size,
    })

  render() {
    const { open, content, size } = this.state
    const { toggleModal, changeContent } = this
    const context = { toggleModal, changeContent }

    return (
      <Provider value={context}>
        {this.props.children}
        <Modal open={open} onClose={() => this.toggleModal(false, null)} size={size}>
          <div>{content}</div>
        </Modal>
      </Provider>
    )
  }
}
