import { H2 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useMutation } from '@apollo/react-hooks'
import { Tick } from '@berlitz/icons'
import PortalInstructor from '@classes/Instructor'
import PortalInstructorAvailabilities from '@classes/InstructorAvailabilities'
import Alert from '@components/Alert'
import { IAvailabilityResponse } from '@components/InstructorScheduler/interface'
import { displayDeleteBtn } from '@components/InstructorScheduler/util'
import ProfileClock from '@components/ProfileClock'
import { AvailabilityAPIResponse, InstructorAvailability } from '@interfaces/InstructorAvailability'
import { UserProfileProps } from '@interfaces/UserProfile'
import { PUT_INSTRUCTOR_AVAILABILITY } from '@mutations/instructorAvailability'
import { BULK_DELETE_UNAVAILABILITIES } from '@mutations/instructorUnAvailability'
import { GET_INSTRUCTOR_AVAILABILITY } from '@queries/instructorAvailability'
import { LESSON_TYPE } from '@utils/constants/availability'
import { isArray, isObject } from 'lodash'
import Router from 'next/router'
import { withApollo, WithApolloClient } from 'react-apollo'
import Empty from './Empty'
import Form from './Form'
import { AvailabilityBulkDeleteProp, FormValueProps } from './interface'
import { ModalWrapper, StyledButton, StyledTabs, StyledText, Wrapper } from './style'
import TimeOffForm from './TimeOffForm'
import Warning from './Warning'

moment.locale('en', {
  week: {
    dow: 1,
    doy: 1,
  },
})

type Props = WithApolloClient<AvailabilityBulkDeleteProp>

const AvailabilityBulkDeleteModal: React.FC<Props> = ({
  client,
  open,
  onClose = () => {},
  getPayload = () => [],
  onDeleting = () => {},
  onDeleted = () => {},
  currentDate = moment(),
  date = moment(),
  isAdmin,
  instructorInfo,
}) => {
  const profile = { InstructorProfile: { instructorInfo } } as UserProfileProps
  const initialValues = {
    AvailabilityType: LESSON_TYPE.FLEX,
    StartDate: date.clone(),
    EndDate: date.clone().add(1, 'month'),
    RepeatOn: date.isValid() ? [parseInt(date.format('e'), 10)] : [],
    KeepLessons: true,
    Timezone: instructorInfo?.PreferredTimezone || moment.tz.guess(),
  }

  const timeOffInitialValues = {
    StartDate: date.clone(),
    RepeatOn: [],
    Timezone: instructorInfo?.PreferredTimezone || moment.tz.guess(),
  }

  const [formValues, setFormValues] = useState<FormValueProps>(initialValues)
  const [showWarning, setShowWarning] = useState(false)
  const [isWarning, setIsWarning] = useState(showWarning)
  const [isTimeOff, setIsTimeOff] = useState(false)
  const [showBulkDeleteAvailability] = useState(displayDeleteBtn(profile, isAdmin))
  const [deleteAvailability] = useMutation<IAvailabilityResponse>(PUT_INSTRUCTOR_AVAILABILITY, {
    onCompleted: (data: IAvailabilityResponse) =>
      onDeleted(data?.putInstructorAvailability?.ServiceStatus?.Success || false),
    onError: () => onDeleted(false),
  })
  const [bulkDeleteUnavailability] = useMutation<IAvailabilityResponse>(BULK_DELETE_UNAVAILABILITIES, {
    onCompleted: (data) => onDeleted(data?.bulkDeleteUnAvailability?.ServiceStatus?.Success || false),
    onError: () => onDeleted(false),
  })
  const Instructor = new PortalInstructor(instructorInfo)
  const intl = useIntl()

  useEffect(() => {
    setFormValues(initialValues)
  }, [])

  const onDeleteEvent = (Availabilities: InstructorAvailability[]) => {
    try {
      deleteAvailability({
        variables: {
          Availabilities,
        },
      })
      onDeleting()
    } catch (error) {
      onDeleted(false)
    }
  }

  const onDeleteUnavailabilities = (payload) => {
    try {
      bulkDeleteUnavailability({
        variables: {
          payload,
        },
      })
      onDeleting()
    } catch (error) {
      onDeleted(false)
    }
  }

  const handleClose = () => {
    onClose()
    setShowWarning(false)
    setIsWarning(false)
  }

  const onTabsChange = (value: boolean) => {
    setIsTimeOff(value)
  }

  return (
    <ModalWrapper>
      <Modal open={open} onClose={handleClose} size={showWarning ? 'sm' : 'md'}>
        <>
          {isWarning ? (
            <Warning
              title={
                isTimeOff
                  ? intl.formatMessage({ id: 'Delete time off', defaultMessage: 'Delete time off' })
                  : intl.formatMessage({ id: 'Delete availability', defaultMessage: 'Delete availability' })
              }
              onClose={handleClose}
              onProceed={async () => {
                const payload = getPayload(formValues, isTimeOff)
                onDeleting()
                setShowWarning(false)
                setIsWarning(false)

                try {
                  if (isArray(payload)) {
                    const availRes = await client.query<AvailabilityAPIResponse>({
                      query: GET_INSTRUCTOR_AVAILABILITY,
                      variables: {
                        InstructorId: Instructor.Id,
                        StartDate: formValues.StartDate.clone().subtract(1, 'day').format('YYYY-MM-DD'),
                        EndDate: formValues.EndDate.clone().add(1, 'day').format('YYYY-MM-DD'),
                      },
                      fetchPolicy: 'no-cache',
                    })

                    const Availabilities = new PortalInstructorAvailabilities(
                      availRes.data?.getInstructorAvailability?.ServiceResult,
                      Instructor
                    )
                    onDeleteEvent(Availabilities.getBulkDeleteEvents(formValues))
                  } else if (isObject(payload) && isTimeOff) {
                    onDeleteUnavailabilities(payload)
                  } else {
                    onDeleted(true)
                  }
                } catch (err) {
                  onDeleted(true)
                }
              }}
            />
          ) : (
            <Wrapper>
              <div className="nav-tabs bulk-delete">
                <div>
                  <H2 disableMargin>
                    <FormattedMessage id="Bulk delete" />
                  </H2>
                </div>

                <ProfileClock
                  hideChangeLink={isAdmin}
                  onChangeClick={() => {
                    onClose()
                    Router.push('/instructors/settings/account')
                  }}
                />
              </div>

              <Spacer size="md" />

              <Alert alertType="info" dismissable={false}>
                <FormattedMessage id="Please note this will delete all recurring availability within the dates/days selected." />
              </Alert>

              <Spacer size="xxxs" />

              <StyledTabs>
                <StyledButton onClick={() => onTabsChange(false)} selected={!isTimeOff}>
                  <StyledText selected={!isTimeOff}>
                    {!isTimeOff && <Tick size="xs" />}
                    <FormattedMessage id="Availability" />
                  </StyledText>
                </StyledButton>
                <StyledButton onClick={() => onTabsChange(true)} selected={isTimeOff}>
                  <StyledText selected={isTimeOff}>
                    {isTimeOff && <Tick size="xs" />}
                    <FormattedMessage id="Time off" />
                  </StyledText>
                </StyledButton>
              </StyledTabs>

              <Spacer size="lg" />

              {!isTimeOff ? (
                showBulkDeleteAvailability ? (
                  <Form
                    onClose={onClose}
                    onDelete={(values: FormValueProps) => {
                      setFormValues(values)
                      setShowWarning(true)
                      setIsWarning(true)
                    }}
                    currentDate={currentDate}
                    initialValues={initialValues}
                    isAdmin={isAdmin}
                    instructorInfo={instructorInfo}
                  />
                ) : (
                  <Empty onClose={onClose} />
                )
              ) : (
                <TimeOffForm
                  onClose={onClose}
                  onDelete={(values: FormValueProps) => {
                    setFormValues(values)
                    setShowWarning(true)
                    setIsWarning(true)
                  }}
                  currentDate={currentDate}
                  initialValues={timeOffInitialValues}
                  isAdmin={isAdmin}
                  instructorInfo={instructorInfo}
                />
              )}
            </Wrapper>
          )}
        </>
      </Modal>
    </ModalWrapper>
  )
}

export default withApollo(AvailabilityBulkDeleteModal)
