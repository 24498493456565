export const UPDATE_PERMISSIONS = 'permissions/UPDATE_PERMISSIONS'
export const FETCH_PERMISSIONS = 'permissions/FETCH_PERMISSIONS'

export const updatePermissions = permissions => ({
  type: UPDATE_PERMISSIONS,
  permissions,
})

export const fetchPermissons = () => ({
  type: FETCH_PERMISSIONS,
})
