import styled, { css } from 'styled-components'
import { Props } from './HR'

export const StyledHR = styled.hr<Props>`
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.palette.blue20};
  margin: ${({ theme }) => `${theme.space.md} 0`};
  margin-top: ${({ theme }) => theme.space.xs};

  ${({ disableMargin }) =>
    disableMargin &&
    css`
      margin-bottom: 0;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0;
  }
`
