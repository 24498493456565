export const PUBLIC_PAGES = []
export const NON_DASHBOARD_ROUTES = ['/_error']

export const isPagePublic = currentRoute => {
  if (currentRoute.endsWith('/')) {
    currentRoute = currentRoute.slice(0, -1)
  }
  // @ts-ignore
  return PUBLIC_PAGES.includes(currentRoute)
}

export default {
  PUBLIC_PAGES,
  NON_DASHBOARD_ROUTES,
}
