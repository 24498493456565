import gql from 'graphql-tag'

export const PUT_INSTRUCTOR_AVAILABILITY = gql`
  mutation PutInstructorAvailability($Availabilities: [PutInstructorAvailabilityInput!]!) {
    putInstructorAvailability(Availabilities: $Availabilities) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
      ServiceResult {
        InstructorAvailabilityInfo {
          Type
          Action
          TimeZone
          StartTime
          StartDate
          LearningCenterName
          LearningCenterId
          InstructorProfileId
          InstructorName
          Id
          EndTime
          AvailabilityType
          AllDay
        }
      }
    }
  }
`

export const PUT_AVAILABILITIES = gql`
  mutation Availabilities(
    $FormValues: PutAvailabilityFormModelInput!
    $Instructor: PutAvailabilityInstructorModelInput!
  ) {
    putAvailabilities(FormValues: $FormValues, Instructor: $Instructor) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
    }
  }
`

export const MERGE_AVAILABILITIES = gql`
  mutation Availabilities(
    $FormValues: PutAvailabilityFormModelInput!
    $Instructor: PutAvailabilityInstructorModelInput!
    $Availabilities: [PutInstructorAvailabilityInput!]!
  ) {
    mergeAvailabilities(FormValues: $FormValues, Instructor: $Instructor, Availabilities: $Availabilities) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
        __typename
      }
      __typename
    }
  }
`

export const REPLACE_AVAILABILITIES = gql`
  mutation Availabilities(
    $FormValues: PutAvailabilityFormModelInput!
    $Instructor: PutAvailabilityInstructorModelInput!
    $Availabilities: [PutInstructorAvailabilityInput!]!
  ) {
    replaceAvailabilities(FormValues: $FormValues, Instructor: $Instructor, Availabilities: $Availabilities) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
        __typename
      }
      __typename
    }
  }
`
