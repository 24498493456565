import { Warning } from '@berlitz/icons'
import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { EmptyPreferred } from './style'

const EmptyPlaceholder: React.FC<{ msg: string } & WrappedComponentProps> = ({ msg = 'No items to display' }) => (
  <EmptyPreferred>
    <Warning size="16px" />
    <FormattedMessage id={msg} />
  </EmptyPreferred>
)

export default injectIntl(EmptyPlaceholder)
