import styled from 'styled-components'

export const EmptyPreferred = styled.p`
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.xxl}`};
  background-color: ${({ theme }) => theme.colors.ui06};
  line-height: 1.38;
  color: ${({ theme }) => theme.palette.blue80};
  position: relative;
  svg {
    position: absolute;
    left: ${({ theme }) => theme.space.md};
    top: ${({ theme }) => theme.space.md};
  }
`
