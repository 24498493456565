import { FieldCalendarIcon, DatePickerWrapper } from '@components/Datepicker/style'
import { Col } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space.xxs};
  display: flex;
  justify-content: flex-end;
`

export const SpinnerBox = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space.xs};
  margin-right: ${({ theme }) => theme.space.xs};
  justify-content: flex-end;
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .tip-alert {
    width: 335px;
    margin-right: ${({ theme }) => theme.space.sm};
  }

  button {
    width: 170px;
    height: 71px;
    border-radius: 3px;
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &[disabled] {
      color: ${({ theme }) => theme.palette.gray100};
      background-color: ${({ theme }) => theme.colors.ui03};
    }
  }
`

export const CalendarButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxxs};

  svg {
    margin-right: ${({ theme }) => theme.space.xs};
  }
`

export const Legends = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SchedBox = styled.div`
  margin: ${({ theme }) => theme.space.lg} 0px;
  margin-bottom: ${({ theme }) => theme.space.xxxl};

  ${FieldCalendarIcon} {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }

  ${DatePickerWrapper} {
    min-width: initial;
    width: calc(75% + 20px);
  }

  .react-datepicker-popper {
    z-index: 2;
  }
`

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.space.md};
`

export const Inline = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid;

  .spinner {
    margin-right: ${({ theme }) => theme.space.sm};
  }
`

export const DatePickerCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none !important;
  }
`
