import FormField from '@berlitz/form-field'
import { spaceXxss } from '@utils/constants'
import { Select } from 'antd'
import styled, { createGlobalStyle, css } from 'styled-components'

import theme from '@berlitz/max-theme-portals'

type BerlitzTheme = typeof theme

export const SelectOverrides = createGlobalStyle<{ theme: BerlitzTheme }>`
  .ant-select-dropdown {
    border: solid 1px ${theme.palette.gray100};
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item {
    color: ${theme.colors.brandSecondaryContrast};
  }

  .ant-select-dropdown-menu-item-selected {
    color: ${theme.colors.brandPrimary};
    font-weight: normal;
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
    background-color: ${theme.colors.brandPrimary};
    color: white;
    font-weight: bold;
  }

  .ant-select-dropdown-menu {
    margin-top: 0;
  }

  .ant-select-dropdown-menu-item-disabled:hover,
  .ant-select-dropdown-menu-item-disabled {
    background-color: ${theme.colors.brandPrimaryContrast};
    color: rgba(0, 0, 0, 0.25);
    font-weight: normal;
  }

  .ant-select-dropdown-menu-item-partial-disabled:hover,
  .ant-select-dropdown-menu-item-partial-disabled {
    background-color: ${theme.colors.brandPrimaryContrast};
    color: rgba(0, 0, 0, 0.25);
    font-weight: normal;
  }


  .ant-select-dropdown-menu-item.highlighted-option {
    color: ${theme.colors.brandPrimary};
  }

  .ant-select-dropdown-menu-item.highlighted-option:hover,
  .ant-select-dropdown-menu-item-active.highlighted-option {
    background-color: ${theme.colors.brandPrimary};
    color: ${theme.colors.brandPrimaryContrast};
    font-weight: bold;
  }

  .ant-empty {
    text-align: center;
  }

  .ant-empty .ant-empty-image img {
    margin: 0 auto;
  }

  .multi-option-selector {
    .ant-select-dropdown-menu-item-group-title {
      display: none;
    }

    .ant-select-dropdown-menu-item-group-list {
      border-top: 1px dashed;
      border-bottom: 1px dashed;
      li:not(:first-child):before {
        content: '- '
      }
    }
  }
`

interface FieldProps {
  disableMargin?: boolean
}

export const Field = styled(FormField)<FieldProps>`
  & > label {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.xxs};
    margin-bottom: ${theme.space.xs};
  }
  & > div {
    z-index: auto;
  }

  ${({ disableMargin }) =>
    disableMargin &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;
    `}
`

interface StyledSelectProps {
  warning: boolean
  error: boolean
  disableMargin: boolean
  responsive: boolean
  fullWidth: boolean
  getPopupContainer: Function
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  ${({ responsive }) =>
    responsive &&
    css`
      overflow: hidden;
      flex-grow: 1;
      max-width: 100%;
    `}

  .ant-select-selection {
    color: ${theme.colors.brandSecondaryContrast};
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.regular};
    padding: ${`${theme.space.xs} ${theme.space.lg} ${theme.space.xs} ${spaceXxss}`};
    border: solid 1px ${theme.palette.gray100};
    border-radius: ${theme.radius.sm};
    height: 36px;

    ${({ warning }) =>
      warning &&
      css`
        border: solid 1px ${theme.palette.warning100};
      `}

    ${({ error }) =>
      error &&
      css`
        border: solid 1px ${theme.palette.danger100};
      `}
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    margin-top: 0;
    top: auto;
    color: inherit;
  }
  .ant-select-selection__rendered {
    margin: 0;
    line-height: 1.2;
  }
  .ant-select-arrow {
    color: ${theme.colors.brandSecondaryContrast};
  }

  ${({ disableMargin }) =>
    disableMargin &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export const SubLabel = styled.div`
  font-size: ${theme.fontSize.xxxs};
  font-weight: ${theme.fontWeight.regular};
  margin: 5px 0;
  line-height: 1.33;
`
