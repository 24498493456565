// TODO: move to max
export const avatarXs = '40px'
export const avatarSm = '56px'
export const avatarMd = '102px'
export const elevationXl = '0 0 9px 0 rgba(0, 0, 0, 0.14)'
export const layoutXxl = '200px'
export const radiusXxxl = '20px'
export const spaceXxss = '6px'
export const iconSm = '16px'
export const charLimit = 3200
export const textInputLimit = 300
export const aboutMeCharLimit = 255

export const getTZLocalstorageKey = (id) => `_tz-mismatch[${id}]`
export const LCRedoLocalstorageKey = (id) => `_lcr[${id}]`
export const InstructorLPLocalstorageKey = (id) => `_ilp[${id}]`
export const getKeepLessonsStorageKey = (id) => `_keep-lessons[${id}]`
export const AllLevelsCompletedLocalStorageKey = (LPId, SFId) =>
  `temp_${FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED}-${LPId}-${SFId}`
export const AllLevelsCompletedModalLocalStorageKey = (LPId, SFId) =>
  `modal-closed_${FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED}-${LPId}-${SFId}`
export const getTempHoldSeshIdsLocalStorageKey = (id) => `_temp-hold-sesh-ids[${id}]`

export const DEFAULT_LANGUAGE = 'en'

export const PORTALS_VAR = {
  LOCAL_SFID: '_sfid',
  COGNITO_IDENTIFIER: 'CognitoIdentityServiceProvider',
  REFRESH_TOKEN: 'refreshToken',
}

export const DATE_FORMAT = {
  DEFAULT: 'Do MMMM YYYY',
  SIMPLE: 'Do MMM YYYY',
  DD_MMMM_YYYY: 'DD MMMM YYYY',
  D_MMM_YYYY: 'D MMM YYYY',
  TZ: 'YYYY-MM-DDTHH:mm:ssZ',
  DT: 'YYYY-MM-DD HH:mm:ss',
  BASIC: 'YYYY-MM-DD',
  BASIC_TIME: 'HH:mm:ss',
  DHM: 'YYYY-MM-DD HH:mm',
  HM: 'HH:mm',
  MY: 'MMMM YYYY',
  COMPLETE: 'Do MMMM YYYY, hh:mm A',
  COMPLETE_24: 'Do MMMM YYYY, HH:mm',
  FULL_24: 'Do MMM YYYY, HH:mm',
  HMA: 'hh:mm A',
  hmmA: 'h:mm A',
  HMa: 'hh:mm a',
  hM: 'hh:mm',
  hmm: 'h:mm',
  NORMAL: 'MMMM DD, YYYY',
  DAY_MONTH: 'DD MMMM',
  DATEPICKER: 'DD/MM/YYYY',
  HHmm: 'HHmm',
  MMMM: 'MMMM',
  ISO8601FormatWithUTC: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
}

// Added pending as CAN_JOIN based on the
// bug ticket that was raised
// https://berlitz.atlassian.net/browse/PTL-1318
export const LESSON_STATUS = {
  CAN_JOIN: ['confirmed', 'pending'],
  CAN_NOTIFY: ['confirmed'],
  CAN_CANCEL: ['confirmed', 'pending'],
  CAN_RESCHEDULE: ['confirmed', 'pending', 'cancelled'],
}

export const PROFILE_TYPE = {
  STUDENT: 'Student',
  INSTRUCTOR: 'Instructor',
}

export const PAGE = {
  TYPES: {
    SITE: 'site',
    PAGE: 'page',
    COMPONENT: 'component',
  },
  ACTIONS: {
    MAINTENANCE: 'maintenance',
    FORBIDDEN: 'forbidden',
    COMINGSOON: 'comingSoon',
    REDIRECTION: 'redirection',
    SITEMAINTENANCE: 'siteMaintenance',
  },
  ROLE_HIERARCHY: [
    __USER_GROUP_SUPERADMIN__,
    __USER_GROUP_ADMIN__,
    __USER_GROUP_AGENT__,
    __USER_GROUP_SFSTANDARD__,
    __USER_GROUP_SALESFORCE__,
    __USER_GROUP_MI__,
    __USER_GROUP_LCSTAFF__,
    __USER_GROUP_INSTRUCTORS__,
    __USER_GROUP_STUDENTS__,
  ],
  ROLE_ADMINS: [
    __USER_GROUP_SUPERADMIN__,
    __USER_GROUP_ADMIN__,
    __USER_GROUP_AGENT__,
    __USER_GROUP_MI__,
    __USER_GROUP_LCSTAFF__,
    __USER_GROUP_SFSTANDARD__,
    __USER_GROUP_SALESFORCE__,
  ],
  ROLE_INSTRUCTOR: [__USER_GROUP_INSTRUCTORS__],
}
export const NON_STUDENT_GROUP = [...PAGE.ROLE_ADMINS, ...PAGE.ROLE_INSTRUCTOR]

export const PERMISSION_ENVIRONMENTS = [
  {
    label: 'dev',
    value: 'dev',
  },
  {
    label: 'staging',
    value: 'staging',
  },
  {
    label: 'uat',
    value: 'uat',
  },
  {
    label: 'production',
    value: 'prod',
  },
]

export const FLEX_HERO_CONTENT_ID = 'bltc36fb23a812cb461'
export const ON_DEMAND_HERO_CONTENT_ID = 'bltddd3d2fe2c6e1995'
export const CERTIFICATE_ACHIEVEMENT_ID = 'blta4d541910bc6c781'

export const CURRENT_PERM_ENV = (
  PERMISSION_ENVIRONMENTS.find((env) => process.env.ENV_NAME === env.label) || PERMISSION_ENVIRONMENTS[0]
).value

export default {
  avatarXs,
  avatarSm,
  avatarMd,
  elevationXl,
  layoutXxl,
  radiusXxxl,
  spaceXxss,
  iconSm,
  charLimit,
}

const LIVE_ONLINE = [
  'Private Online Level Based',
  'Private Online Skill Based',
  'Group(3) Online Level Based',
  'Group(3) Online Skill Based',
  'Group Online Level Based',
  'Group(6) Online Level Based',
  'Group(6) Online Skill Based',
  'Group Online Skill Based',
  'Standalone Online',
  'Charter Online',
]
const F2F = [
  'Private Total Immersion Level Based',
  'Private Face to Face Level Based',
  'Private Face to Face Skill Based',
  'Group(3) Face to Face Level Based',
  'Group Face to Face Level Based',
  'Group(3) Face to Face Skill Based',
  'Group(6) Face to Face Level Based',
  'Group Face to Face Skill Based',
  'Group(6) Face to Face Skill Based',
  'Group Large Face to Face Level Based',
  'Group Large Face to Face Skill Based',
  'Charter Face to Face ',
]

const HYBRID = [
  'Private Hybrid Level Based',
  'Private Hybrid Skill Based',
  'Group(3) Hybrid Level Based',
  'Group(3) Hybrid Skill Based',
  'Group Hybrid Level Based',
  'Group(6) Hybrid Level Based',
  'Group(6) Hybrid Skill Based',
  'Group Hybrid Skill Based',
]

export const FLEX_PRIVATE_ONLINE_ACTIVATION = 'Berlitz Flex - Private Online Activation'
export const FLEX = [FLEX_PRIVATE_ONLINE_ACTIVATION]
export const JPN_FLEX = ['Berlitz Flex - Private Face to Face Activation']
export const ON_DEMAND = ['Berlitz On Demand']
export const ON_DEMAND_1 = ['Berlitz On Demand + 1:1 Classes']

export const PROGRAM_TYPES = {
  CT: [...LIVE_ONLINE, ...F2F, ...HYBRID, ...FLEX, ...ON_DEMAND],
  LIVE_ONLINE,
  F2F,
  HYBRID,
  FLEX,
  JPN_FLEX,

  ONLINE: [...LIVE_ONLINE, ...FLEX],
  ON_DEMAND: [...ON_DEMAND],
  FLEX_BOD: [...FLEX, ...ON_DEMAND],
  ON_DEMAND_1,
}

export const VALID_PROGRAM_TYPES = ['Active', 'Pending Start', 'Scheduling']
export const INVALID_SCHEDULING_PROGRAM_TYPES = new Set(['Draft', 'Cancelled', 'Completed', 'Expired'])
export const FINISHED_PROGRAM_TYPES = ['Cancelled', 'Completed']
export const CANCELLED_LESSON_STATUS = ['rejected', 'cancelled']

export const LIVE_ONLINE_VIRTUAL_CENTER = 'Live Online Virtual Center'

export const MI_COGNITO_GROUP = 'MI'
export const FEATURE_TYPE = {
  JAPAN_FLEX: 'JAPAN_FLEX',
  DEFAULT: 'DEFAULT',
  NO_LP: 'NO_LP',
  ON_DEMAND: 'ON_DEMAND',
  DISABLED_FOLLOW_INSTRUCTOR: 'DISABLED_FOLLOW_INSTRUCTOR',
  NO_MEDIA: 'NO_MEDIA',
  ON_DEMAND_NO_WIDGETS: 'ON_DEMAND_NO_WIDGETS',
  ON_DEMAND_GROUP_CONVO: 'ON_DEMAND_GROUP_CONVO',
}

export const CANCELLATION_REASONS = {
  STUDENT_CANCELLATION: 'Student Cancellation',
  STUDENT_COMPLAINT_INSTRUCTOR: 'Student Complaint - Instructor',
  STUDENT_COMPLAINT_MATERIAL: 'Student Complaint - Material',
  STUDENT_COMPLAINT_FACILITIES: 'Student Complaint - Facilities',
  STUDENT_COMPLAINT_SCHEDULE: 'Student Complaint - Schedule',
  STUDENT_COMPLAINT_OTHER: 'Student Complaint - Other',
  STUDENT_LATE_CANCELLATION: 'Student - Late Cancellation',
}

export const LESSON_REG_STATUS = {
  COMPLETE: 'Complete',
  NOT_STARTED: 'Not started',
  NEW: 'New',
}

export const REG_STATUS = {
  ACTIVE: 'Active',
  PENDING_START: 'Pending Start',
  CANCELLED: 'Cancelled',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  PAUSED: 'Paused',
  EXPIRED: 'Expired',
}

export const LESSON_ATTENDANCE_STATUS = {
  PRESENT: 'Present',
  LATE: 'Late',
  ABSENT: 'Absent',
}

export const LESSON_ATTENDANCE_TYPE = {
  ALL: 'All classes',
  THIS_CLASS: 'This class',
}

export const SCHEDULE_STATUS = {
  CANCELLED: 'Cancelled',
  RESCHEDULED: 'Rescheduled',
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
}

export const FLEX_VERSION = {
  SINGLE: 'Single Level',
  TWO: 'Two Level',
  MULTI: 'Multi Level',
}

export const FLEX_LEVEL_STATUS = {
  LEVEL_STARTED: 'Level Started',
  UNLIMITED_LCS: 'Unlimited Coaching Sessions',
  FOR_NEXT_LEVEL: 'Ready for Next Level',
  ENROLMENT_INPROGRESS: 'Re-Enrolment in Progress',
  ENROLMENT_COMPLETED: 'Re-Enrolment Completed',
  ALL_LEVELS_COMPLETED: 'All Levels Completed',
}

export const YES_NO = {
  YES: 'Yes',
  NO: 'No',
}

export const MINS_PER_UNIT = 15

export const VIRTUAL_CLASSROOM_PROVIDER = {
  NEWROW: 'newrow',
  KALTURA: 'kaltura',
  ZOOM: 'zoom',
}

export const VIRTUAL_CLASSROOM_PROVIDERS = [
  VIRTUAL_CLASSROOM_PROVIDER.NEWROW,
  VIRTUAL_CLASSROOM_PROVIDER.KALTURA,
  VIRTUAL_CLASSROOM_PROVIDER.ZOOM,
]

export const ELECTIVE = {
  REQUIRED: 'Required',
  ASSIGNED: 'Assigned',
  NOT_REQUIRED: 'Not required',
}

export const CURRICULUM_TEST_STATUS = {
  UNLOCKED: 'Test Unlocked',
  LOCKED: 'Test Locked',
  COMPLETED: 'Test Completed',
  EXPIRED: 'Test Expired',
}

export const PORTAL_ORIGIN = {
  FMI: 'Portal - FMI',
  FMI_BULK: 'Portal - FMI Bulk',
  FMI_RESCHEDULE: 'Portal - FMI Reschedule',
  RESCHEDULE: 'Portal - Reschedule',
  SINGLE: 'Portal - Single',
  BULK: 'Portal - Bulk',
}

export const MY_BERLITZ_ENV = {
  CHINA: 'China',
  DEFAULT: 'Frankfurt',
}

export const SF_STANDARD_ROLE = 'SFStandard'
export const SF_STANDARD_ROLE_LABEL = 'SF Standard'
export const SALESFORCE_ROLE = __USER_GROUP_SALESFORCE__
export const SALESFORCE_ROLE_LABEL = 'Salesforce'
export const REGIONAL_USER_ROLES = [SF_STANDARD_ROLE, SALESFORCE_ROLE]
export const NOLPID = 'NoLPID'
export const OPTLPID = 'OPTLPID'
export const STATUS_TEXT_MAPPING = {
  LS: {
    // LESSON STATUS
    notyetstarted: 'not started',
    inprogress: 'in-progress',
    complete: 'completed',
  },
  US: {
    // UNIT STATUS
    notyetstarted: 'Not started',
    inprogress: 'In-progress',
    complete: 'Completed',
  },
}

export const CertificateTypes = {
  ACHIEVEMENT: 'Certificate of Achievement',
  PARTICIPATION: 'Certificate of Participation',
}

export const SCHEDULING_LEVEL = {
  PROGRAM_LEVEL: 'Program Level',
  PROFILE_LEVEL: 'Profile Level',
}

export const SCHEDULING_LEVEL_LABEL = {
  PROGRAM_LEVEL: 'This course',
  PROFILE_LEVEL: 'This course and all future courses',
}

export const LANGUAGE_LEVEL = {
  NOT_SET: 'Not set',
}

export const PLACEMENT_TEST = {
  REQUIRED: 'Required',
}
