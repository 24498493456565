import { createSlice } from '@reduxjs/toolkit'
import * as learningPath from './learningPathSelector'
import * as userProfile from './userProfileSelector'
import * as registration from './registrationSelector'

export const initialState = {
  learningPath: null,
  userProfile: null,
  registration: null,
}

const activeStateSlice = createSlice({
  name: 'activeState',
  initialState,
  reducers: {
    activeLearningPathUpdated(state, action) {
      state.learningPath = action.payload
    },

    activeUserUpdated(state, action) {
      state.userProfile = action.payload
    },

    activeRegistrationUpdated(state, action) {
      state.registration = action.payload
    },
  },
})

export const { activeLearningPathUpdated, activeUserUpdated, activeRegistrationUpdated } = activeStateSlice.actions

export default activeStateSlice.reducer

export const learningPathSelector = learningPath
export const userProfileSelector = userProfile
export const registrationSelector = registration
