import Button from '@berlitz/button'
import { H1, H4 } from '@berlitz/globals'
import { WarningTriangle } from '@berlitz/icons'
import Modal from '@berlitz/modal'
import * as React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { ActionButtons, CenterText, IconCircle } from './style'

interface WarningContentProps {
  onClose: Function
  onCancel?: Function
  onProceed?: Function
  title?: string
  subtitle?: React.ReactNode
  description?: React.ReactNode
  proceedLabel?: string
  cancelLabel?: string
  noButtons?: boolean
  anApiError?: boolean
  customButtons?: React.ReactNode
  cancelBtnType?: 'info' | 'warning' | 'error' | 'success' | 'light' | 'default' | undefined
  proceedBtnType?: 'info' | 'warning' | 'error' | 'success' | 'light' | 'default' | undefined
  cancelOutline?: boolean
}

interface WarningModalProps extends WarningContentProps {
  open: boolean
}

export const Content: React.FC<WarningContentProps & InjectedIntlProps> = ({
  intl,
  onClose,
  onCancel,
  onProceed,
  title,
  subtitle,
  description,
  proceedLabel = '',
  cancelLabel = '',
  noButtons,
  anApiError,
  customButtons,
  cancelBtnType,
  cancelOutline,
  proceedBtnType = 'default',
}) => (
  <>
    <IconCircle color="error">
      <WarningTriangle size="lg" />
    </IconCircle>
    <CenterText>
      <H1 disableMargin>
        {anApiError ? intl.formatMessage({ id: 'An unknown error has occurred. Please try again later.' }) : title}
      </H1>
      <H4>{!anApiError && subtitle}</H4>
      <H4>{!anApiError && description}</H4>

      {!noButtons && (
        <ActionButtons>
          {customButtons ? (
            customButtons
          ) : (
            <>
              <Button onClick={onCancel ? onCancel : onClose} outline={cancelOutline} buttonType={cancelBtnType}>
                {cancelLabel ? cancelLabel : <FormattedMessage id="Cancel" />}
              </Button>
              {proceedLabel ? (
                <Button buttonType={proceedBtnType} onClick={onProceed}>
                  {proceedLabel}
                </Button>
              ) : null}
            </>
          )}
        </ActionButtons>
      )}
    </CenterText>
  </>
)

export const WarningModalContent = injectIntl(Content)

class WarningModal extends React.Component<WarningModalProps & InjectedIntlProps> {
  static Content = WarningModalContent

  render() {
    const { open, onClose } = this.props
    return (
      <Modal open={open} onClose={onClose}>
        <WarningModalContent {...this.props} />
      </Modal>
    )
  }
}

export default injectIntl(WarningModal)
