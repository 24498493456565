import styled from 'styled-components'

export const HR = styled.hr`
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.palette.gray40};
  margin: 0;
  margin-top: 8px;
`

export const StyledA = styled.a`
  ${({ theme }) => `
    color: ${theme.colors.textMuted} !important;
    font-size: ${theme.fontSize.xxs};
    cursor: pointer;
    text-decoration: none;
  `}
`

export const LinkWrapper = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
`

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 128px);

  @media (max-width: 767px) {
    width: calc(100% - 32px);
  }
`

export const FooterPadding = styled.div`
  height: 50px;
`
