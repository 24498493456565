import { H1, H2, P } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import get from 'lodash/get'
import * as React from 'react'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { InstructorInfo } from '../../../interfaces/Instructor'
import { GET_INSTRUCTOR_DATA } from '../../../queries'
import { avatarMd, avatarSm } from '@utils/constants'

import Avatar from '../../Avatar'
import HR from '../../HR'
import { RectLoader } from '../../Loader'
import { AboutLoader, ApiErrorMessage } from '../Common'
import { Header } from './style'

interface InstructorModalProps {
  open: boolean
  onClose: Function
  InstructorId: string
}

const InstructorModal: React.FC<InstructorModalProps> = ({ open, onClose, InstructorId }) => (
  <Modal open={open} onClose={onClose}>
    <Query
      query={GET_INSTRUCTOR_DATA}
      variables={{
        InstructorId,
      }}
      fetchPolicy="network-only"
    >
      {({ loading, data, error }) => {
        const instructor: InstructorInfo = get(data, 'getInstructor', {})
        const contactInfo: InstructorInfo = get(instructor, 'contactInfo', {})

        if (error) {
          return <ApiErrorMessage />
        }

        return (
          <>
            <Header>
              <div className="avatar">
                <Avatar loading={loading} type="profile" img={instructor.Avatar} size={avatarSm} md={avatarMd} />
              </div>

              {loading ? (
                <RectLoader width="250" height="50" />
              ) : (
                <H1 disableMargin>
                  {contactInfo.FirstName} {contactInfo.LastName}
                </H1>
              )}
            </Header>

            <Spacer />
            <HR />
            <Spacer size="sm" />

            {loading ? (
              <AboutLoader />
            ) : (
              <>
                <H2>
                  <FormattedMessage id="About" /> {contactInfo.FirstName}
                </H2>

                <P>{instructor.Description}</P>
              </>
            )}
          </>
        )
      }}
    </Query>
  </Modal>
)

export default InstructorModal
