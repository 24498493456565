import gql from 'graphql-tag'

export const POST_CASE = gql`
  mutation PostCase($caseInput: [CaseInput!]!) {
    postCase(caseInput: $caseInput) {
      Success
    }
  }
`

export const PUT_CASE = gql`
  mutation PutCase($caseInput: [PutCaseInput!]!) {
    putCase(caseInput: $caseInput) {
      Success
    }
  }
`
