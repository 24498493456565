import { Cancel, Info, Tick, Warning, WarningTriangle } from '@berlitz/icons'
import React, { useState } from 'react'
import BorderlessAlert from './BorderlessAlert'
import { DismissBtn, Wrapper } from './style'
import TipsAlert from './TipsAlert'

const alertTypes = {
  error: {
    AlertIcon: Warning,
    Color: 'error',
  },
  info: {
    AlertIcon: Info,
    Color: 'info',
  },
  success: {
    AlertIcon: Tick,
    Color: 'success',
  },
  warning: {
    AlertIcon: WarningTriangle,
    Color: 'warning',
  },
}

interface IAlert {
  alertType?: 'info' | 'success' | 'warning' | 'error'
  showIcon?: boolean
  dismissable?: boolean
  small?: boolean
  children?: React.ReactNode
}

const Alert = (props: IAlert) => {
  const { children, alertType = 'info', showIcon = true, dismissable = true, small } = props
  const { AlertIcon, Color } = alertTypes[alertType]
  const [showAlert, setShowAlert] = useState(true)

  return (
    <Wrapper alertType={alertType} showIcon={showIcon} dismissable={dismissable} small={small}>
      {showIcon && <AlertIcon className="alert-icon" size="sm" color={Color} />}
      <div className="content">{children}</div>
      {dismissable && (
        <DismissBtn
          alertType={alertType}
          onClick={() => setShowAlert(!showAlert)}
          data-testid="close-alert"
          aria-label="Close Alert"
        >
          <Cancel size="sm" color={Color} />
        </DismissBtn>
      )}
    </Wrapper>
  )
}

export { TipsAlert, BorderlessAlert }
export default React.memo(Alert)
