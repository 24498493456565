import { AvailabilityOptionProp, AvailabilitySchedule } from '@components/InstructorScheduler/interface'
import { FormValueProps } from '@components/Modal/AvailabilityModal/interface'
import { RepeatOnOptions } from '@components/Modal/AvailabilityModal/constants'
import {
  InstructorAvailabilities,
  InstructorAvailabilityInfo,
  InstructorInfo,
  InstructorUnAvailabilityInfo,
  InstructorLearningCenters,
} from '@interfaces/Instructor'
import { UserProfileProps } from '@interfaces/UserProfile'
import { DATE_FORMAT, LIVE_ONLINE_VIRTUAL_CENTER } from '@utils/constants'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'
import map from 'lodash/map'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { Event, stringOrDate } from 'react-big-calendar'
import { getPrimaryLC } from '@components/Modal/AvailabilityModal/util'
import { tlc } from '@utils/helpers/programs'
import { isEmpty } from 'lodash'

const moment = extendMoment(Moment)
const defaultEndTime = '00:00'

export const isFreelanceType = (ContractType: string = ''): boolean => {
  return (ContractType || '').toLowerCase() === 'freelance'
}

export const getAvailabilityType = (
  AvailabilityOption: AvailabilityOptionProp,
  LessonType: string = '',
  primaryLC: InstructorLearningCenters
): string => {
  let type = 'F2F'
  if (primaryLC.Name.includes('Live Online')) {
    type = 'Online'
  }

  return `${get(
    uniq(AvailabilityOption.replace(' - F2F', '').replace(' - Online', '').split(';')),
    '[0]',
    ''
  )} - ${LessonType ? LessonType.replace('Face to face', 'F2F') : type}`
}

export const getTitle = (AvailabilityType: string = '') => {
  if (!AvailabilityType) {
    return 'Invalid AvailabilityType'
  }
  if (AvailabilityType.includes('F2F/Online')) {
    return 'Face to face/Online'
  }

  if (AvailabilityType.includes('F2F')) {
    return 'Face to face'
  }

  return 'Online'
}

export const getPayload = (
  isTimeOff: boolean,
  resource: AvailabilitySchedule,
  values: FormValueProps,
  profile: UserProfileProps,
  eventDate: Date,
  instructorInfo: InstructorInfo,
  type: string
) => {
  let payload = {}
  const isFreelance = isFreelanceType(get(profile, 'InstructorProfile.instructorInfo.ContractType'))
  const primaryLC = getPrimaryLC(get(profile, 'InstructorProfile.instructorInfo.learningcenters'))
  const profileTZ = profile.Timezone
  const EndTimeValue = values.EndTime.format(DATE_FORMAT.HM)
  const isEndOfDay = EndTimeValue === moment().endOf('day').format(DATE_FORMAT.HM)

  if (isTimeOff) {
    const df = DATE_FORMAT.BASIC
    const tf = 'HH:mm:ss'
    const date = moment(eventDate).format(df)
    const startTime = (values.AllDay ? values.StartTime.clone().startOf('day') : values.StartTime).format(tf)
    const endTime = (values.AllDay ? values.EndTime.clone().endOf('day') : values.EndTime).format(tf)
    const EndDateTime = moment.tz(`${date} ${endTime}`, profileTZ)
    payload = {
      UnAvailabilityType: values.UnAvailabilityType,
      StartDaterTime: moment.tz(`${date} ${startTime}`, profileTZ).utc().format(),
      Recurrance: false,
      KeepExistingLessons: values.KeepLessons || false,
      EndDateTime: (isEndOfDay ? EndDateTime.clone().add(1, 'day').startOf('day') : EndDateTime).utc().format(),
      LearningCenterId: values.LearningCenterId,
      LearningCenterName: values.LearningCenterName,
    }

    if (isFreelance) {
      payload = {
        ...payload,
        ApprovalStatus: 'Approved',
      }
    }

    if (isFreelance) {
      payload = {
        ...payload,
        ApprovalStatus: 'Approved',
      }
    }
  } else {
    const EndTime = isEndOfDay ? defaultEndTime : EndTimeValue
    payload = {
      Type: values.AvailabilityType,
      StartTime: values.AllDay ? defaultEndTime : values.StartTime.format(DATE_FORMAT.HM),
      StartDate: moment(eventDate).format(DATE_FORMAT.BASIC),
      EndTime: values.AllDay ? defaultEndTime : EndTime,
      AvailabilityType: getAvailabilityType(get(instructorInfo, 'AvailabilityOption'), type, primaryLC),
      CancelSessionFlag: !values.KeepLessons,
      LearningCenterId: values.LearningCenterId,
      LearningCenterName: values.LearningCenterName,
    }
  }

  return {
    ...resource,
    ...payload,
    TimeZone: profile.Timezone,
    InstructorProfileId: get(instructorInfo, 'Id', ''),
    InstructorName: `${get(instructorInfo, 'contactInfo.FirstName', '')} ${get(
      instructorInfo,
      'contactInfo.LastName',
      ''
    )}`,
    AllDay: values.AllDay,
  }
}

// Returns an array of dates between the two dates
const getDates = (startDate, endDate) => {
  let dates: Date[] = []

  // Strip hours minutes seconds etc.
  let currentDate: Date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())

  while (currentDate <= endDate) {
    dates = [...dates, currentDate]
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1 // Will increase month if over range
    )
  }

  return dates
}

export const getSelectedSchedules = (
  values: FormValueProps,
  profile: UserProfileProps,
  resource: AvailabilitySchedule,
  isTimeOff: boolean
): AvailabilitySchedule[] => {
  let lessonType: string[] = []

  if (isArray(values.LessonType)) {
    lessonType = [...values.LessonType]
  }

  if (isString(values.LessonType)) {
    lessonType = [values.LessonType]
  }

  const omitFields = isTimeOff ? ['EndTime', 'StartTime'] : []

  return flatten(
    lessonType.map((type) => {
      return getDates(values.StartDate.toDate(), values.EndDate.toDate())
        .filter((date: Date) => {
          return (isTimeOff ? map(RepeatOnOptions, 'id') : values.RepeatOn).includes(moment(date).weekday())
        })
        .map((date: Date) => {
          const instructorInfo = get(profile, 'InstructorProfile.instructorInfo', {})
          return omit(getPayload(isTimeOff, resource, values, profile, date, instructorInfo, type), [
            ...omitFields,
            'EndDate',
            '__typename',
          ])
        })
    })
  )
}

export const getTimeOffConflict = (
  events: Event[],
  selectedScheds: InstructorUnAvailabilityInfo[],
  StartDate: Moment.Moment,
  EndDate: Moment.Moment,
  Id: string
): Event[] => {
  return filter(events, (event) => {
    return (
      moment(event.start).isBetween(StartDate.subtract(1, 'day'), EndDate.add(1, 'day')) &&
      get(event, 'resource.UnAvailabilityType') !== void 0 &&
      get(event, 'resource.Id') !== Id
    )
  }).filter(({ start, end }) =>
    selectedScheds.some(({ StartDaterTime, EndDateTime }) => {
      const eventRange = moment.range(moment(start), moment(end))
      const selectedRange = moment.range(moment(StartDaterTime), moment(EndDateTime))
      return eventRange.overlaps(selectedRange)
    })
  )
}

export const getSchedConflict = (
  events: Event[],
  selectedScheds: InstructorAvailabilityInfo[],
  StartDate: Moment.Moment,
  EndDate: Moment.Moment
): Event[] =>
  filter(
    events,
    (event) =>
      get(event, 'resource.UnAvailabilityType') === void 0 &&
      moment(event.start).isBetween(StartDate.subtract(1, 'day'), EndDate.add(1, 'day'))
  ).filter(({ start, end }) =>
    selectedScheds.some(({ StartDate: SelectedStartDate, StartTime, EndTime }) => {
      const eventRange = moment.range(moment(start), moment(end))
      const selectedRange = moment.range(
        moment(`${SelectedStartDate} ${StartTime}`),
        moment(`${SelectedStartDate} ${EndTime}`)
      )
      return eventRange.overlaps(selectedRange)
    })
  )

export const getTypeConflict = (events: Event[], selectedScheds: InstructorAvailabilityInfo[]): Event[] =>
  events.filter(({ start, end, resource }) =>
    selectedScheds.some(({ StartDate, StartTime, EndTime, Type }) => {
      const eventRange = moment.range(moment(start), moment(end))
      const selectedRange = moment.range(moment(`${StartDate} ${StartTime}`), moment(`${StartDate} ${EndTime}`))
      return eventRange.overlaps(selectedRange) && resource.Type !== Type
    })
  )

export const getAvailTypeConflict = (events: Event[], selectedScheds: InstructorAvailabilityInfo[]): Event[] =>
  events.filter(
    ({ start, end, title, resource }) =>
      resource.UnAvailabilityType === void 0 &&
      selectedScheds.some(({ StartDate, StartTime, EndTime, AvailabilityType }) => {
        const eventRange = moment.range(moment(start).subtract(45, 'minutes'), moment(end).add(45, 'minutes'))
        const selectedRange = moment.range(moment(`${StartDate} ${StartTime}`), moment(`${StartDate} ${EndTime}`))
        return eventRange.overlaps(selectedRange) && title !== getTitle(AvailabilityType)
      })
  )

export const getReplacedEvents = (
  events: Event[],
  selectedScheds: InstructorAvailabilityInfo[]
): InstructorAvailabilities[] => [
  ...events.map(({ resource }) => ({
    InstructorAvailabilityInfo: {
      Id: resource.Id,
      Action: 'Delete',
      CancelSessionFlag: get(selectedScheds, '[0].CancelSessionFlag'),
    },
  })),
  ...selectedScheds.map((sched) => ({
    InstructorAvailabilityInfo: {
      ...omit(sched, ['Id']),
      CancelSessionFlag: false,
    },
  })),
]

export const getMergedEvents = (
  events: Event[],
  selectedScheds: InstructorAvailabilityInfo[]
): InstructorAvailabilities[] => {
  const tf = DATE_FORMAT.HM
  return [
    ...filter(events, ['resource.AvailabilityType', get(selectedScheds, '[0].AvailabilityType')]).map(
      ({ start, end, resource }) => {
        const conflict = find(selectedScheds, ({ StartDate, StartTime, EndTime }: InstructorAvailabilityInfo) => {
          const eventRange = moment.range(moment(start), moment(end))
          const selectedRange = moment.range(moment(`${StartDate} ${StartTime}`), moment(`${StartDate} ${EndTime}`))
          return eventRange.overlaps(selectedRange)
        })

        const eventStartTime = moment(get(resource, 'StartTime'), tf)
        const eventEndTime = moment(get(resource, 'EndTime'), tf)
        const conflictStartTime = moment(get(conflict, 'StartTime'), tf)
        const conflictEndTime = moment(get(conflict, 'EndTime'), tf)
        const CancelSessionFlag = get(conflict, 'CancelSessionFlag')

        const isEventStartTimeEarlier = eventStartTime.isBefore(conflictStartTime)
        const isEventEndTimeLater = eventEndTime.isAfter(conflictEndTime)

        return {
          InstructorAvailabilityInfo: omit(
            {
              ...resource,
              StartTime: (isEventStartTimeEarlier ? eventStartTime : conflictStartTime).format(tf),
              EndTime: (isEventEndTimeLater ? eventEndTime : conflictEndTime).format(tf),
              CancelSessionFlag,
            },
            ['__typename']
          ),
        }
      }
    ),
  ]
}

export const formScheduleEvents = (schedule: AvailabilitySchedule[]): Event[] => {
  let doubleLessonType: Event[] = []
  const events: Event[] = schedule.map((sched) => {
    const { StartDate, AvailabilityType = '', Id, StartTime, EndTime } = sched
    const hasTwoTypes = AvailabilityType && AvailabilityType.includes('F2F/Online')
    const sdate = moment(StartDate, DATE_FORMAT.BASIC)

    let event = {
      title: hasTwoTypes ? 'Face to face' : getTitle(AvailabilityType),
      id: Id,
      start: new Date(),
      end: new Date(),
      resource: sched,
    }

    if (sched.UnAvailabilityType) {
      const updatedStart = moment(sched.StartDaterTime).weekday(moment(sched.StartDaterTime).weekday()).clone()
      const updatedEnd = moment(sched.EndDateTime).weekday(moment(sched.EndDateTime).weekday()).clone()
      event = {
        ...event,
        title: sched.UnAvailabilityType,
        start: updatedStart.toDate(),
        end: updatedEnd.toDate(),
      }
    } else {
      const tz = sched?.TimeZone || moment.tz.guess()
      const StartDateTime = moment.tz(sched.StartDateTime, tz).clone()
      const EndDateTime = moment.tz(sched.EndDateTime, tz).clone()
      const isEndOfDay = EndDateTime.format(DATE_FORMAT.HM) === defaultEndTime
      const schedEndTime = isEndOfDay ? StartDateTime.endOf('day') : EndDateTime
      event = {
        ...event,
        start: new Date(moment.tz(sched.StartDateTime, tz).format('llll')),
        end: new Date(schedEndTime.format('llll')),
      }
    }

    if (hasTwoTypes) {
      doubleLessonType = [
        ...doubleLessonType,
        {
          ...event,
          title: 'Online',
        },
      ]
    }

    return event
  })

  return [...events, ...doubleLessonType]
}

const getAvailabilitySplits = (
  Availabilities: InstructorAvailabilityInfo[],
  UnAvailabilities: InstructorUnAvailabilityInfo[]
): InstructorAvailabilityInfo[] => {
  let splitAvails: InstructorAvailabilityInfo[] = []
  Availabilities.map((avail) => {
    const tz = avail.TimeZone || moment.tz.guess()
    const start = moment(`${avail.StartDate} ${avail.StartTime}`, DATE_FORMAT.DHM).tz(tz).utc()
    const end = moment(`${avail.StartDate} ${avail.EndTime}`, DATE_FORMAT.DHM).tz(tz).utc()

    const timeOffsInsideAvail = UnAvailabilities.filter(({ StartDaterTime, EndDateTime }) => {
      const availRange = moment.range(start, end)
      const unAvailRange = moment.range(moment(StartDaterTime), moment(EndDateTime))
      return availRange.contains(unAvailRange) && !availRange.overlaps(unAvailRange)
    })

    splitAvails = [...splitAvails, avail]
    if (timeOffsInsideAvail.length) {
      const schedules: AvailabilitySchedule[] = [avail, ...timeOffsInsideAvail]
      orderBy(schedules, ['StartDaterTime', 'StartTime']).map((item, index, arr) => {
        const prevItem = arr[index - 1] || item
        const isLastItem = arr.length === index + 1
        splitAvails = [
          ...splitAvails,
          {
            ...avail,
            StartTime: !index ? avail.StartTime : moment(prevItem.EndDateTime).format(DATE_FORMAT.HM),
            EndTime: isLastItem ? avail.EndTime : moment(item.StartDaterTime).format(DATE_FORMAT.HM),
          },
        ]
      })
    }
  })

  return splitAvails.filter(({ StartTime, EndTime }) => StartTime !== EndTime)
}

export const getOverlapAvails = (
  Availabilities: InstructorAvailabilityInfo[],
  UnAvailabilities: InstructorUnAvailabilityInfo[]
): InstructorAvailabilityInfo[] => {
  let overlappedAvails: InstructorAvailabilityInfo[] = []

  Availabilities.map((avail) => {
    const tz = avail.TimeZone || moment.tz.guess()
    const start = moment(`${avail.StartDate} ${avail.StartTime}`, DATE_FORMAT.DHM).tz(tz).utc()
    const end = moment(`${avail.StartDate} ${avail.EndTime}`, DATE_FORMAT.DHM).tz(tz).utc()

    const conflicts = UnAvailabilities.filter(({ StartDaterTime, EndDateTime }) => {
      const availRange = moment.range(start, end)
      const unAvailRange = moment.range(moment(StartDaterTime), moment(EndDateTime))
      return availRange.overlaps(unAvailRange) && !unAvailRange.contains(availRange)
    })

    overlappedAvails = [...overlappedAvails, avail]

    if (conflicts.length) {
      const unAvailStart = conflicts.find(({ StartDaterTime, EndDateTime }) => {
        return start.isBetween(StartDaterTime, EndDateTime)
      })
      const unAvailEnd = conflicts.find(({ StartDaterTime, EndDateTime }) => {
        return end.isBetween(StartDaterTime, EndDateTime)
      })

      overlappedAvails = [
        ...overlappedAvails,
        {
          ...avail,
          StartTime: unAvailStart ? moment(unAvailStart.EndDateTime).format(DATE_FORMAT.HM) : avail.StartTime,
          EndTime: unAvailEnd ? moment(unAvailEnd.StartDaterTime).format(DATE_FORMAT.HM) : avail.EndTime,
        },
      ]
    }
  })

  return overlappedAvails
}

export const getAvailabilities = (
  Availabilities: InstructorAvailabilityInfo[],
  UnAvailabilities: InstructorUnAvailabilityInfo[]
): InstructorAvailabilityInfo[] => {
  return getOverlapAvails(getAvailabilitySplits(Availabilities, UnAvailabilities), UnAvailabilities)
}

export const getUnAvailabilities = (UnAvailabilities: InstructorUnAvailabilityInfo[]): InstructorUnAvailabilityInfo[] =>
  UnAvailabilities.filter(({ ApprovalStatus }) => ApprovalStatus !== 'Rejected')

export const getThreeWeekEvents = (events: Event[], currentDate: Moment.Moment): Event[] => {
  const currentWeekScheds = events
    .filter(({ start = moment(), end = moment() }) => {
      const weekRange = moment.range(
        currentDate.clone().subtract(1, 'week').startOf('week').startOf('day'),
        currentDate.clone().add(1, 'week').endOf('week').endOf('day')
      )
      const schedRange = moment.range(start, end)
      return weekRange.overlaps(schedRange)
    })
    .map(({ resource }) => resource)
  const Availabilities = currentWeekScheds.filter(({ AvailabilityType }) => AvailabilityType)
  const UnAvailabilities = currentWeekScheds.filter(({ UnAvailabilityType }) => UnAvailabilityType)
  return formScheduleEvents([...getAvailabilities(Availabilities, UnAvailabilities), ...UnAvailabilities])
}

export const displayDeleteBtn = (profile: UserProfileProps, isAdmin?: boolean, event?: Event) => {
  const contractType = profile?.InstructorProfile?.instructorInfo?.ContractType
  const availabilityType = profile?.InstructorProfile?.instructorInfo?.AvailabilityType
  const availabilityOption = profile?.InstructorProfile?.instructorInfo?.AvailabilityOption

  // based on https://berlitz.atlassian.net/browse/PTL-1300
  const primaryLC = getPrimaryLC(profile?.InstructorProfile?.instructorInfo?.learningcenters)
  const isLiveOnlineInstructor = tlc(primaryLC.Name).includes(tlc(LIVE_ONLINE_VIRTUAL_CENTER))
  const isFlexEvent = tlc(event?.resource?.Type) === 'flex' || !event

  const cng = tlc(contractType) === tlc('Contracted Not Guaranteed')
  const freelance = tlc(contractType) === tlc('Freelance')
  const cg = tlc(contractType) === tlc('Contracted Guaranteed')
  const flex = tlc(availabilityType).includes('flex')
  const contractedF2F = tlc(availabilityOption).includes(tlc('Available(Contracted) - F2F'))
  const contractedOnline = tlc(availabilityOption).includes(tlc('Available(Contracted) - Online'))
  const guaranteedOnline = tlc(availabilityOption).includes(tlc('Available(Guaranteed) - Online'))
  const tentativeOnline = tlc(availabilityOption).includes(tlc('Tentative - Online'))

  // options based on https://berlitz.atlassian.net/wiki/spaces/B2/pages/687570955/Bulk+delete
  const option1 = cng && flex && contractedF2F
  const option2 = cng && flex && contractedOnline
  const option3 = freelance && flex && tentativeOnline
  const option4 = isAdmin && cg && !flex && guaranteedOnline
  const option5 = isAdmin && cg && flex && guaranteedOnline
  const option6 = isAdmin && cng && !flex && contractedOnline
  // options 7 based on https://berlitz.atlassian.net/browse/PTL-1300
  const option7 = !isAdmin && cng && isFlexEvent && isLiveOnlineInstructor

  return (option1 || option2 || option3 || option4 || option5 || option6) && !option7
}

export const canCreateAvailability = (EndDateWithBerlitz: string = '', date?: stringOrDate) => {
  return (!!EndDateWithBerlitz && moment(EndDateWithBerlitz).isSameOrAfter(date, 'date')) || !EndDateWithBerlitz
}

export const canModifyFlex = (profile: UserProfileProps, isAdmin: boolean = false, resource?: AvailabilitySchedule) => {
  const instructorInfo = profile.InstructorProfile?.instructorInfo
  const primaryLC = getPrimaryLC(profile?.InstructorProfile?.instructorInfo?.learningcenters)
  const isLiveOnlineInstructor = tlc(primaryLC.Name).includes(tlc(LIVE_ONLINE_VIRTUAL_CENTER))
  const isCnG = tlc(instructorInfo?.ContractType || '').includes(tlc('Contracted Not Guaranteed'))
  const isFlexEvent = tlc(resource?.Type) === 'flex' || isEmpty(resource)
  const option7 = !isAdmin && isCnG && isFlexEvent && isLiveOnlineInstructor
  return !option7
}
