import styled, { css } from 'styled-components'

export interface ButtonProp {
  type?: string
}

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  #reasonContainer {
    width: 50%;
    min-width: 200px;
  }
`

export const ModalIconWrapper = styled.div<ButtonProp>`
  height: 106px;
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 53px;

  ${({ type = 'info', theme }) =>
    type === 'info' &&
    css`
      background-color: ${theme.palette[type + '100']};

      svg {
        height: ${theme.layout.lg};
        width: ${theme.layout.lg};
        color: ${theme.colors.brandPrimaryContrast};
      }
    `}

  ${({ type = 'info', theme }) =>
    type === 'success' &&
    css`
      svg {
        height: 106px;
        width: 106px;
        color: ${theme.palette[type + '100']};
      }
    `}
`

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button:last-child {
    margin-left: ${({ theme }) => theme.space.xs};
    &:not(disabled) {
      color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    }
    &:disabled {
      border-color: ${({ theme }) => theme.palette.gray20};
      color: ${({ theme }) => theme.palette.gray100};
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.palette.gray20};
    }
  }
`
