export const SET_DATE = 'mySchedules/SET_DATE'
export const SET_TITLE = 'mySchedules/SET_TITLE'

export const setDate = date => ({
  type: 'mySchedules/SET_DATE',
  date,
})

export const setScheduleTabTitle = title => ({
  type: 'mySchedules/SET_TITLE',
  title,
})
