import { InstructorGroupInfoType } from '@interfaces/LessonGroups'
import uniqBy from 'lodash/uniqBy'
import {
  SET_PENDING_COUNT,
  SET_PENDING_ERROR,
  SET_PENDING_LESSONS,
  SET_PENDING_LOADING,
  SET_PENDING_PROCESSED,
} from '../actions/pendingLessons'

export const initialState = {
  pendingLessonCount: 0 as number,
  loading: false,
  error: false,
  pending: [],
  lessonGroup: {} as InstructorGroupInfoType,
  processed: [],
}

function pendingLessonsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PENDING_COUNT:
      return Object.assign({}, state, {
        pendingLessonCount: action.pendingLessonCount,
      })

    case SET_PENDING_LOADING:
      return Object.assign({}, state, {
        loading: action.loading,
        error: action.loading ? false : state.error,
      })

    case SET_PENDING_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    case SET_PENDING_LESSONS:
      __DEV__ && console.log('action.lessons?.pendingLessonCount: %o', action.lessons?.pendingSessionCount)

      // @ts-ignore
      const pending = action.lessons?.pendingLessons?.filter(lesson => !state?.processed?.includes(lesson?.SessionId))
      const pendingLessonCount = uniqBy(pending, 'SessionId')?.length ?? 0

      return Object.assign({}, state, {
        loading: false,
        error: false,
        pending,
        pendingLessonCount,
        lessonGroup: action.lessons?.lessonGroup,
      })

    case SET_PENDING_PROCESSED:
      return Object.assign({}, state, {
        processed: [...new Set([...action.processed, ...state.processed])],
      })

    default:
      return state
  }
}

export default pendingLessonsReducer
