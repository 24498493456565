import { Cancel, Info, Tick, Warning } from '@berlitz/icons'
import * as React from 'react'

import { Provider } from './Context'
import { ToastProps } from './interface'
import Toasters from './Toasters'

const defaultPosition: string = 'bottomCenter'
const defaultDuration: number = 3000

export default class ToasterProvider extends React.Component {
  state = {
    toasts: [],
  }

  add = (toast: ToastProps, duration = defaultDuration) => {
    const { toasts } = this.state
    const id = Date.now()

    this.setState(
      {
        toasts: [
          ...toasts,
          {
            ...toast,
            id,
          },
        ],
      },
      () => {
        setTimeout(() => {
          this.remove(id)
        }, duration)
      }
    )
  }

  remove = (toastId: number) => {
    const { toasts } = this.state

    this.setState({
      toasts: toasts.filter(({ id }: { id: number }) => id !== toastId),
    })
  }

  info = (message: string, position = defaultPosition, duration = defaultDuration) => {
    this.add(
      {
        type: 'info',
        icon: <Info color="info" />,
        message,
        position,
      },
      duration
    )
  }

  success = (message: string, position = defaultPosition, duration = defaultDuration) => {
    this.add(
      {
        type: 'success',
        icon: <Tick color="success" />,
        message,
        position,
      },
      duration
    )
  }

  warning = (message: string, position = defaultPosition, duration = defaultDuration) => {
    this.add(
      {
        type: 'warning',
        icon: <Warning color="warning" />,
        message,
        position,
      },
      duration
    )
  }

  error = (message: string, position = defaultPosition, duration = defaultDuration) => {
    this.add(
      {
        type: 'error',
        icon: <Cancel color="error" />,
        message,
        position,
      },
      duration
    )
  }

  render() {
    const { info, success, warning, error } = this
    const context = {
      success,
      info,
      warning,
      error,
    }

    return (
      <Provider value={context}>
        <Toasters toasts={this.state.toasts} onRemove={this.remove} />
        {this.props.children}
      </Provider>
    )
  }
}
