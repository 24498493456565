import styled from 'styled-components'

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.blue80};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  svg {
    margin-right: ${({ theme }) => theme.space.xs};
  }
`

export const ContactUs = styled.div`
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  padding: ${({ theme }) => theme.space.xs};
  font-size: ${({ theme }) => theme.fontSize.xxs};

  a {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

export const Box = styled.div`
  background-color: ${({ theme }) => theme.palette.info20};
  height: max-content;
  padding: ${({ theme }) => theme.space.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  &.width-max-content {
    width: max-content;
  }

  &.tip-alert-warning {
    background-color: ${({ theme }) => theme.palette.warning10};

    ${Title}, svg {
      color: ${({ theme }) => theme.palette.warning100};
    }
  }

  ul {
    list-style: initial;
    list-style-position: inside;
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`
