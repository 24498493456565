import gql from 'graphql-tag'

export const PUT_STUDENTS = gql`
  mutation PutStudents($studentInput: [StudentInput!]!) {
    putStudents(studentInput: $studentInput) {
      StudentProfileInfo {
        VideoAuthority
        Student
        RecordId
        Ranking
        PreferredTimezone
        PreferredLocale
        Phone
        OverallPerformanceScore
        MobilePhone
        Milestone
        Level
        Hobbies
        FamilyAndRelationship
        EnableRanking
        EnableCustomerSatisfaction
        Email
        Education
        DisplayName
        FirstLessonDate
        Country
        AboutYou
        City
        Latitude
        Longitude
        PostalCode
        State
        Street
        Purpose
        DetailsOfGoal
        LearningGoal
        LastLessonDate
        EnablePermissions
        Profession
        NickName
        Company
        LastName
        ContactMethod
        FirstName
        Location
        EnableUploadFiles
        EnableGDPR
        CustomerId
        Birthdate
        OtherDetails
        PreferredLanguage
        PortalLanguage
        PortalTimeformat
      }
    }
  }
`

export const PUT_LEARNING_PROFILES = gql`
  mutation PutLearningProfiles($learningProfileInput: [LearningProfileInput!]!) {
    putLearningProfiles(learningProfileInput: $learningProfileInput) {
      StudentLanguageProfileInfo {
        RecordId
        StudentProfileId
        StudentName
        Language
        Details
        AllNotesWrittenOnTheTakeaway
        CustomizedToLifeSituations
        CustomizedToWorkSituation
        DoesnotWantStrictCorrection
        FocusOnPronunciation
        GetHintsOrSupport
        HaveRolePlays
        KeepSelfIntroductionsToMinimum
        LearnPoliteFormalExpressions
        LearnWordsAndExpressions
        NoNaturalWayToSaySomething
        RepeatCorrectedWordsOrPhrases
        SpeakAsMuchAsPossible
        SpeakAtNaturalSpeed
        StrictCorrection
        TaughtFullSentences
        Purpose
        ByWhen
        FirstLessonDate
        LessonTaken
        Goal
        Goals {
          RecordId
          Description
          Item
          Language
          Order
          Type
        }
      }
    }
  }
`

export const PUT_SCHEDULES = gql`
  mutation putSchedules($SessionInfo: [SessionInfoInput!]!) {
    putSchedules(SessionInfoArray: $SessionInfo) {
      Attachments {
        CreatedDatetime
        FileUrl
        LastModifieDatetime
        Name
        RecordId
        Status
        Title
        SessionScheduleId
      }
      Material
      GoalNotAchieved
      Materials {
        Chapter
        Material
        Page
        Point
        RecordId
        SessionSchedule
        Status
        Type
        URL
      }
      DeliveryMode
      EndTime
      Id
      InstructorEmail
      InstructorId
      InstructorName
      LanguageLevel
      LearningCenter
      LearningCenterAddress
      LearningCenterId
      Material
      OnlineLessonURL
      ProgramEnrolDate
      ProgramId
      ProgramName
      Room
      StartTime
      Status
      StudentNote
      Timezone
      Type
      VirtualClassRoomProvider
      Attendance
      CustomerId
      Homework
      IsLastHomeworkDone
      Language
      StudentName
      Description
      IsGoalAchieved
      LateMinutes
    }
  }
`
