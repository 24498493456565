import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@berlitz/modal'
import { Tick, WarningTriangle, AccessTime } from '@berlitz/icons'

import { TitleWrapper, IconWrapper, Wrapper } from './style'

const iconTypes = {
  info: {
    Icon: AccessTime,
    iconColor: 'brand03',
    backgroundColor: 'info100',
  },
  success: {
    Icon: Tick,
    iconColor: 'brand03',
    backgroundColor: 'success100',
  },
  warning: {
    Icon: WarningTriangle,
    iconColor: 'brand03',
    backgroundColor: 'danger100',
  },
}

export const BasicModalContent = (props) => {
  const { type = 'info', modalTitle, children, open, onClose } = props

  const { Icon, iconColor, backgroundColor } = iconTypes[type]

  return (
    <Wrapper>
      <IconWrapper backgroundColor={backgroundColor}>
        <Icon color={iconColor} size="lg" />
      </IconWrapper>
      <TitleWrapper>{modalTitle}</TitleWrapper>
      {children}
    </Wrapper>
  )
}

/* eslint-disable react/prefer-stateless-function */
class BasicModal extends React.Component {
  render() {
    const { type = 'info', modalTitle, children, open, onClose } = this.props

    const { Icon, iconColor, backgroundColor } = iconTypes[type]

    /* TODO: Add XL size for icon sizes to fully support zeplin design */

    return (
      <Modal open={open} onClose={onClose}>
        <Wrapper>
          <IconWrapper backgroundColor={backgroundColor}>
            <Icon color={iconColor} size="lg" />
          </IconWrapper>
          <TitleWrapper>{modalTitle}</TitleWrapper>
          {children}
        </Wrapper>
      </Modal>
    )
  }
}

BasicModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
  modalTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default BasicModal
