import { AUTO_RAISE_CASE_COMPLETED, AUTO_RAISE_CASE_STARTED } from '../actions/case'

export const initialState = {
  isRaisingCase: false,
  programId: undefined,
}

function caseReducer(state = initialState, action) {
  switch (action.type) {
    case AUTO_RAISE_CASE_STARTED:
      return Object.assign({}, state, {
        isRaisingCase: true,
        programId: action.programId,
      })

    case AUTO_RAISE_CASE_COMPLETED:
      return Object.assign({}, state, {
        isRaisingCase: false,
        programId: undefined,
      })

    default:
      return state
  }
}

export default caseReducer
