import Spinner from '@berlitz/spinner'
import React from 'react'
import { ModalOverlay, Wrapper } from './style'

const PageOverlay = () => (
  <Wrapper>
    <ModalOverlay>
      <Spinner />
    </ModalOverlay>
  </Wrapper>
)

export default PageOverlay
