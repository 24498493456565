import * as React from 'react'
import { ContextProps } from './interface'

import ToasterConsumer from './ToasterConsumer'

const withToastManager = (Component: any) => (props) => (
  <ToasterConsumer>{(context: ContextProps) => <Component toaster={context} {...props} />}</ToasterConsumer>
)

export default withToastManager
