import gql from 'graphql-tag'

export const GET_LEARNING_PATH = gql`
  query GetLearningPath($StudentId: String!, $IdType: String!, $isFlex: Boolean, $berlitzEnv: String) {
    getLearningPath(StudentId: $StudentId, IdType: $IdType, isFlex: $isFlex, berlitzEnv: $berlitzEnv) {
      lpid
      shortname
      product
      status
      numberofunits
      completedunits
      details {
        lpid
        language
        level
        material_type
        fullname {
          language
          fullname
        }
        units {
          unitid
          shortname
          fullname
          unitstatus
          lessonstatus
          flexstatus
          resetcount
          opcompletion
          vocabexpressions
          grammaritem
          unittype
          topic
          order
          isunit0
          objectives
          reseturl
          latestattemptdate
          activities {
            type
            url
            actionplanurl
            reseturl
            language {
              code
              name
            }
          }
          attachments {
            type
            url
          }
          rounds {
            name
            status
            order
            url
          }
        }
      }
    }
  }
`

export const GET_LEARNING_PATH_BY_LPIDS_ALIAS = gql`
  query GetLearningPathByLPIDS(
    $StudentId: String!
    $IdType: String!
    $Ids: String!
    $berlitzEnv: String
    $isTopic: Boolean
  ) {
    getLearningPath: getLearningPathByIds(
      Username: $StudentId
      IdType: $IdType
      Ids: $Ids
      berlitzEnv: $berlitzEnv
      isTopic: $isTopic
    ) {
      lpid
      shortname
      product
      status
      numberofunits
      completedunits
      details {
        lpid
        language
        level
        material_type
        fullname {
          language
          fullname
        }
        units {
          unitid
          shortname
          fullname
          unitstatus
          lessonstatus
          flexstatus
          resetcount
          opcompletion
          vocabexpressions
          grammaritem
          unittype
          topic
          order
          module
          isunit0
          objectives
          reseturl
          latestattemptdate
          activities {
            type
            url
            actionplanurl
            reseturl
            language {
              code
              name
            }
          }
          attachments {
            type
            url
          }
          rounds {
            name
            status
            order
            url
          }
        }
      }
    }
  }
`

export const GET_LEARNING_PATH_BY_LPIDS = gql`
  query GetLearningPathByLPIDS($StudentId: String!, $IdType: String!, $Ids: String!, $berlitzEnv: String) {
    getLearningPathByIds(Username: $StudentId, IdType: $IdType, Ids: $Ids, berlitzEnv: $berlitzEnv) {
      lpid
      shortname
      product
      status
      numberofunits
      completedunits
      details {
        lpid
        language
        level
        material_type
        fullname {
          language
          fullname
        }
        units {
          unitid
          shortname
          module
          fullname
          unitstatus
          lessonstatus
          flexstatus
          resetcount
          opcompletion
          vocabexpressions
          grammaritem
          unittype
          topic
          order
          isunit0
          objectives
          reseturl
          latestattemptdate
          activities {
            type
            url
            actionplanurl
            reseturl
            language {
              code
              name
            }
          }
          attachments {
            type
            url
          }
          rounds {
            name
            status
            order
            url
          }
          ai_tutor_link {
            ui_lang
            target_lang
            url
          }
        }
      }
    }
  }
`

export const GET_LEARNING_PATH_WITH_STUDENT_PROFILE = gql`
  query GetLearningPath($StudentId: String!, $IdType: String!, $isFlex: Boolean) {
    getLearningPath(StudentId: $StudentId, IdType: $IdType, isFlex: $isFlex) {
      lpid
      shortname
      product
      status
      numberofunits
      completedunits
      details {
        lpid
        language
        fullname {
          language
          fullname
        }
        units {
          unitid
          shortname
          fullname
          unitstatus
          flexstatus
          resetcount
          opcompletion
          vocabexpressions
          grammaritem
          unittype
          topic
          order
          isunit0
          objectives
          reseturl
          latestattemptdate
          activities {
            type
            url
            actionplanurl
            reseturl
          }
          attachments {
            type
            url
          }
          rounds {
            name
            status
            order
            url
          }
        }
      }
    }
    getUserProfile(Id: $StudentId, Type: "Student") {
      StudentProfile {
        StudentProfileInfo {
          ProgramStudentWrappers {
            ProgramStudentInfo {
              ProgramStudentId
              ProgramId
              DeliveryMode
              ProgramType
              Language
              ProgramName
              ContractEndDate
              ContractStartDate
              Status
              Materials {
                RecordId
                NewRowPlaylistId
                LPID
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_LEARNING_UNITS = gql`
  query GetAllLearningUnits($Id: String!, $StudentId: String!) {
    getAllLearningUnits(Id: $Id, StudentId: $StudentId) {
      unitid
      shortname
      fullname
      unitstatus
      lessonstatus
      flexstatus
      resetcount
      opcompletion
      vocabexpressions
      grammaritem
      unittype
      topic
      order
      module
      isunit0
      objectives
      reseturl
      latestattemptdate
      activities {
        type
        url
        actionplanurl
        reseturl
        language {
          code
          name
        }
      }
      attachments {
        type
        url
      }
      rounds {
        name
        status
        order
        url
      }
    }
  }
`
