import bulksearch from './bulksearch'
import cases from './case'
import dashboard from './dashboard'
import intl from './intl'
import mySchedules from './mySchedules'
import pendingLessons from './pendingLessons'
import permissions from './permissions'
import session from './session'
import studentData from './studentData'
import userCognito from './userCognito'
import userProfile from './userProfile'
import preloadedData from './preloadedData'
import singleLesson from './singleLesson'
import fmiBulkForm from './fmiBulkForm'
import activeState from '@components/ActiveState/activeStateSlice'

export default {
  session,
  bulksearch,
  userProfile,
  pendingLessons,
  mySchedules,
  userCognito,
  cases,
  intl,
  permissions,
  dashboard,
  studentData,
  preloadedData,
  singleLesson,
  fmiBulkForm,
  activeState,
}
