import Button from '@berlitz/button'
import { H1, P } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import Expand6 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/52-Expand-Retract/Expand6'
import { VIRTUAL_CLASSROOM_PROVIDER } from '@utils/constants'
import Router from 'next/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

interface IPTechCheckContent {
  onClose: Function
  pid?: string
  virtualClassroomProvider: string
}

interface IPTechCheckModal extends IPTechCheckContent {
  virtualClassroomProvider: string
  open: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 320px;
  }

  button {
    &.check-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 149px;

      svg {
        font-size: ${({ theme }) => theme.fontSize.xs};
        color: ${({ theme }) => theme.colors.text03};
        margin-left: ${({ theme }) => theme.space.xs};
      }
    }
  }

  .support {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`

const TechCheckContent: React.FC<IPTechCheckContent> = ({ onClose, pid, virtualClassroomProvider }) => {
  const vcProviderTestLU = new Map()
  vcProviderTestLU.set(VIRTUAL_CLASSROOM_PROVIDER.KALTURA, 'https://smart.newrow.com/room/testPage/')
  vcProviderTestLU.set(VIRTUAL_CLASSROOM_PROVIDER.NEWROW, 'https://smart.newrow.com/room/testPage/')
  vcProviderTestLU.set(VIRTUAL_CLASSROOM_PROVIDER.ZOOM, 'https://zoom.us/test')
  const testLink = vcProviderTestLU.get(virtualClassroomProvider?.toLowerCase()) ?? ''

  return (
    <Container>
      <img src="/images/bug.svg" alt="Tech check fail" />

      <Spacer size="xxxl" />

      <H1>
        <FormattedMessage id="If your tech check fails" />
      </H1>

      <P disableMargin>
        <FormattedMessage id="1. Create a case in support." />
      </P>

      <P disableMargin>
        <FormattedMessage id="2. Upload a screenshot of your tech check." />
      </P>

      <P>
        <FormattedMessage id="3. Our team will be in touch to help." />
      </P>

      <Button className="check-btn" onClick={() => window.open(testLink, '_blank')}>
        <FormattedMessage id="Tech check" /> <Expand6 />
      </Button>

      <Spacer size="sm" />

      <a
        className="support"
        href="/"
        onClick={(e) => {
          e.preventDefault()
          onClose()
          Router.push(`/support/contact-us/?id=technical-support&cat=Virtual%20classroom${pid ? `&pid=${pid}` : ''}`)
        }}
      >
        <FormattedMessage id="Support" />
      </a>
    </Container>
  )
}

class TechCheckModal extends React.Component<IPTechCheckModal> {
  static Content = TechCheckContent

  render() {
    const { open, onClose, pid, virtualClassroomProvider } = this.props
    return (
      <Modal open={open} onClose={onClose}>
        <TechCheckContent onClose={onClose} pid={pid} virtualClassroomProvider={virtualClassroomProvider} />
      </Modal>
    )
  }
}

export default TechCheckModal
