import { ICognitoState } from '@interfaces/UserCognito'
import get from 'lodash/get'
import {
  USER_COGNITO_ACTION_LOAD,
  USER_COGNITO_ACTIVATION,
  USER_COGNITO_FORCE_PASSWORD,
  USER_COGNITO_INIT,
  USER_COGNITO_LOAD,
  USER_COGNITO_MESSAGE,
  USER_COGNITO_NEXT,
  USER_COGNITO_PREV,
  USER_COGNITO_RESET,
} from '../actions/userCognito'

export const initialState: ICognitoState = {
  users: [],
  prev: '',
  current: '',
  next: '',
  pageInfos: [],
  loading: false,
  actionLoading: false,
  search: {
    searchKey: '',
    attribute: 'email',
  },
  userOnReset: undefined,
  message: '',
  group: '',
}

const userActivation = action => user => {
  let nu = user
  if (user.username === action.user.username) {
    nu = {
      ...user,
      enabled: action.status,
    }
  }

  return nu
}

const forcePassword = action => user => {
  let nu = user
  if (user.username === action.user.username) {
    nu = {
      ...user,
      status: 'FORCE_CHANGE_PASSWORD',
    }
  }

  return nu
}

function cognitoReducer(state = initialState, action) {
  switch (action.type) {
    case USER_COGNITO_INIT: {
      const pageInfo = {
        users: action.users,
        prev: '',
        current: '',
        next: action.next,
      }
      const pageInfos = [pageInfo]
      return Object.assign({}, state, {
        ...pageInfo,
        pageInfos,
        group: action.group,
        search: action.search,
      })
    }

    case USER_COGNITO_PREV: {
      const pageInfo = state.pageInfos.find(page => page.current === action.prev)
      return Object.assign({}, state, {
        users: get(pageInfo, 'users', state.users),
        prev: get(pageInfo, 'prev', state.prev),
        current: get(pageInfo, 'current', state.current),
        next: get(pageInfo, 'next', state.next),
      })
    }

    case USER_COGNITO_NEXT: {
      let pageInfo = state.pageInfos.find(page => page.current === state.next)
      let pageInfos = state.pageInfos
      if (!pageInfo) {
        pageInfo = {
          users: action.users,
          prev: state.current,
          current: state.next,
          next: action.next,
        }
        pageInfos = state.pageInfos.concat(pageInfo)
      }
      return Object.assign({}, state, {
        ...pageInfo,
        pageInfos,
      })
    }

    case USER_COGNITO_LOAD:
      return Object.assign({}, state, {
        loading: action.loading,
      })

    case USER_COGNITO_ACTION_LOAD:
      return Object.assign({}, state, {
        actionLoading: action.loading,
      })

    case USER_COGNITO_ACTIVATION: {
      const users = state.users.map(userActivation(action))
      const pageInfos = state.pageInfos.map(page => ({
        ...page,
        users: page.users.map(userActivation(action)),
      }))

      return Object.assign({}, state, {
        users,
        pageInfos,
      })
    }

    case USER_COGNITO_RESET:
      return Object.assign({}, state, {
        userOnReset: action.user,
      })

    case USER_COGNITO_FORCE_PASSWORD: {
      const users = state.users.map(forcePassword(action))
      const pageInfos = state.pageInfos.map(page => ({
        ...page,
        users: page.users.map(forcePassword(action)),
      }))

      return Object.assign({}, state, {
        users,
        pageInfos,
      })
    }

    case USER_COGNITO_MESSAGE:
      return Object.assign({}, state, {
        message: action.message,
      })

    default:
      return state
  }
}

export default cognitoReducer
