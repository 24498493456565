import * as React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'

import BasicModal from '../../components/Modal/BasicModal'
import { UnsavedModalProps } from './interface'
import { ModalDescription, StyledButton } from './style'

const UnsavedModal: React.FC<InjectedIntlProps & UnsavedModalProps> = ({ intl, open, onDontSave, onClose }) => (
  <BasicModal
    type="warning"
    open={open}
    modalTitle={intl.formatMessage({ id: 'Your changes are not saved' })}
    onClose={onClose}
  >
    <ModalDescription>
      <FormattedMessage id="Would you like to save your changes" />?
    </ModalDescription>

    <StyledButton type="button" onClick={onDontSave}>
      <FormattedMessage id="Don't save" />
    </StyledButton>
    <StyledButton type="button" onClick={onClose}>
      <FormattedMessage id="Continue2" defaultMessage="Continue" />
    </StyledButton>
  </BasicModal>
)

export default injectIntl(UnsavedModal)
