import * as React from 'react'
import { Consumer } from './ModalContext'

interface IPModalConsumer {
  children: any
}

const ModalConsumer: React.FC<IPModalConsumer> = ({ children }) => <Consumer>{(context) => children(context)}</Consumer>

export default ModalConsumer
