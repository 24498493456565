import theme from '@berlitz/max-theme-portals'
import { Root } from '@components/Header/style'
import { DatePickerCol as DatePickerColLesson } from '@layouts/MyLessons/Layout/style'
import { DatePickerCol } from '@layouts/MySchedule/Layout/style'
import { _attention } from '@utils/mixins'
import 'antd/lib/form/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/timeline/style/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { createGlobalStyle, css } from 'styled-components'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'typeface-ibm-plex-mono'
import 'typeface-ibm-plex-sans'

type BerlitzTheme = typeof theme

const GlobalStyle = createGlobalStyle<{ theme: BerlitzTheme }>`
  body {
    font-weight: ${theme.fontWeight.semiBold};
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
  }
  a {
      transition: .4s;
      color: ${theme.colors.brandPrimary};
      font-weight: ${theme.fontWeight.bold};
      &:visited {
        color: ${theme.colors.textLinkVisited};
      }
      ${() =>
        _attention(css`
          color: hsl(240, 100%, 25%);
        `)};

  }

  .margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .mobile-nav--open {
    ${Root} {
      z-index: 1;
    }
    ${DatePickerCol}, ${DatePickerColLesson} {
      .popped-over {
        z-index: 1;
      }
    }
  }

  img[hidden] {
    display: none;
  }
`

export default GlobalStyle
