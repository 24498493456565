import gql from 'graphql-tag'

export const GET_INSTRUCTOR_AVAILABILITY = gql`
  query GetInstructorAvailability($InstructorId: String, $FromDate: String, $EndDate: String) {
    getInstructorAvailability(InstructorId: $InstructorId, FromDate: $FromDate, EndDate: $EndDate) {
      ServiceResult {
        InstructorAvailabilityInfo {
          Type
          Action
          TimeZone
          StartTime
          EndTime
          StartDate
          StartDateTime
          EndDateTime
          LearningCenterName
          LearningCenterId
          InstructorProfileId
          InstructorName
          Id
          AvailabilityType
          AllDay
        }
      }
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
    }
  }
`
