import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import { activeUserUpdated, userProfileSelector } from './activeStateSlice'

const ActiveState: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch()
  const profile = useSelector(({ userProfile }: StoreProps) => userProfile.info)
  const activeProfile = useSelector(userProfileSelector.selectUserProfile)

  React.useEffect(() => {
    if (profile?.SFId && !activeProfile) {
      dispatch(activeUserUpdated(profile))
    }
  }, [profile, activeProfile, dispatch])

  return <>{children}</>
}

export default ActiveState
