import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js'
import moment from 'moment'
import { PAGE } from './constants'
import { isGodMode } from './helpers'

const role = (session: CognitoUserSession) => {
  const cognitoGroups = session.getIdToken()?.payload['cognito:groups'] ?? []
  const isAdmin = cognitoGroups.some((group) => PAGE.ROLE_ADMINS.includes(group))
  const godMode = isGodMode(isAdmin) ?? false

  return {
    isAdmin: isAdmin ? true : false,
    godMode,
  }
}

/**
 * https://github.com/aws-amplify/amplify-js/blob/c3a06153e3ffe05dd65485a22b1f99aabe9b3d83/packages/auth/src/Auth.ts#L81C1-L89
 */
const AMPLIFY_SYMBOL = (
  typeof Symbol !== 'undefined' && typeof Symbol.for === 'function'
    ? Symbol.for('amplify_default')
    : '@@amplify_default'
) as Symbol

export const dispatchAuthEvent = (event: string, session: CognitoUserSession, message: string) => {
  const { isAdmin, godMode } = role(session)
  if (!isAdmin || (isAdmin && godMode)) {
    Hub.dispatch('auth', { event, data: undefined, message }, 'Auth', AMPLIFY_SYMBOL)
  }
}

const godModeLog = (session: CognitoUserSession, parentLog: string, log: string) => {
  const { godMode } = role(session)

  const expiry = session.getIdToken().getExpiration()
  const diff = expiry * 1000 - Date.now()
  const dateString = moment.unix(expiry).format()

  godMode &&
    console.log('godMode getSession', {
      expiration: dateString,
      logMessage: `${parentLog} - ${log}`,
      session,
    })
}

let inflight = false

/**
 * This is used to get a session, either from the session object
 * or from  the local storage, or by using a refresh token (triggers an event)
 *
 * @param {GetSessionOptions} options
 * @returns {Promise<CognitoUserSession | undefined>}
 */
export const getSession = async (parentLog: string) => {
  const user = Auth['userPool'].getCurrentUser()

  /**
   * If user is empty, then I think it might be good if we trigger refresh_token event in here
   * since calling currentSession function will automatically refresh token if its expired
   */
  if (!user) {
    const session = await Auth.currentSession()
    godModeLog(session, parentLog, 'the user is null')
    // dispatchAuthEvent('tokenRefresh', session, `New token retrieved`)
    return Promise.resolve(session)
  }

  if (user.signInUserSession != null && user.signInUserSession.isValid()) {
    const session: CognitoUserSession = user.signInUserSession
    godModeLog(session, parentLog, 'triggered from signInUserSession')
    return Promise.resolve(session)
  }

  const keyPrefix = `CognitoIdentityServiceProvider.${user.pool.getClientId()}.${user.username}`

  const idTokenKey = `${keyPrefix}.idToken`
  const accessTokenKey = `${keyPrefix}.accessToken`
  const refreshTokenKey = `${keyPrefix}.refreshToken`
  const clockDriftKey = `${keyPrefix}.clockDrift`

  if (user.storage.getItem(idTokenKey)) {
    const idToken = new CognitoIdToken({
      IdToken: user.storage.getItem(idTokenKey),
    })
    const accessToken = new CognitoAccessToken({
      AccessToken: user.storage.getItem(accessTokenKey),
    })
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: user.storage.getItem(refreshTokenKey),
    })

    const clockDrift = parseInt(user.storage.getItem(clockDriftKey), 0) || 0

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
      ClockDrift: clockDrift,
    }
    const cachedSession = new CognitoUserSession(sessionData)

    if (cachedSession.isValid()) {
      user.signInUserSession = cachedSession
      godModeLog(cachedSession, parentLog, 'triggered from cachedSession')
      inflight = false
      return Promise.resolve(cachedSession)
    }

    const session = await Auth.currentSession()
    if (!inflight) {
      godModeLog(session, parentLog, 'triggered from refreshSession')
      // dispatchAuthEvent('tokenRefresh', session, `New token retrieved`)
      inflight = true
    }
    return Promise.resolve(session)
  } else {
    const session = await Auth.currentSession()
    godModeLog(session, parentLog, 'Local storage is missing an ID Token, Please authenticate')
    // dispatchAuthEvent('tokenRefresh', session, `New token retrieved`)
    inflight = false
    return Promise.resolve(session)
  }
}
