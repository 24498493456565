export const AUTO_RAISE_CASE_STARTED = 'case/AUTO_RAISE_CASE_STARTED'
export const AUTO_RAISE_CASE_COMPLETED = 'case/AUTO_RAISE_CASE_COMPLETED'

export const autoRaiseCase = programId => ({
  type: AUTO_RAISE_CASE_STARTED,
  programId,
})

export const autoRaiseCompleted = () => ({
  type: AUTO_RAISE_CASE_COMPLETED,
})
