import { H1 } from '@berlitz/globals'
import { WarningTriangle } from '@berlitz/icons'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { CenterText, IconCircle } from '../style'

const ApiErrorMessage: React.FC = () => (
  <CenterText>
    <IconCircle color="error">
      <WarningTriangle size="lg" />
    </IconCircle>

    <H1>
      <FormattedMessage id="An unknown error has occurred. Please try again later." />
    </H1>
  </CenterText>
)

export default ApiErrorMessage
