import styled, { css } from 'styled-components'
import { ToasterItemBoxProps, ToasterItemTagProps } from './interface'

export const Icon = styled.div`
  margin-right: ${({ theme }) => theme.space.xs};
`

export const IconBox = styled.div`
  margin-right: ${({ theme }) => theme.space.xs};
`

export const ToasterItemTag = styled.div<ToasterItemTagProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 336px;
  height: max-content;
  background-color: ${({ theme }) => theme.palette.blue10};
  border: 3px solid ${({ theme }) => theme.palette.blue60};
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.md}`};
  margin: ${({ theme }) => theme.space.lg};
  font-size: ${({ theme }) => theme.fontSize.xs};
  border-radius: ${({ theme }) => theme.radius.md};
  cursor: pointer;

  ${({ type }) =>
    type &&
    type === 'success' &&
    css`
      background-color: ${({ theme }) => theme.palette.success10};
      border: 3px solid ${({ theme }) => theme.palette.success100};
    `}

  ${({ type }) =>
    type &&
    type === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.palette.warning10};
      border: 3px solid ${({ theme }) => theme.palette.warning100};
    `}

  ${({ type }) =>
    type &&
    type === 'error' &&
    css`
      background-color: ${({ theme }) => theme.palette.danger10};
      border: 3px solid ${({ theme }) => theme.palette.danger100};
    `}

  ${({ theme, position }) =>
    position &&
    (position === 'topLeft' || position === 'topCenter' || position === 'topRight') &&
    css`
      margin-bottom: ${theme.space.sm};

      &:not(:first-child) {
        margin-top: 0;
      }
    `}

  ${({ theme, position }) =>
    position &&
    (position === 'bottomLeft' || position === 'bottomCenter' || position === 'bottomRight') &&
    css`
      margin-top: ${theme.space.sm};

      &:not(:last-child) {
        margin-bottom: 0;
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0;
    margin-right: 0;
  }
`

export const ToasterItemBox = styled.div<ToasterItemBoxProps>`
  position: fixed;
  z-index: 99;

  ${({ position }) =>
    position &&
    position === 'topLeft' &&
    css`
      left: 0;
    `}

  ${({ position }) =>
    position &&
    position === 'topCenter' &&
    css`
      left: 50%;
      margin-left: -168px;
    `}

  ${({ position }) =>
    position &&
    position === 'topRight' &&
    css`
      right: 0;
    `}

  ${({ position }) =>
    position &&
    position === 'bottomLeft' &&
    css`
      left: 0;
      bottom: 0;
    `}

  ${({ position }) =>
    position &&
    position === 'bottomCenter' &&
    css`
      left: 50%;
      bottom: 0;
      margin-left: -168px;
    `}

  ${({ position }) =>
    position &&
    position === 'bottomRight' &&
    css`
      right: 0;
      bottom: 0;
    `}

  ${({ position }) =>
    !position &&
    css`
      left: 50%;
      bottom: 0;
      margin-left: -168px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    left: 50%;
    margin-left: -168px;
  }
`

export const ToasterBox = styled.div`
  position: absolute;
`
