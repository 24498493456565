import { StudentProfileInfo } from '@interfaces/Student'
import _ from 'lodash'
import { STORE_INSTRUCTOR_STUDENT_PROFILE } from '../actions/studentData'

export const initialState = {
  id: undefined as string | undefined,
  profile: {} as StudentProfileInfo,
}

function studentDataReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_INSTRUCTOR_STUDENT_PROFILE:
      const newState = { ...state }
      action.id ? (newState[action.id] = action.profile) : null
      return _.cloneDeep(newState)
    default:
      return state
  }
}

export default studentDataReducer
