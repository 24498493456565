import { _attention } from '@utils/mixins'
import styled, { css } from 'styled-components'

/* TODO: - static variables to be added in max-theme-portals later */
export const Wrapper = styled.aside<{ isActivityPage: boolean; isAdmin?: boolean }>`
  width: 100%;
  height: ${({ theme }) => theme.layout.lg};
  position: relative;
  background-color: ${({ theme }) => theme.colors.ui06};
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.space.sm};
  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: absolute;
    display: block;
    width: ${({ theme }) => theme.layout.lg};
    height: 100%;
    /* move to max */
    min-height: ${({ isActivityPage, isAdmin }) =>
      isActivityPage ? 'calc(100vh - 116px)' : `calc(100vh - ${isAdmin ? '114px' : '76px'})`};
    bottom: 0;
    padding: ${({ theme }) => `${theme.space.md} 0px`};
    text-align: center;
    a:last-child {
      bottom: -${({ theme }) => theme.space.xs};
      position: fixed;
      left: 0;
      width: ${({ theme }) => theme.layout.lg};
    }
  }

  ${({ isActivityPage }) =>
    isActivityPage &&
    css`
      display: none !important;
    `}
`

interface NavigationProps {
  active: boolean
}

export const NavigationWrapper = styled.div<NavigationProps>`
  padding: ${({ theme }) => `0 ${theme.space.xxxs}`};
  cursor: pointer;
  margin-right: ${({ theme }) => theme.layout.xs};
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  ${({ theme, active }) => css`
    svg,
    path {
      align-self: center;
      color: ${active ? theme.iconColors.brand01 : theme.palette.blue30};
    }
    span {
      color: ${active ? theme.colors.brandPrimary : theme.palette.blue30};
    }
  `}

  svg, path {
    transition: 0.2s;
  }

  ${({ theme }) =>
    _attention(css`
      svg,
      path {
        align-self: center;
        color: ${theme.palette.blue60};
      }
    `)};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    margin-bottom: ${({ theme }) => theme.layout.xs};
    margin-right: 0px;
  }
`

export const NavigationName = styled.span<{ longText?: boolean }>`
  font-family: 'IBM Plex Mono', 'Helvetica Neue', Arial, sans-serif;
  margin-top: ${({ theme }) => theme.space.xxxs};
  padding: 0 ${({ theme }) => theme.space.xxxs};
  width: 100%;
  white-space: normal;
  hyphens: auto;
  word-break: break-all;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    word-break: normal;
    text-align: center;
    white-space: unset;
  }

  ${({ longText, theme }) =>
    longText
      ? css`
          font-size: 11px;
          letter-spacing: -1px;
        `
      : css`
          letter-spacing: -0.5px;
          font-size: ${theme.fontSize.xxxs};
        `}
`
