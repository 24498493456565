import PortalStudentProfile from '@classes/StudentProfile'
import useCustomLazyQuery from '@hooks/useCustomLazyQuery'
import { StoreProps } from '@interfaces/StoreState'
import { GET_CASES } from '@queries/cases'
import { GET_FLEX_CONTENT } from '@queries/contentStack'
import { GET_SCHEDULES_ID } from '@queries/index'
import { setPreloadedData } from '@redux/actions/preloadedData'
import { updateAuthorized } from '@redux/actions/session'
import { initHJ } from '@utils/hotjar'
import { identity, pickBy } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const PreloadedRequests: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const [preExecutionDone, setPreExecutionDone] = useState(false)
  const { authorized, locale, profile } = useSelector(({ session, userProfile }: StoreProps) => ({
    authorized: session?.authorized,
    locale: session?.user?.attributes?.locale || 'en',
    profile: new PortalStudentProfile(userProfile.info.StudentProfile?.StudentProfileInfo),
  }))

  const [getNextLesson, { data: nextSession, loading: nextSessionLoading, error: nextSessionError }] =
    useCustomLazyQuery(GET_SCHEDULES_ID)
  const [getMyCalendar, { data: myCalendar, loading: myCalendarLoading, error: myCalendarError }] =
    useCustomLazyQuery(GET_SCHEDULES_ID)
  const [getCases, { data: cases, loading: casesLoading, error: casesError }] = useCustomLazyQuery(GET_CASES)

  const [getHeroBlockTransl, { data: heroBlockTransl, loading: heroBlockTranslLoading, error: heroBlockError }] =
    useCustomLazyQuery(GET_FLEX_CONTENT(locale))

  useEffect(() => {
    // always force recordID to authorized value on student
    dispatch(updateAuthorized(profile.student?.RecordId, 'StudentId'))
    setPreExecutionDone(true)
  }, [])

  useEffect(() => {
    if (preExecutionDone) {
      getCases({
        Id: authorized,
        Type: 'Student',
        UserId: authorized,
      })

      getNextLesson({
        Id: authorized,
        FilterType: 'FILTER_STUDENT_NEXT2_SESSION',
        Limit: 30,
        FromDate: moment.utc().format(),
        EndDate: moment.utc(moment().add(30, 'days')).format(),
      })

      getMyCalendar({
        Id: authorized,
        FromDate: moment.utc().format(),
        EndDate: moment.utc(moment().add(30, 'days')).format(),
        FilterType: 'FILTER_STUDENT_MY_CALENDER',
        Limit: 10,
      })

      getHeroBlockTransl({})
    }
  }, [preExecutionDone])

  useEffect(() => {
    dispatch(
      setPreloadedData(
        pickBy(
          {
            cases,
            nextSession,
            myCalendar,
            heroBlockTransl,
          },
          identity
        )
      )
    )

    if (cases && nextSession) {
      initHJ()
      // @ts-ignore
      window.hj('identify', authorized, {
        casesCreated: !!cases.getCases.length,
        noLessonsBooked: !nextSession.getSchedulesId.length,
      })
    }
  }, [cases, nextSession, myCalendar, heroBlockTransl])

  useEffect(() => {
    dispatch(
      setPreloadedData({
        casesLoading,
        nextSessionLoading,
        myCalendarLoading,
        heroBlockTranslLoading,
      })
    )
  }, [casesLoading, nextSessionLoading, myCalendarLoading, heroBlockTranslLoading])

  useEffect(() => {
    const hasChange = casesError || nextSessionError || myCalendarError || heroBlockError
    if (!hasChange) {
      return
    }
    dispatch(
      setPreloadedData({
        casesError,
        nextSessionError,
        myCalendarError,
        heroBlockError,
      })
    )
  }, [casesError, nextSessionError, myCalendarError, heroBlockError])

  return <>{children}</>
}

export default PreloadedRequests
