import { UPDATE_PERMISSIONS, FETCH_PERMISSIONS } from '@redux/actions/permissions'

const checkNStore = perm => {
  if (!process.browser) {
    return
  }

  let flags = Object.keys(perm.permissions)
    .filter(i => i.indexOf('flag') >= 0)
    .reduce((p, c) => {
      p[c] = perm.permissions[c]
      return p
    }, {})

  let storedFlag = window.localStorage.getItem('stored-ff')
  if (btoa(JSON.stringify(flags)) !== storedFlag) {
    window.localStorage.setItem('stored-ff', btoa(JSON.stringify(flags)))
  }
}

export const initialState = {
  permissions: {},
  isFetching: false,
}

function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PERMISSIONS:
      return Object.assign({}, state, {
        isFetching: false,
        permissions: action.permissions.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      })

    case FETCH_PERMISSIONS:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      })

    default:
      checkNStore(state)
      return state
  }
}

export default permissionsReducer
