export const TIME_FORMAT_COGNITO_GROUPS = {
  Student: [__USER_GROUP_STUDENTS__],
  Instructor: [
    __USER_GROUP_INSTRUCTORS__,
    __USER_GROUP_LCSTAFF__,
    __USER_GROUP_MI__,
    __USER_GROUP_SFSTANDARD__,
    __USER_GROUP_SALESFORCE__,
  ],
}
