import React from 'react'
import PropTypes from 'prop-types'
import { Consumer } from './GroupContext'
import { Input, RadioLabel, RadioCircle, RadioDot } from './style'

const Radio = ({ children, ...rest }) => (
  <Consumer>
    {({ name, value, onChange }) => {
      const optional = {}
      if (value !== undefined) {
        optional.checked = rest.value === value
      }

      if (typeof onChange === 'function') {
        optional.onChange = onChange
      }

      return (
        <>
          <Input
            {...rest}
            id={rest.id || rest.value}
            aria-checked={optional.checked}
            type="radio"
            name={name}
            {...optional}
          />
          <RadioLabel htmlFor={rest.id || rest.value} disabled={rest.disabled} checked={optional.checked}>
            <RadioCircle>
              <RadioDot />
            </RadioCircle>
            {children}
          </RadioLabel>
        </>
      )
    }}
  </Consumer>
)

Radio.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Radio
