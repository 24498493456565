function omitDeepArrayWalk(arr, key) {
  return arr.map(val => {
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key)
    } else if (typeof val === 'object') {
      return omitDeep(val, key)
    }
    return val
  })
}

export default function omitDeep<T>(obj: T, key: string): T {
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) {
        newObj[i] = val
      } else if (Array.isArray(val)) {
        newObj[i] = omitDeepArrayWalk(val, key)
      } else if (typeof val === 'object' && val !== null) {
        newObj[i] = omitDeep(val, key)
      } else {
        newObj[i] = val
      }
    }
  })
  return newObj as T
}
