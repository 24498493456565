import * as React from 'react'

import { ToasterItemProps } from './interface'
import { IconBox, ToasterItemTag } from './style'

const ToasterItem: React.FC<ToasterItemProps> = ({
  id,
  type = 'info',
  icon,
  message,
  position = 'bottomCenter',
  onRemove,
}) => (
  <ToasterItemTag type={type} position={position} onClick={() => onRemove(id)}>
    <>
      {icon && <IconBox>{icon}</IconBox>}
      <span data-e2e="toaster-msg">{message}</span>
    </>
  </ToasterItemTag>
)

export default ToasterItem
