import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import Spacer from '@berlitz/spacer'
import Button from '@berlitz/button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 280px;
  }

  button {
    margin: ${({ theme }) => theme.space.xxs};
  }
`

interface IEmpty {
  onClose: Function
}

const Empty: React.FC<IEmpty> = ({ onClose }) => {
  return (
    <Container>
      <FormattedMessage id="Availability locked. Please contact your learning center for more information." />

      <Spacer size="lg" />

      <img src="/images/locked.svg" alt="Locked" />

      <Spacer size="xxl" />

      <div>
        <Button onClick={() => onClose()}>
          <FormattedMessage id="Cancel" />
        </Button>
        <Button disabled>
          <FormattedMessage id="Save" />
        </Button>
      </div>
    </Container>
  )
}

export default Empty
