import { H3, P } from '@berlitz/globals'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-align: center;
    line-height: 1.56;
  }

  ${P} {
    margin-bottom: 0;
  }

  img {
    width: 100%;
    max-width: 400px;
    margin: ${({ theme }) => `${theme.space.lg} auto`};
  }
`

export const TextWrapper = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
`
