import { UPDATE_LOCALE } from '@redux/actions/intl'
import { DEFAULT_LANGUAGE } from '@utils/constants'

export const initialState = {
  locale: DEFAULT_LANGUAGE,
  messages: {},
}

function intlReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOCALE:
      return Object.assign({}, state, {
        ...action.settings,
      })

    default:
      return state
  }
}

export default intlReducer
