import Clock from '@berlitz/streamline-icons/lib/regular/48-Maps-Navigation/13-Earth/EarthTime'
import { WarningModal } from '@components/Modal'
import WithUserTime, { WithUserTimeProps } from '@hocs/WithUserTime'
import { StoreProps } from '@interfaces/StoreState'
import { DATE_FORMAT, PROFILE_TYPE } from '@utils/constants'
import { REFRESH_INTERVAL } from '@utils/constants/time'
import moment from 'moment'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Content, Details, Label, Time, Timezone, Wrapper } from './style'
import useTimeFormat from '@hooks/useTimeFormat'

export interface ProfileClockInterface extends WithUserTimeProps {
  description?: string
  warningModal?: boolean
  hideChangeLink?: boolean
  onChangeClick?: Function
  timezone?: string
}

const ProfileClock: React.FC<ProfileClockInterface> = ({
  description,
  warningModal,
  getUserDate,
  isProfileTimeSameWithBrowser,
  hideChangeLink,
  onChangeClick,
  timezone,
}) => {
  const { convertToUserTimeFormats } = useTimeFormat()
  const intl = useIntl()
  const userDate = () => (!getUserDate(timezone) ? moment() : getUserDate(timezone))
  const profile = useSelector(({ userProfile }: StoreProps) => userProfile?.info)
  const [open, setOpen] = useState(false)
  const [time, setTime] = useState(userDate().format(DATE_FORMAT.HM))

  const interval = setInterval(() => {
    if (isProfileTimeSameWithBrowser(timezone)) {
      clearInterval(interval)
      return
    }
    setTime(userDate().format(DATE_FORMAT.HM))
  }, REFRESH_INTERVAL)

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (isProfileTimeSameWithBrowser(timezone)) {
    return <div />
  }

  return (
    <Wrapper>
      <Details>
        <Clock />
        <Content>
          <Label>
            <FormattedMessage id="Timezone:" />
          </Label>
          <Timezone>{(timezone ?? profile?.Timezone ?? '').replace(/_/gi, ' ')}</Timezone>
          {!hideChangeLink && (
            <a
              onClick={
                onChangeClick
                  ? () => onChangeClick()
                  : () => {
                      warningModal
                        ? setOpen(true)
                        : Router.push(
                            profile?.Type === PROFILE_TYPE.STUDENT
                              ? '/settings/account'
                              : '/instructors/settings/account'
                          )
                    }
              }
            >
              <FormattedMessage id="Change" />
            </a>
          )}
        </Content>
      </Details>
      <Time>{convertToUserTimeFormats(time, DATE_FORMAT.HM)}</Time>
      {warningModal && (
        <WarningModal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onCancel={() => {
            setOpen(false)
          }}
          title={intl.formatMessage({ id: 'Are you sure you want to leave this page?' })}
          description={intl.formatMessage({
            id: description ? description : 'Your booking results will be lost.',
          })}
          cancelLabel={intl.formatMessage({ id: 'Stay on this page' })}
          proceedLabel={intl.formatMessage({ id: 'Leave this page' })}
          onProceed={() => {
            setOpen(false)
            Router.push(profile?.Type === PROFILE_TYPE.STUDENT ? '/settings/account' : '/instructors/settings/account')
          }}
        />
      )}
    </Wrapper>
  )
}

export default WithUserTime(ProfileClock)
