import { H3, H4, P } from '@berlitz/globals'
import styled, { css } from 'styled-components'

import { TableProps } from './interface'

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    border: none;
    margin-bottom: ${({ theme }) => theme.space.md};

    &:not(:first-child) {
      margin-left: ${({ theme }) => theme.space.xs};
    }
  }
`

export const MaxContent = styled.div`
  width: max-content;
`

export const Table = styled.table<TableProps>`
  width: 100%;
  border-spacing: 0px;
  margin-bottom: ${({ theme }) => theme.space.lg};

  tr {
    td {
      padding: ${({ theme }) => `${theme.space.xxxs} ${theme.space.sm} ${theme.space.xxxs} 0`};
      font-size: ${({ theme }) => theme.fontSize.xxs};
      vertical-align: top;

      ${({ theme, bordered }) =>
        bordered &&
        css`
          border-bottom: 2px solid ${theme.palette.blue20};
        `}

      ${({ theme, padded }) =>
        padded &&
        css`
          padding: ${theme.space.xs} ${theme.space.sm} ${theme.space.xs} 0;
        `}

      &:first-child {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }
  }
`

export const Box = styled.div`
  ${H3} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  ${H4} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.brandPrimary};
  }

  ${P} {
    font-weight: normal;
  }

  li {
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: 0;
    line-height: 1.38;
    letter-spacing: 0.56px;
    font-weight: normal;
  }

  .no-margin {
    margin: 0;
  }
`

export const EPContainer = styled.div`
  height: 226px;
`

export const ViewProfile = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.space.md};
  }
`

export const TextContainer = styled.div`
  word-break: normal;
`
