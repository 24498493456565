import { ICredentials } from '@aws-amplify/core'
import aws4 from 'aws4'
import axios from 'axios'
import { DefinitionNode, DocumentNode } from 'graphql'
import { Auth } from '@aws-amplify/auth'

interface IGQLPayload {
  operationName?: string
  query: string
  variables: any
}

interface IValue {
  value: string
}

interface ICustomDefNode {
  name: IValue
  operation: string
}

type IDefinitionNode = DefinitionNode & ICustomDefNode

const HTTP_METHOD = 'POST'

function generateHeaders(auth: ICredentials, payload: any) {
  let options = {
    path: '/graphql',
    service: 'appsync',
    method: HTTP_METHOD,
    region: process.env.AWS_PROJECT_REGION,
    hostname: process.env.PORTAL_APPSYNC_HOSTNAME,
    body: JSON.stringify(payload),
    headers: { 'Content-Type': '*/*' },
  }

  let sig = aws4.sign(options, {
    accessKeyId: auth.accessKeyId,
    secretAccessKey: auth.secretAccessKey,
    sessionToken: auth.sessionToken,
  })

  return sig.headers
}

async function execute(gqlObj: DocumentNode, variables: any, token?: string) {
  const url = process.env.PORTALS_API_URL
  let auth = await Auth.currentCredentials()
  let opDef = gqlObj?.definitions?.[0] as IDefinitionNode
  let data: IGQLPayload = {
    operationName: opDef?.name?.value,
    query: gqlObj.loc?.source.body.replace('\n  ', '') || '',
    variables,
  }

  if (opDef?.operation !== 'mutation') {
    delete data.operationName
  }
  let headers = generateHeaders(auth, data)
  if (token) {
    headers = {
      Authorization: token,
    }
  }
  return axios({
    method: HTTP_METHOD,
    url,
    data,
    headers,
  })
}

export default execute
