import Spacer from '@berlitz/spacer'
import * as React from 'react'
import { RectLoader } from '../../Loader'

const AboutLoader: React.FC = () => (
  <>
    <RectLoader width="100" height="30" />
    <Spacer size="xxs" />
    <RectLoader width="250" height="25" />
    <RectLoader width="350" height="25" />
    <RectLoader width="300" height="25" />
    <RectLoader width="280" height="25" />
    <RectLoader width="310" height="25" />
  </>
)

export default AboutLoader
