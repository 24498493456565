import { DATE_FORMAT } from '@utils/constants'
import moment from 'moment'

export const getUserTimeFormat = (
  datetime: string,
  format: string,
  isTwelveHour: boolean,
  custom12HourFormat?: string
): string => {
  const m = moment(datetime, format, true)
  const twelveHourFormat =
    (custom12HourFormat === 'default' ? DATE_FORMAT.hmmA : custom12HourFormat) || DATE_FORMAT.hmmA
  const timeFormat = isTwelveHour ? twelveHourFormat : DATE_FORMAT.HM

  if (!m.isValid()) {
    return datetime
  }

  switch (format) {
    case DATE_FORMAT.COMPLETE:
      return `${m.format(DATE_FORMAT.DEFAULT)}, ${m.format(timeFormat)}`

    default:
      return m.format(timeFormat)
  }
}

export const getEventTimeFormat = (
  time: { default: string; start: string; end: string },
  isTimeFormatDisabled,
  convertToUserTimeFormats
) =>
  isTimeFormatDisabled
    ? time.default
    : convertToUserTimeFormats(
        [time.start, time.end],
        [DATE_FORMAT.HMa, DATE_FORMAT.HMa],
        [DATE_FORMAT.hmm, 'default']
      ).join(' - ')

export const BERLITZ_STAFFS = [
  __USER_GROUP_MI__,
  __USER_GROUP_LCSTAFF__,
  __USER_GROUP_SFSTANDARD__,
  __USER_GROUP_SALESFORCE__,
]
