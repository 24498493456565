import * as React from 'react'

interface PageLayoutProps {
  setFormDirtyState: (dirty: boolean) => void
  isFormDirty: boolean
  displayUnsavedModal: (path: string, dynamicPath?: string) => void
}

const { Provider, Consumer } = React.createContext<PageLayoutProps>({
  isFormDirty: false,
  displayUnsavedModal: (path: string, dynamicPath?: string) => ({ path, dynamicPath }),
  setFormDirtyState: (dirty: boolean) => dirty,
})

export { Provider, Consumer }
