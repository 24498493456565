import Button from '@berlitz/button'
import { H3, H4 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Spacer from '@berlitz/spacer'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import EmptyPlaceholder from '../../../components/EmptyPlaceholder'
import { GroupProfileModalProps } from './interface'
import Loader from './Loader'
import QuerySingleProfile from '../SingleProfileModal/QuerySingleProfile'
import QueryGroupProfile from './QueryGroupProfile'
import { ActionButtons, Box, EPContainer, StyledTd, Table, TextContainer } from './style'
import Alert from '@components/Alert'
import RouteLink from '@components/RouteLink'

const GroupProfileModal: React.FC<GroupProfileModalProps> = ({ open, onClose, students, programId, language, id }) => (
  <Modal open={open} onClose={onClose}>
    <QuerySingleProfile StudentId={students?.[0]?.CustomerId} Language={language}>
      {({ loading, data }) => {
        if (loading) {
          return <Loader />
        }

        const MICommentProfile = data?.MIComment

        const programs = data?.ProgramStudentWrappers ?? []
        const program = programs.find((program) => program.ProgramStudentInfo.ProgramId === programId)
        const MICommentProgram = program?.ProgramStudentInfo?.MIComment

        return (
          <QueryGroupProfile id={id}>
            {({ loading, data }) => {
              if (loading) {
                return <Loader />
              }

              const groupInfo = get(data, 'getGroupProfile.StudentGroupInfo') || {}
              const students = groupInfo.StudentInfos

              return (
                <Box>
                  {isEmpty(groupInfo) && (
                    <EPContainer>
                      <EmptyPlaceholder msg="Empty group info." />
                    </EPContainer>
                  )}

                  {!isEmpty(groupInfo) && (
                    <>
                      <H3>{groupInfo.Name}</H3>

                      <H4>
                        <FormattedMessage id="Purpose of study" />
                      </H4>
                      <Table bordered padded>
                        <tbody>
                          {students &&
                            students.map(({ Id, StudentName, Purpose, StudentId }) => (
                              <tr key={Id}>
                                <StyledTd>
                                  <RouteLink key={Id} path={`/student/?id=${StudentId}`}>
                                    {StudentName}
                                  </RouteLink>
                                </StyledTd>
                                <td>{Purpose}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>

                      <Spacer size="lg" />

                      {MICommentProfile && (
                        <Alert alertType="error" dismissable={false}>
                          <TextContainer>{MICommentProfile}</TextContainer>
                        </Alert>
                      )}

                      {MICommentProgram && (
                        <Alert alertType="error" dismissable={false}>
                          <TextContainer>{MICommentProgram}</TextContainer>
                        </Alert>
                      )}
                      {/* <H4>
                        <FormattedMessage id="Students" />
                      </H4>
                      <Students>
                        {students &&
                          students.map(({ Id, StudentName }) => (
                            <Student key={Id}>
                              <Link href="/">
                                <a>
                                  <Profile size="xs" />
                                  {StudentName}
                                </a>
                              </Link>
                            </Student>
                          ))}
                      </Students> */}
                    </>
                  )}

                  <ActionButtons>
                    <Button onClick={onClose}>
                      <FormattedMessage id="Close" />
                    </Button>
                  </ActionButtons>
                </Box>
              )
            }}
          </QueryGroupProfile>
        )
      }}
    </QuerySingleProfile>
  </Modal>
)

export default GroupProfileModal
