import { StoreProps } from '@interfaces/StoreState'
import { avatarXs, PROFILE_TYPE } from '@utils/constants'
import get from 'lodash/get'
import Link from 'next/link'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown'
import RouteLink from '../RouteLink'
import Badge from '../TemporaryComponents/Badge'
import Menu from '../TemporaryComponents/Menu'
import { CircleIconLoader } from './Loader'
import { StyledA, StyledAvatar } from './style'
import PortalAvatar from '@classes/Avatar'

const { Item } = Menu

const menuItems = [
  {
    id: '1',
    path: '/instructors/settings/account',
    label: 'My Account',
    role: 'Instructor',
  },
  {
    id: '2',
    path: '/settings/account',
    label: 'My Account',
    role: 'Student',
  },
  {
    id: '3',
    path: '/settings',
    label: 'Settings',
    role: 'Student',
  },
  {
    id: '4',
    path: '/instructors/settings',
    label: 'Settings',
    role: 'Instructor',
  },
]

const DropdownAvatar = () => {
  const { profile, isFetching, cognitoUserName, authorized, Avatar } = useSelector(
    ({ session, userProfile }: StoreProps) => ({
      profile: userProfile.info,
      isFetching: userProfile.isFetching,
      cognitoUserName: session?.user?.username,
      authorized: session.authorized,
      Avatar: new PortalAvatar(userProfile?.info?.InstructorProfile?.instructorInfo?.Avatar).Avatar,
    })
  )

  if (isFetching && authorized) {
    return <CircleIconLoader height="50px" />
  }

  if (!profile) {
    return null
  }

  const menu = (
    <Menu>
      {menuItems.map((item) =>
        profile.SFId && profile.Type === item.role ? (
          <Item key={item.id}>
            <RouteLink path={item.path} withHoverEffect>
              <FormattedMessage data-e2e={item.label} id={item.label} />
            </RouteLink>
          </Item>
        ) : null
      )}

      {profile.SFId && <Menu.Divider />}

      <Item key="logout">
        <Link href="/logout">
          <FormattedMessage id="Sign Out" />
        </Link>
      </Item>
    </Menu>
  )

  const { FirstName, LastName } =
    profile.Type === PROFILE_TYPE.STUDENT
      ? get(profile, 'StudentProfile.StudentProfileInfo', {})
      : get(profile, 'InstructorProfile.instructorInfo.contactInfo', {})
  const displayName = `${FirstName || cognitoUserName} ${LastName || profile.Type || ''}`

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Badge offset={[-5, 30]} status="success">
        <StyledAvatar size={avatarXs} name={displayName} type="profile" img={Avatar} />
      </Badge>
    </Dropdown>
  )
}

export default DropdownAvatar
