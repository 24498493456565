import { defineMessages } from 'react-intl'

export const getMessages = () =>
  defineMessages({
    title1: {
      id: 'New time format feature',
      defaultMessage: 'New time format feature',
    },
    title2: {
      id: 'You can now set your preferred time format in MyBerlitz.',
      defaultMessage: 'You can now set your preferred time format in MyBerlitz.',
    },
    body: {
      id: 'Choose from a 12 hour or 24 hour clock display across your learning portal, by navigating to My account page.',
      defaultMessage:
        'Choose from a 12 hour or 24 hour clock display across your learning portal, by navigating to My account page.',
    },
    button1: {
      id: 'Keep 24 hour clock',
      defaultMessage: 'Keep 24 hour clock',
    },
    button2: {
      id: 'Change to 12 hour clock',
      defaultMessage: 'Change to 12 hour clock',
    },
    saving: {
      id: 'Updating time format display...',
      defaultMessage: 'Updating time format display...',
    },
    wait: {
      id: 'Please wait a moment.',
      defaultMessage: 'Please wait a moment.',
    },
    saved: {
      id: 'Time format display has been updated',
      defaultMessage: 'Time format display has been updated',
    },
    redirecting: {
      id: 'Redirecting to My account page...',
      defaultMessage: 'Redirect to My account page...',
    },
  })
