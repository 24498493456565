import { UPDATE_DASHBOARD_ACTIVE_MATERIAL, UPDATE_DASHBOARD_ACTIVE_PROGRAM } from '@redux/actions/dashboard'

export const initialState = {
  activeProgram: null,
  activeMaterial: null,
}

function intlReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DASHBOARD_ACTIVE_PROGRAM:
      return Object.assign({}, state, {
        ...state,
        activeProgram: action.activeProgram,
      })
    case UPDATE_DASHBOARD_ACTIVE_MATERIAL:
      return Object.assign({}, state, {
        ...state,
        activeMaterial: action.activeMaterial,
      })
    default:
      return state
  }
}

export default intlReducer
