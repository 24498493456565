import PortalStudentProfile from '@classes/StudentProfile'
import AtRiskModal from '@berlitzplatforms/micro.modal.at-risk'
import { StoreProps } from '@interfaces/StoreState'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import FormattedHTMLMessage from '@components/FormattedHTMLMessage'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { updateDashboardActiveProgram } from '@redux/actions/dashboard'

const AtRiskCheck: React.FC = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { profile, rawProfile } = useSelector(({ userProfile }: StoreProps) => ({
    rawProfile: userProfile.info,
    profile: new PortalStudentProfile(userProfile.info.StudentProfile?.StudentProfileInfo),
  }))

  const [open, setOpen] = useState(Boolean(profile?.AtRiskPrograms.length))
  const showModal = () => Boolean(rawProfile?.StudentProfile) && Boolean(profile?.AtRiskPrograms.length)
  const closeModal = () => setOpen(false)
  const goToScheduling = () => {
    dispatch(updateDashboardActiveProgram(profile.AtRiskProgram?.ProgramId || ''))
    router.push(profile.AtRiskProgram?.hasBulkOnlyFeature() ? '/scheduling/instructors' : '/scheduling')
  }
  useEffect(() => {
    setOpen(showModal())
  }, [])

  const content1 = (
    <>
      {Boolean(profile?.AtRiskPrograms?.length) &&
        profile?.AtRiskPrograms.map((program, index) => (
          <FormattedHTMLMessage
            key={index}
            id="You have {lessonsRemaining} unbooked lessons for {lessonName}"
            defaultMessage="You have {lessonsRemaining} unbooked lessons for {lessonName}"
            values={{
              lessonsRemaining: program?.getLessonsLeft(),
              lessonName: `<span style="color:#004fef; font-weight:500;">${
                profile.Materials[program.LMSMaterialId || '']?.MaterialName
              }</span>`,
            }}
          />
        ))}
    </>
  )
  const content2 = (
    <FormattedMessage
      id="Students who book all of their lessons in advance are more likely to complete their course in time. Book your lessons now!"
      defaultMessage="Students who book all of their lessons in advance are more likely to complete their course in time. Book your lessons now!"
    />
  )

  const title = (
    <FormattedMessage id="Stay on-track, book your lessons now" defaultMessage="Stay on-track, book your lessons now" />
  )

  return (
    <AtRiskModal
      open={open}
      onClose={closeModal}
      title={title}
      content1={content1}
      content2={content2}
      onProceed={goToScheduling}
      button1Label={<FormattedMessage id="Close" defaultMessage="Close" />}
      button2Label={<FormattedMessage id="Book lessons" defaultMessage="Book lessons" />}
    />
  )
}

export default AtRiskCheck
