import styled from 'styled-components'

const alertInfo = {
  error: {
    backgroundColor: 'danger10',
    borderColor: 'danger100',
  },
  info: {
    backgroundColor: 'info10',
    borderColor: 'info100',
  },
  success: {
    backgroundColor: 'success10',
    borderColor: 'success100',
  },
  warning: {
    backgroundColor: 'warning10',
    borderColor: 'warning100',
  },
}

export const Wrapper = styled.div<{ small?: boolean; alertType: string; showIcon: boolean; dismissable: boolean }>`
  border: ${({ theme, small }) => (small ? '1px' : theme.space.xxxs)} solid
    ${({ theme, alertType }) => theme.palette[alertInfo[alertType].borderColor]};
  border-radius: ${({ theme }) => theme.radius.md};
  background-color: ${({ theme, alertType, small }) =>
    small ? theme.palette.powder : theme.palette[alertInfo[alertType].backgroundColor]};
  border: ${({ theme, small }) => (small ? '1px' : theme.space.xxxs)} solid
    ${({ theme, alertType }) => theme.palette[alertInfo[alertType].borderColor]};
  font-size: ${({ theme, small }) => (small ? theme.fontSize.xxs : theme.fontSize.sm)};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  position: relative;
  display: flex;
  align-items: center;

  padding: ${({ theme, showIcon, dismissable, small }) => {
    return small
      ? `${theme.space.xs} ${theme.space.md}`
      : `${theme.space.sm} ${dismissable ? '44px' : '16px'} ${theme.space.sm}
    ${showIcon ? '44px' : '16px'}`
  }};

  /* TODO: - static variables to be added in max-theme-portals later */
  margin-bottom: 20px;

  .alert-icon {
    position: absolute;
    top: ${({ theme }) => theme.space.xs};
    left: ${({ theme }) => theme.space.md};
    overflow: hidden;
  }

  .content {
    word-break: break-word;
  }
`
/* eslint-enable */

export const DismissBtn = styled.button<{ alertType: string }>`
  outline: none;
  border: none;
  position: absolute;
  top: ${({ theme }) => theme.space.sm};
  right: ${({ theme }) => theme.space.md};
  overflow: hidden;
  cursor: pointer;
  padding: 0;
  background-color: ${({ theme, alertType }) => theme.palette[alertInfo[alertType].backgroundColor]};
`
