import FormField from '@berlitz/form-field'
import TimeClockCircle from '@berlitz/streamline-icons/lib/light/01-Interface-Essential/18-Time/TimeClockCircle'
import Calendar1 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import { spaceXxss } from '@utils/constants'
import styled, { css } from 'styled-components'

export const FieldCalendarIcon = styled(Calendar1)`
  position: absolute;
  right: ${spaceXxss};
  top: 10px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.brandSecondaryContrast};
`

export const FieldTimeIcon = styled(TimeClockCircle)`
  position: absolute;
  right: ${spaceXxss};
  top: 10px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.brandSecondaryContrast};
`

export const Field = styled(FormField)`
  label {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
  & > div {
    z-index: auto;
  }
`

const selected = css`
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.brandPrimary};
  min-width: ${({ theme }) => theme.layout.sm};
  box-shadow:
    inset 0 0 0 2px ${({ theme }) => theme.colors.brandPrimary},
    inset 0 0 0 3px white;

  // &.react-datepicker__day--today,
  // &.react-datepicker__month-text--today {
  //   color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  //   &:after {
  //     color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  //   }
  // }
`

const badge = css`
  line-height: 9px;
  font-size: 10px;
  color: white;
`

export const DatePickerWrapper = styled.div<{
  borderless?: boolean
  monthsShown?: number
  hideNavigation?: boolean
  dontBreak?: boolean
}>`
  min-width: 100%;
  display: inline-block;
  position: relative;

  &.popped-over {
    z-index: 20;
  }

  .react-datepicker {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  }

  .react-datepicker__current-month {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: ${({ theme }) => theme.space.xs};
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  input {
    width: 100%;
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  }

  input {
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    padding: ${({ theme }) => `7px ${theme.space.lg} 7px ${spaceXxss}`};
    border: 1px solid ${({ theme }) => theme.palette.gray100};
    border-radius: ${({ theme }) => theme.radius.sm};
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    }
  }

  .react-datepicker__header {
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.palette.gray40};
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: ${({ theme }) => theme.space.xs};
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-name {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.xxxs};
  }

  .react-datepicker__navigation--next {
    border-left-color: ${({ theme }) => theme.colors.brandPrimary};
    right: 0;
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${({ theme }) => theme.colors.brandPrimary};
    left: 0;
  }

  .previous-date {
    background-color: ${({ theme }) => theme.colors.ui02};
  }

  div[class*=' react-datepicker__day--highlighted-count'],
  div[class^='react-datepicker__day--highlighted-count'] {
    background: ${({ theme }) => theme.palette.blueGray60};
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    &.react-datepicker__day--keyboard-selected,
    &.react-datepicker__month-text--keyboard-selected,
    &.react-datepicker__day--in-range,
    &.react-datepicker__day--selected {
      ${selected}
    }
    position: relative;
    &:after {
      font-size: ${({ theme }) => theme.fontSize.xxxs};
      background: ${({ theme }) => theme.palette.red100};
      content: '..';
      ${badge}
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      background: ${({ theme }) => theme.palette.red100};
      height: ${({ theme }) => theme.space.md};
      width: ${({ theme }) => theme.space.md};
      border-radius: 50%;
      padding: 3px;
      line-height: 4px;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
    &.react-datepicker__day--highlighted-count-1:after {
      content: '1';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-2:after {
      content: '2';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-3:after {
      content: '3';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-4:after {
      content: '4';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-5:after {
      content: '5';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-6:after {
      content: '6';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-7:after {
      content: '7';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-8:after {
      content: '8';
      ${badge}
    }

    &.react-datepicker__day--highlighted-count-9:after {
      content: '9';
      ${badge}
    }
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__day--selected {
    ${selected}
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    min-width: ${({ theme }) => theme.space.xxl};
    line-height: 42px;
    color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    margin: 0;
  }

  .react-datepicker__day {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    &:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):not(
        .react-datepicker__day--keyboard-selected
      ):hover {
      border-radius: 0;
      background-color: ${({ theme }) => theme.palette.blue50};
      color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    }
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.palette.blueGray80};
    font-weight: normal;
  }

  .react-datepicker__day--disabled {
    background: ${({ theme }) => theme.colors.ui02};
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  }

  .react-datepicker__close-icon {
    right: 26px;
    &:after {
      background-color: ${({ theme }) => theme.palette.blueGray50};
    }
  }

  .react-datepicker__time-container,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: ${({ theme }) => theme.layout.xl};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: auto;
    padding: ${({ theme }) => theme.space.xs};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    &:hover {
      background-color: ${({ theme }) => theme.colors.brandPrimary};
      color: ${({ theme }) => theme.colors.brandPrimaryContrast};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary};
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  }

  // .react-datepicker__day--today,
  // .react-datepicker__month-text--today {
  //   position: relative;
  //   color: ${({ theme }) => theme.colors.brandPrimary};
  //   &:after {
  //     content: '.';
  //     display: block;
  //     position: absolute;
  //     bottom: -${({ theme }) => theme.space.xxs};
  //     width: 100%;
  //     font-size: ${({ theme }) => theme.fontSize.lg};
  //     color: ${({ theme }) => theme.colors.brandPrimary};
  //   }
  //
  // }

  .react-datepicker__day--highlighted-type-available {
    background-color: ${({ theme }) => theme.colors.brandSecondary};
  }

  .react-datepicker__day--highlighted-type-unavailable {
    background-color: ${({ theme }) => theme.palette.blueGray10};
  }

  .react-datepicker__day--highlighted-type-suggested {
    background-color: ${({ theme }) => theme.palette.warning100};
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  }

  .react-datepicker__day--highlighted-type-available-suggested {
    position: relative;
    z-index: 1;
    &:after {
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        -225deg,
        ${({ theme }) => theme.colors.brandSecondary} calc(100% - 29px),
        ${({ theme }) => theme.palette.warning100} calc(100% - 40px)
      );
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
  }

  ${({ theme, borderless }) =>
    borderless &&
    css`
      .react-datepicker__navigation--previous {
        left: ${theme.space.sm};
      }
      .react-datepicker__navigation--next {
        right: ${theme.space.sm};
      }
      .react-datepicker {
        width: 100%;
        border: 0;
        border-radius: 0;
      }
      .react-datepicker__month-container {
        width: 100%;
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 14.28%;
        margin-left: 0;
        margin-right: 0;
      }
    `}

  ${({ theme, monthsShown = 0 }) =>
    monthsShown > 1 &&
    css`
      .react-datepicker {
        border: 0;
        width: 100%;
        background: transparent;
      }
      .react-datepicker__month-container {
        background: ${theme.colors.brandPrimaryContrast};
        margin: 0 ${theme.space.sm};
        border-radius: ${theme.radius.md};
        border: 1px solid ${theme.palette.gray100};
      }
    `}

  ${({ monthsShown = 0, dontBreak }) =>
    dontBreak &&
    css`
      overflow-x: scroll;
      width: 100%;
      .react-datepicker {
        min-width: ${306 * monthsShown}px;
      }
    `}

  ${({ hideNavigation }) =>
    hideNavigation &&
    css`
      .react-datepicker__navigation--previous,
      .react-datepicker__navigation--next {
        display: none;
      }
    `}
`
