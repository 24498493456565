export const initHJ = () =>
  // @ts-ignore
  (window.hj =
    // @ts-ignore
    window.hj ||
    // tslint:disable-next-line: only-arrow-functions
    function() {
      // @ts-ignore
      const temp = (hj.q = hj.q || [])
      temp.push(arguments)
    })
