import PortalStudentProfile from '@classes/StudentProfile'
import AtRiskCheck from '@components/AtRiskCheck'
import PortalTimeFormatModal from '@components/Modal/PortalTimeFormatModal'
import TimezoneCheck from '@components/TimezoneCheck'
import useTimeFormat from '@hooks/useTimeFormat'
import { StoreProps } from '@interfaces/StoreState'
import React from 'react'
import { useSelector } from 'react-redux'

const GlobalModalCheck: React.FC = () => {
  const { isTimeFormatDisabled } = useTimeFormat()
  const { profile, profileInfo } = useSelector(({ userProfile }: StoreProps) => ({
    profileInfo: userProfile.info,
    profile: new PortalStudentProfile(userProfile.info.StudentProfile?.StudentProfileInfo),
  }))

  if (
    !isTimeFormatDisabled &&
    ((profileInfo.StudentProfile && !profileInfo.StudentProfile?.StudentProfileInfo?.PortalTimeformat) ||
      (profileInfo.InstructorProfile && !profileInfo.InstructorProfile?.instructorInfo?.PortalTimeformat))
  ) {
    return <PortalTimeFormatModal />
  } else if (profileInfo.StudentProfile && profile.AtRiskProgram) {
    return <AtRiskCheck />
  } else {
    return <TimezoneCheck />
  }
}

export default GlobalModalCheck
