import { RESET_PRELOADED_DATA, UPDATE_PRELOADED_DATA } from '@redux/actions/preloadedData'

export const initialState = {}

function preloadedDataReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRELOADED_DATA:
      return Object.assign({}, state, {
        ...action.data,
      })
    case RESET_PRELOADED_DATA:
      return {}
    default:
      return state
  }
}

export default preloadedDataReducer
