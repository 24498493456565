export const RELATIVE_TIME = {
  future: 'in %s',
  past: '%s ago',
  s: 'a few seconds',
  ss: '%d seconds',
  m: 'a minute',
  mm: '%d minutes',
  h: '1h',
  hh: '%dhrs',
  d: 'a day',
  dd: '%d days',
  M: 'a month',
  MM: '%d months',
  y: 'a year',
  yy: '%d years',
}

export const REFRESH_INTERVAL = 30000
export const PORTAL_12_HOUR_TIME_FORMAT = '12 hour clock'
export const PORTAL_24_HOUR_TIME_FORMAT = '24 hour clock'
export const PORTAL_TIME_FORMATS = [PORTAL_12_HOUR_TIME_FORMAT, PORTAL_24_HOUR_TIME_FORMAT]
