import { UserProfileProps } from '@interfaces/UserProfile'

const FEATURES = {
  NewLearningExperience: 'New Learning Experience',
}

class PortalFeatures {
  features: string[]

  constructor(features: string[]) {
    this.features = features
  }

  hasNewLearningExperience() {
    return this.features.some(feature => FEATURES.NewLearningExperience === feature)
  }
}

let instance
export const PortalFeaturesFactory = (profile: UserProfileProps) => {
  if (!instance) {
    instance = new PortalFeatures(profile?.StudentProfile.StudentProfileInfo?.PortalFeaturesEnabled ?? [])
  }

  return instance
}

export default PortalFeatures
