class PortalAvatar {
  avatar?: string

  constructor(avatar?: string) {
    this.avatar = avatar || ''
  }

  get Avatar() {
    return (
      this.avatar
        ?.replace('https://www.tinygraphs.com/', 'https://tinygraphs.berlitz-platforms.io/')
        ?.replace('https://tinygraphs.com/', 'https://tinygraphs.berlitz-platforms.io/')
        ?.replace('http://tinygraphs.com/', 'https://tinygraphs.berlitz-platforms.io/') || ''
    )
  }

  get IsTinyGraphs() {
    return this.Avatar?.includes('tinygraphs')
  }
}

export default PortalAvatar
