import Checkbox from '@berlitz/checkbox'
import * as React from 'react'

interface MyFormValues {
  name: string
}

type CheckboxProps = React.ComponentProps<typeof Checkbox>

interface FieldCheckboxProps extends CheckboxProps {
  field: MyFormValues
}

const CustomCheckbox: React.FunctionComponent<FieldCheckboxProps> = (props) => (
  <Checkbox name={props.field.name} {...props} />
)

export default CustomCheckbox
