import { Auth } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'
import { Storage, StorageAccessLevel } from '@aws-amplify/storage'
import { userLogout } from '@redux/actions/session'
import map from 'lodash/map'
import axios, { AxiosRequestConfig } from 'axios'
import S3 from 'aws-sdk/clients/s3'
import { SESSIONS_URL, SSO_URL, FILEPROXY_URL, VOTIRO_URL } from './api-url'
import { PORTALS_VAR } from './constants'

export async function signOutUser() {
  try {
    /**
     * Lets move authUser here, in that way we are catching it if it fails
     */
    const authUser = await Auth.currentAuthenticatedUser()
    const sessionBearer = `Bearer ${authUser?.signInUserSession?.idToken?.jwtToken}`
    await fetch(`${SESSIONS_URL}/logout${process.env.ENV_NAME === 'staging' ? '?env=sit' : ''}`, {
      headers: { Authorization: sessionBearer },
    })
  } catch (e) {
    // do nothing but lets just catch so user can logout normally
  }

  userLogout()
  localStorage.removeItem(PORTALS_VAR.LOCAL_SFID)
  await Auth.signOut()
  // stored data that we won't clear
  /**
   * Not sure why we are keeping info after the user logs out
   * Disabling this line for now
   */
  // const portalSelectedMaterial = localStorage.getItem('portal-selected-material') || '{}'
  localStorage.clear()

  /**
   * Not sure why we are keeping info after the user logs out
   * Disabling this line for now
   */
  // localStorage.setItem('portal-selected-material', portalSelectedMaterial)
  window.location.href = `${SSO_URL}signout`
  return null
}

export async function uploadFile(files, level: StorageAccessLevel = 'protected') {
  const uuidv1 = require('uuid/v1')

  const pFiles = map(files, (file) => {
    const fileName = file.name.replace(/ /g, '_')
    return Storage.put(`${uuidv1()}-${fileName}`, file, {
      contentType: file.type,
      level,
    })
      .then((res) => ({
        name: fileName,
        // @ts-ignore
        path: res.key,
      }))
      .catch((err) => {
        __DEV__ && console.log('err: %o', err)
      })
  })

  return Promise.all(pFiles)
}

export const formatFileName = (fileName: string) => {
  const currentTime = new Date().getTime()
  const newName = `${currentTime}_${fileName.replace(/[^a-zA-Z0-9-_.]/g, '')}`
  console.log('fileName _ ', newName)
  return newName
}

export async function uploadFileProxy(files) {
  //const uuidv1 = require('uuid/v1')
  const pFiles = map(files, (file) => {
    const fileName = file.name.replace(/ /g, '_')
    //const uploadedFileName = `${uuidv1()}-${fileName}`
    const uploadedFileName = formatFileName(file.name)

    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${FILEPROXY_URL}/${process.env.AWS_S3_BUCKET}/${uploadedFileName}?content-type=${file.type}`,
      data: file,
    }

    return axios(config)
      .then(() => ({
        name: fileName,
        path: uploadedFileName,
      }))
      .catch((err) => {
        __DEV__ && console.log('err: %o', err)
      })
  })

  return Promise.all(pFiles)
}

export async function votiroFileUpload(files) {
  const pFiles = map(files, (file) => {
    const fileName = file.name.replace(/ /g, '_')

    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${VOTIRO_URL}/votiroFileupload?filename=${fileName}&contentType=${file.type}`,
      data: file,
      headers: {
        Accept: 'application/json',
      },
    }

    return axios(config)
      .then((response) => {
        return {
          name: fileName,
          id: response.data.id,
          path: response.data.url,
          type: file.type,
          report: response.data.report,
        }
      })
      .catch((err) => {
        __DEV__ && console.log('err: %o', err)
      })
  })

  return Promise.all(pFiles)
}

export async function votiroFileDownload(file) {
  window.open(`${VOTIRO_URL}/votiroFileupload/${file.id}?filename=${file.name}&contentType=${file.type}`)
}

export async function votiroFileReport(id) {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: `${VOTIRO_URL}/votiroFileupload/${id}/report`,
    headers: {
      Accept: 'application/json',
    },
  }

  return axios(config)
    .then((response) => response.data)
    .catch((err) => {
      __DEV__ && console.log('err: %o', err)
    })
}

export function getFileProxyBaseUrl(url) {
  return `${FILEPROXY_URL}/${process.env.AWS_S3_BUCKET}/${url}`
}

export async function getFileBaseUrl(url, level: StorageAccessLevel = 'protected') {
  const fileUrl = await Storage.get(url, { level })
  return typeof fileUrl === 'string' ? fileUrl.split('?')[0] : fileUrl
}

export async function removeFile(name) {
  return Storage.remove(name)
}

export async function getFile(name, level: StorageAccessLevel = 'protected') {
  return await Storage.get(name, { level })
}

export const getS3Details = (url: string) => {
  const splitted = decodeURIComponent(url).split('/')
  const length = splitted?.length ?? 0
  if (length > 2) {
    return {
      key: splitted[length - 1],
      identityId: splitted[length - 2],
    }
  }
  return {
    key: url,
    identityId: undefined,
  }
}

export async function getProtectedFile(url, isSystem) {
  const { key, identityId } = getS3Details(url)
  if (url?.includes('/public/') || url?.includes('/proctected')) {
    return await Storage.get(key, { level: isSystem ? 'public' : 'protected', download: false, identityId })
  } else {
    return await getSignedURL(key)
  }
}

export async function getSignedURL(key) {
  const credentials = await Auth.currentUserCredentials()

  const s3 = new S3({
    region: process.env.AWS_S3_REGION,
    credentials: credentials,
  })

  const params = {
    Bucket: process.env.AWS_S3_BUCKET,
    Key: key,
  }

  return await s3.getSignedUrlPromise('getObject', params)
}

export const configureAmplify = (state: string | string[] | null) => {
  let qs = ''
  if (state) {
    qs += `?${state}`
  }

  const config = {
    Auth: {
      identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
      region: process.env.AWS_PROJECT_REGION, // REQUIRED - Amazon Cognito Region
      userPoolId: process.env.AWS_USER_POOLS_ID, // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: process.env.AWS_USER_POOLS_WEB_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID
      oauth: {
        domain: process.env.OAUTH_DOMAIN,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: typeof window !== 'undefined' ? `${window.location.origin}${qs}` : `http://localhost:3000${qs}`,
        redirectSignOut: '/',
        responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    Storage: {
      AWSS3: {
        bucket: process.env.AWS_S3_BUCKET, // REQUIRED -  Amazon S3 bucket
        region: process.env.AWS_S3_REGION, // OPTIONAL -  Amazon service region
      },
    },
  }
  Amplify.configure(config)
  Auth.configure(config.Auth)
  Storage.configure(config.Storage)
}
