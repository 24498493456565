import { Auth } from '@aws-amplify/auth'
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider'
import {
  ICognitoSearch,
  ICognitoState,
  UserCognito,
  CreateUserCognito,
  CreateUserCognitoInput,
} from 'interfaces/UserCognito'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

let cispInstance: CognitoIdentityServiceProvider | undefined

export const configureAWSCognito = async () => {
  if (!cispInstance) {
    cispInstance = new CognitoIdentityServiceProvider({
      apiVersion: '2016-04-18',
      region: process.env.AWS_PROJECT_REGION,
      credentials: await Auth.currentCredentials(),
    })
  }
  return cispInstance
}

const mapToCognitoState = (response: any) =>
  map((response || {}).Users, user => {
    const attrs = get(user, 'Attributes', [])
    const nu: any = reduce(
      attrs,
      (accumulator, attr) => {
        accumulator = {
          ...accumulator,
          [attr.Name]: attr.Value,
        }

        return accumulator
      },
      {}
    )

    return {
      username: user.Username,
      name: nu.name || nu.given_name || user.Username,
      email: nu.email,
      customerId: nu['custom:customerId'],
      sfEnv: nu['custom:sfEnv'],
      status: user.UserStatus,
      enabled: user.Enabled,
      timezone: nu.zoneinfo,
    } as UserCognito
  })

export const listUsersInGroup = async (limit: number, group: string, pagination?: string) => {
  let cognito = {
    users: [] as UserCognito[],
    next: '',
    prev: '',
  } as ICognitoState
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Limit: limit,
      GroupName: group,
    }

    if (pagination) {
      params.NextToken = pagination
    }

    const response = await cisp.listUsersInGroup(params).promise()
    cognito.users = mapToCognitoState(response)
    cognito.next = response.NextToken || ''
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }

  return cognito
}

export const adminDisableUser = async (user: UserCognito) => {
  let ret = false
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Username: user.username,
    }

    await cisp.adminDisableUser(params).promise()
    ret = true
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }

  return ret
}

export const adminEnableUser = async (user: UserCognito) => {
  let ret = false
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Username: user.username,
    }

    await cisp.adminEnableUser(params).promise()
    ret = true
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }

  return ret
}

export const listUsers = async (search: ICognitoSearch, limit: number, pagination?: string) => {
  let cognito = {
    users: [] as UserCognito[],
    next: '',
    prev: '',
  } as ICognitoState
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Filter: `${search.attribute} ^= \'${search.searchKey}\'`,
      Limit: limit,
    }

    if (pagination) {
      params.PaginationToken = pagination
    }

    const response = await cisp.listUsers(params).promise()
    cognito.users = mapToCognitoState(response)
    cognito.next = response.PaginationToken || ''
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }

  return cognito
}

export const adminListGroupForUser = async (user: UserCognito) => {
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Username: user.username,
    }

    const response = await cisp.adminListGroupsForUser(params).promise()
    const attrs = get(response, 'Groups', [])
    const group: CognitoIdentityServiceProvider.GroupType = reduce(
      attrs,
      (accumulator, group) => {
        if (isEmpty(accumulator)) {
          accumulator = group
        }

        if (get(group, 'Precedence', 0) > get(accumulator, 'Precedence', 0)) {
          accumulator = group
        }

        return accumulator
      },
      {} as CognitoIdentityServiceProvider.GroupType
    )
    return group
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }
}

export const adminCreateUser = async (user: UserCognito) => {
  let ret = false
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Username: user.username,
      MessageAction: 'RESEND',
    }

    await cisp.adminCreateUser(params).promise()
    ret = true
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }
  return ret
}

export const adminSetUserPassword = async (user: UserCognito, password: string) => {
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
      Username: user.username,
      Password: password,
      Permanent: false,
    }

    return cisp.adminSetUserPassword(params).promise()
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }
}

export const adminCreateUserV2 = async (user: CreateUserCognito) => {
  let ret = false
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      TemporaryPassword: `${process.env.TEMP_ADMIN_PASSWORD}`,
      UserAttributes: [
        {
          Name: 'custom:customerId',
          Value: 'integrationTeacherTest',
        },
        {
          Name: 'email',
          Value: user.email,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
      Username: user.username,
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
    }

    await cisp.adminCreateUser(params).promise()
    ret = true
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }
  return ret
}

export const adminAddUserToGroup = async (user: CreateUserCognitoInput) => {
  let ret = false
  const cisp = await configureAWSCognito()
  try {
    const params: any = {
      GroupName: user.groupName,
      Username: user.username,
      UserPoolId: `${process.env.AWS_USER_POOLS_ID}`,
    }

    await cisp.adminAddUserToGroup(params).promise()
    ret = true
  } catch (err) {
    __DEV__ && console.log('err: %o', err)
  }
  return ret
}
