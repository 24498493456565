import Button from '@berlitz/button'
import styled, { css } from 'styled-components'

export const StyledButton = styled(Button)`
  border: none;
  margin-right: ${({ theme }) => theme.space.sm};

  &:last-child {
    margin: 0;
  }
`

export const ModalDescription = styled.div`
  margin-bottom: ${({ theme }) => theme.space.xxxl};
  font-size: ${({ theme }) => theme.fontSize.sm};
`

interface WrapperProps {
  withHeader: boolean
  isActivityPage?: boolean
  hasSidebar?: boolean
  isAdmin?: boolean
}

export const Wrapper = styled.main`
  height: 100%;
  position: relative;
  ${({ withHeader, isActivityPage, isAdmin }: WrapperProps) => {
    if (!withHeader) {
      return css`
        min-height: ${isActivityPage ? 'max-content' : '100vh'};
      `
    }
    return css`
      background: #f4f8ff;
      min-height: calc(100vh - ${isActivityPage ? '116px' : isAdmin ? '118px' : '76px'});
      margin-top: ${({ theme }) => theme.space.xxs};
    `
  }};
`

export const Container = styled.div`
  ${({ withHeader, hasSidebar, isActivityPage = false }: WrapperProps) => {
    if (isActivityPage) {
      return css`
        padding: 0;
      `
    }

    if (withHeader) {
      return css`
        padding: ${({ theme }) => theme.space.sm};

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          padding: ${({ theme }) =>
            `${theme.space.lg} ${theme.space.xl} 0 ${hasSidebar ? theme.layout.xl : theme.space.xl}`};
        }
      `
    }
  }};
`

export const AdminFooter = styled.div`
  background: ${({ theme }) => theme.colors.brandSupporting04};
  right: 0;
  left: 0;
  position: fixed;
  top: 0;
  text-align: center;
  padding: ${({ theme }) => theme.space.sm};
  span {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  z-index: 1000;
`
