import gql from 'graphql-tag'

export const GET_CASES = gql`
  query GetCases($Id: String!, $Type: String!, $UserId: String!) {
    getCases(Id: $Id, Type: $Type, UserId: $UserId) {
      FileInfos {
        Title
        Name
        FileUrl
        Creator
        CreatorId
        CreatedDatetime
        RecordId
      }
      CaseInfo {
        Type
        SubType
        StudentId
        Status
        Id
        CaseNumber
        Subject
        Description
        CreatorId
        Creator
        CreatedDatetime
        RegistrationId
        LastModifieDatetime
        caseCommentInfoList {
          Id
          Creator
          CreatorId
          CreatedDatetime
          CommentBody
        }
      }
    }
  }
`
