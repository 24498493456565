import { InstructorInfo } from '@interfaces/Instructor'
import { ProgramStudentInfo } from '@interfaces/Student'
import { UserProfileProps } from '@interfaces/UserProfile'

export const FETCH_PROFILE = 'userProfile/FETCH_STARTED'
export const FETCH_PROFILE_ERROR = 'userProfile/FETCH_ERROR'
export const REFETCH_PROFILE = 'userProfile/REFETCH_PROFILE'
export const FETCH_PROFILE_SUCCESS = 'userProfile/FETCH_SUCCESS'
export const UPDATE_STUDENT_PROGRAM = 'userProfile/UPDATE_STUDENT_PROGRAM'
export const UPDATE_INSTRUCTOR_INFO = 'userProfile/UPDATE_INSTRUCTOR_INFO'
export const RESET_LAST_ACTIVE = 'userProfile/RESET_LAST_ACTIVE'

export const refetchProfile = () => ({
  type: REFETCH_PROFILE,
})

export const fetchProfile = () => ({
  type: FETCH_PROFILE,
})

export const fetchProfileSuccess = (profile: UserProfileProps) => {
  // force SFID value to studentSFID or if sutdent recordId is null then it is instructor
  // safe to use SFID from profile
  const forcedSFId = profile.StudentProfile?.StudentProfileInfo?.RecordId || profile.SFId
  return {
    type: FETCH_PROFILE_SUCCESS,
    profile: { ...profile, SFId: forcedSFId } as UserProfileProps,
  }
}

export const fetchProfileError = () => ({
  type: FETCH_PROFILE_ERROR,
})

export const updateStudentProgram = (ProgramInfo: ProgramStudentInfo) => ({
  type: UPDATE_STUDENT_PROGRAM,
  ProgramInfo,
})

export const updateInstructorInfo = (info: InstructorInfo) => ({
  type: UPDATE_INSTRUCTOR_INFO,
  info,
})

export const resetLastActive = (resetLastActive: boolean) => ({
  type: RESET_LAST_ACTIVE,
  resetLastActive,
})
