import Spacer from '@berlitz/spacer'
import { Wrapper } from '@components/Error/style'
import { SessionProps } from '@interfaces/StoreState'
import * as React from 'react'

interface Prop extends SessionProps {
  statusCode: number
}

const Error: React.FC<Prop> = ({ statusCode }) => {
  const heading = {
    400: 'Bad request',
    404: 'Page not found',
    403: 'We are sorry but you do not have access to this page or resource',
  }

  return (
    <Wrapper isAdmin={false} hasUser={true}>
      <div>
        <h1>{statusCode}</h1>
        <p>{heading[statusCode] || heading['404']}</p>
        <Spacer size="xl" />
      </div>

      <object type="image/svg+xml" data="/images/404.svg" width="100%" style={{ maxWidth: '500px' }} />
    </Wrapper>
  )
}

export default Error
