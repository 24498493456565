import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { ITimeRange, TEventResource } from '@components/InstructorScheduler/interface'
import { TypeOptions as TYPE_OPTIONS } from '@components/Modal/AvailabilityModal/constants'
import PortalInstructor from './Instructor'
import {
  AVAILABILITY_OPTION,
  AVAILABILITY_TYPE,
  AVAILABILITY_TYPE_OPTIONS,
  CONTRACT_TYPE,
  LEARNING_CENTER,
  LESSON_TYPE,
  LESSON_TYPE_OPTIONS,
  REPEAT_OPTIONS,
} from '@utils/constants/availability'
import { isEmpty } from 'lodash'

const moment = extendMoment(Moment)

moment.locale('en', {
  week: {
    dow: 1,
    doy: 1,
  },
})

type TResource = TEventResource | null

class PoratlAvailabilityForm {
  resource: TResource
  Instructor: PortalInstructor
  timeRange?: ITimeRange
  isTimeOff?: boolean
  isAdmin?: boolean

  constructor(
    resource: TResource,
    Instructor: PortalInstructor,
    timeRange: ITimeRange,
    isTimeOff: boolean = false,
    isAdmin: boolean = false
  ) {
    this.resource = resource || null
    this.Instructor = Instructor
    this.timeRange = timeRange
    this.isTimeOff = isTimeOff
    this.isAdmin = isAdmin
  }

  get ReadOnly() {
    return (!this.isAdmin && this.resource?.IsCore) || (this.resource?.IsAvailability && !this.CanModifyFlex)
  }

  get CanModifyFlex() {
    const isLiveOnlineInstructor = this.Instructor.PrimaryLearningCenter?.Name.toLowerCase().includes(
      LEARNING_CENTER.LIVE_ONLINE_VIRTUAL_CENTER.toLowerCase()
    )
    const isCnG = this.Instructor.ContractType?.toLowerCase().includes(
      CONTRACT_TYPE.CONTRACTED_NOT_GUARANTEED.toLowerCase()
    )
    const isFlexEvent =
      (this.resource?.Availability?.Type || '')?.toLowerCase() === LESSON_TYPE.FLEX.toLowerCase() ||
      isEmpty(this.resource)
    const option7 = !this.isAdmin && isCnG && isFlexEvent && isLiveOnlineInstructor
    return !option7
  }

  get HideAvailabilityForm() {
    const ContractType = this.Instructor.ContractType?.toLowerCase()
    const AvailabilityType = this.Instructor.AvailabilityType.toLowerCase()
    const AvailabilityOption = this.Instructor.AvailabilityOption.toLowerCase()
    const type = AvailabilityType.split(';').map(i => i.toLowerCase())
    const option = AvailabilityOption.split(';').map(i => i.toLowerCase())
    const Name = (this.Instructor.PrimaryLearningCenter?.Name || '').toLowerCase()

    const isCG = ContractType === CONTRACT_TYPE.CONTRACTED_GUARANTEED.toLowerCase()
    const isCore = type.includes(LESSON_TYPE.CORE.toLowerCase())
    const isAvailableGuaranteedF2F = option.includes(AVAILABILITY_OPTION.AVAILABLE_GUARANTEED_F2F.toLowerCase())
    const isAvailableGuaranteedOnline = option.includes(AVAILABILITY_OPTION.AVAILABLE_GUARANTEED_ONLINE.toLowerCase())

    const isLiveOnlineVC = Name === LEARNING_CENTER.LIVE_ONLINE_VIRTUAL_CENTER.toLowerCase()

    // 1 Flex
    const Scenario1 = isCG && !isCore && isAvailableGuaranteedF2F

    // 2 Flex
    const Scenario2 = isCG && !isCore && isAvailableGuaranteedOnline

    // 1 Core
    const Scenario3 = isCG && isCore && isAvailableGuaranteedF2F && !this.isAdmin

    // 2 Core
    const Scenario4 = isCG && isCore && isAvailableGuaranteedOnline && isLiveOnlineVC && !this.isAdmin

    return Scenario1 || Scenario2 || Scenario3 || Scenario4 || (!this.CanModifyFlex && isEmpty(this.resource))
  }

  get HideF2FOrFlexOption() {
    const ContractType = this.Instructor.ContractType.toLowerCase()
    const AvailabilityType = this.Instructor.AvailabilityType.toLowerCase()
    const AvailabilityOption = this.Instructor.AvailabilityOption.toLowerCase()
    const type = AvailabilityType.split(';').map(i => i.toLowerCase())
    const option = AvailabilityOption.split(';').map(i => i.toLowerCase())
    const Name = (this.Instructor.PrimaryLearningCenter?.Name || '').toLowerCase()

    const isCG = ContractType === CONTRACT_TYPE.CONTRACTED_GUARANTEED.toLowerCase()
    const isCnotG = ContractType === CONTRACT_TYPE.CONTRACTED_NOT_GUARANTEED.toLowerCase()
    const isFreelance = ContractType === CONTRACT_TYPE.FREELANCE.toLowerCase()
    const isCore = type.includes(LESSON_TYPE.CORE.toLowerCase())
    const isTentativeOnline = option.includes(AVAILABILITY_OPTION.TENTATIVE_ONLINE.toLowerCase())
    const isTentativeF2F = option.includes(AVAILABILITY_OPTION.TENTATIVE_F2F.toLowerCase())

    const isAvailableGuaranteedF2F = option.includes(AVAILABILITY_OPTION.AVAILABLE_GUARANTEED_F2F.toLowerCase())
    const isAvailableContractedF2F = option.includes(AVAILABILITY_OPTION.AVAILABLE_CONTRACTED_F2F.toLowerCase())
    const isAvailableContractedOnline = option.includes(AVAILABILITY_OPTION.AVAILABLE_CONTRACTED_ONLINE.toLowerCase())
    const isLiveOnlineVC = Name === LEARNING_CENTER.LIVE_ONLINE_VIRTUAL_CENTER.toLowerCase()

    // 3 Flex
    const Scenario1 = isCnotG && !isCore && isAvailableContractedOnline && isLiveOnlineVC

    // 4 Flex
    const Scenario2 = isCnotG && !isCore && isAvailableContractedF2F

    // 5 Flex
    const Scenario3 = isFreelance && !isCore && isTentativeOnline && isLiveOnlineVC

    // 6 Flex
    const Scenario4 = isFreelance && !isCore && isTentativeF2F

    // 1 Core
    const Scenario5 = isCG && isCore && isAvailableGuaranteedF2F && isLiveOnlineVC && !isFreelance

    return Scenario1 || Scenario2 || Scenario3 || Scenario4 || Scenario5
  }

  get LessonTypeOptions() {
    return (this.HideF2FOrFlexOption && this.Instructor.IsPrimaryLCLiveOnline) || this.Instructor.IsPrimaryLCLiveOnline
      ? AVAILABILITY_TYPE_OPTIONS.filter(({ id }) => id === AVAILABILITY_TYPE.ONLINE.fullName)
      : this.Instructor.IsFreelance
      ? AVAILABILITY_TYPE_OPTIONS.filter(({ id }) => id === AVAILABILITY_TYPE.ONLINE.fullName)
      : AVAILABILITY_TYPE_OPTIONS
  }

  get TypeOptions() {
    return this.HideF2FOrFlexOption && !this.isAdmin && this.Instructor.IsPrimaryLCLiveOnline
      ? LESSON_TYPE_OPTIONS.filter(({ id }) => id.toLowerCase() !== LESSON_TYPE.FLEX.toLowerCase())
      : this.Instructor.IsFreelance
      ? LESSON_TYPE_OPTIONS.filter(({ id }) => id.toLowerCase() === LESSON_TYPE.FLEX.toLowerCase())
      : LESSON_TYPE_OPTIONS
  }

  get LessonTypeFormValue() {
    return (this.HideF2FOrFlexOption && this.Instructor.IsPrimaryLCLiveOnline) || this.Instructor.IsPrimaryLCLiveOnline
      ? AVAILABILITY_TYPE.ONLINE.fullName
      : ''
  }

  get LearningCenterOptions() {
    return this.Instructor.LearningCenters.map(({ Id, Name }) => ({
      id: Id,
      label: Name,
      value: Id,
    }))
  }

  get AvailabilityEndDate() {
    const EndDateWithBerlitz = moment(this.Instructor.EndDateWithBerlitz)
    const EndDateOneYear = moment(this.timeRange?.end?.clone()).add(1, 'year')
    return this.Instructor.EndDateWithBerlitz &&
      EndDateWithBerlitz.isValid() &&
      EndDateWithBerlitz.isBefore(EndDateOneYear, 'date')
      ? EndDateWithBerlitz
      : EndDateOneYear
  }

  get TimeOffStartDate() {
    return this.FormStartDate.clone().tz(this.Instructor.TimeZone)
  }

  get TimeOffEndDate() {
    return this.FormStartDate.clone().tz(this.Instructor.TimeZone)
  }

  get FormStartDate() {
    return moment(this.timeRange?.start?.clone())
  }

  get FormEndDate() {
    return this.isTimeOff ? this.TimeOffEndDate : this.AvailabilityEndDate
  }

  get FormStartTime() {
    return moment(this.timeRange?.start?.clone())
  }

  get FormEndTime() {
    return moment(this.timeRange?.end?.clone())
  }

  get DefaultFormValues() {
    return {
      StartTime: this.FormStartTime,
      EndTime: this.FormEndTime,
      AllDay: false,
      LessonType: this.LessonTypeFormValue,
      AvailabilityType: this.Instructor.IsFlexOnly ? LESSON_TYPE.FLEX : LESSON_TYPE.CORE,
      StartDate: this.FormStartDate,
      EndDate: this.FormEndDate,
      RepeatOn: [],
      KeepLessons: true,
      UnAvailabilityType: this.UnAvailabilityTypeValue,
      Id: '',
      LearningCenterId: this.Instructor.PrimaryLearningCenter?.Id || '',
      LearningCenterName: this.Instructor.PrimaryLearningCenter?.Name || '',
    }
  }

  get AvailabilityFormValues() {
    return {
      ...this.DefaultFormValues,
      StartDate: this.FormStartDate,
      EndDate: this.AvailabilityEndDate,
      RepeatOn: [parseInt(moment(this.FormStartDate).format('e'), 10)],
    }
  }

  get TimeOffFormValues() {
    return {
      ...this.DefaultFormValues,
      StartDate: this.FormStartDate,
      EndDate: this.FormStartDate,
      RepeatOn: REPEAT_OPTIONS.map(({ value }) => value),
    }
  }

  get UnAvailabilityTypeValue() {
    return this.Instructor.isFreelancer() ? 'Private' : ''
  }

  get UnAvailabilityTypeOptions() {
    if (this.Instructor.isFreelancer()) {
      return TYPE_OPTIONS.filter(({ id = '' }) => id.toLowerCase() === 'private')
    }

    return TYPE_OPTIONS
  }

  getDefaultFormValues(isTimeOff: boolean) {
    return { ...(isTimeOff ? this.TimeOffFormValues : this.AvailabilityFormValues), KeepLessons: false }
  }

  isDateValid(date: string = '') {
    return !!date.match(/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/)
  }
}

export default PoratlAvailabilityForm
