import Error from '@components/ErrorPublic'
import PageOverlay from '@components/PageOverlay'
import { StoreProps } from '@interfaces/StoreState'
import { GET_USER_PERMISSIONS } from '@queries/permissions'
import { fetchPermissons, updatePermissions } from '@redux/actions/permissions'
import { isPagePublic } from '@utils/page-config'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Query, QueryResult } from 'react-apollo'
import { useDispatch, useSelector } from 'react-redux'

const PermissionComponent: React.FC<QueryResult> = ({ data, error, children }) => {
  const dispatch = useDispatch()
  const [fetchedPermissions, setFetchedPermissions] = useState(null)

  useEffect(() => {
    setFetchedPermissions(data?.userPermissions)
  }, [])

  useEffect(() => {
    if (data?.userPermissions && !isEqual(fetchedPermissions, data?.userPermissions)) {
      dispatch(updatePermissions(data.userPermissions))
      setFetchedPermissions(data.userPermissions)
    }
  }, [data])

  if (error) {
    return <Error statusCode={403} />
  }

  if (fetchedPermissions) {
    return <>{children}</>
  }

  return <PageOverlay />
}

const Permissions: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { userPermissions } = useSelector(({ permissions }: StoreProps) => ({
    userPermissions: permissions?.permissions,
  }))

  useEffect(() => {
    isEmpty(userPermissions) && dispatch(fetchPermissons())
  }, [])

  const isPublicPage = isPagePublic(router.pathname)

  if (isPublicPage || !isEmpty(userPermissions)) {
    return <>{children}</>
  }

  return (
    <Query query={GET_USER_PERMISSIONS} fetchPolicy="no-cache">
      {result => {
        return <PermissionComponent {...result}>{children}</PermissionComponent>
      }}
    </Query>
  )
}

export default Permissions
