import * as React from 'react'
import { TSize } from './interface'

import ModalConsumer from './ModalConsumer'

export interface WithModalProps {
  modal: {
    toggleModal: (open: boolean, content?: React.ReactNode, size?: TSize) => void
    changeContent: (content: React.ReactNode, size?: TSize) => void
  }
}

const withModalManager = (Component: React.ElementType) => (props) => (
  <ModalConsumer>{(context: WithModalProps) => <Component modal={context} {...props} />}</ModalConsumer>
)

export default withModalManager
