import React from 'react'
import { HR, StyledA, LinkWrapper, FooterWrapper, FooterPadding } from './style'
import { FormattedMessage } from 'react-intl'

const PageFooter: React.FC = () => {
  return (
    <FooterPadding>
      <FooterWrapper>
        <HR />
        <LinkWrapper>
          <StyledA href="https://www.berlitz.com/en-de/data-protection" target="_blank">
            <FormattedMessage id="Privacy" defaultMessage="Privacy" />
          </StyledA>
        </LinkWrapper>
      </FooterWrapper>
    </FooterPadding>
  )
}

export default PageFooter
