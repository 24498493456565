import {
  UPDATE_FMI_BULK_FORM_INSTRUCTORS,
  UPDATE_FMI_BULK_FORM_INSTRUCTOR_TYPES,
  UPDATE_FMI_BULK_FORM_SELECTED_DATE,
} from '@redux/actions/fmiBulkForm'

export const initialState = {
  instructors: [],
  favouriteInstructors: [],
  pastInstructors: [],
  mostAvailableInstructors: [],
  selectedDate: '',
}

function fmiBulkFormReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FMI_BULK_FORM_INSTRUCTORS:
      return Object.assign({}, state, {
        ...state,
        instructors: action.instructors || [],
      })

    case UPDATE_FMI_BULK_FORM_INSTRUCTOR_TYPES:
      return Object.assign({}, state, {
        ...state,
        favouriteInstructors: action.favouriteInstructors || [],
        pastInstructors: action.pastInstructors || [],
        mostAvailableInstructors: action.mostAvailableInstructors || [],
      })

    case UPDATE_FMI_BULK_FORM_SELECTED_DATE:
      return Object.assign({}, state, {
        ...state,
        selectedDate: action.selectedDate || '',
      })

    default:
      return state
  }
}

export default fmiBulkFormReducer
