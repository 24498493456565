import { H3, H4 } from '@berlitz/globals'
import styled, { css } from 'styled-components'

import { TableProps } from './interface'

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
export const StyledTd = styled.td`
  a {
    text-decoration: underline;
  }
`

export const Student = styled.div`
  width: ${({ theme }) => theme.layout.xxl};
  margin-bottom: ${({ theme }) => theme.space.sm};

  a {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: ${({ theme }) => theme.colors.brandPrimary};
  }

  svg {
    margin-right: ${({ theme }) => theme.space.xs};
  }
`

export const Students = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.space.lg};
`

export const MaxContent = styled.div`
  width: max-content;
`

export const Table = styled.table<TableProps>`
  width: 100%;
  border-spacing: 0px;
  margin-bottom: ${({ theme }) => theme.space.lg};

  tr {
    td {
      padding: ${({ theme }) => `${theme.space.xxxs} ${theme.space.sm} ${theme.space.xxxs} 0`};
      font-size: ${({ theme }) => theme.fontSize.xxs};
      vertical-align: top;

      ${({ theme, bordered }) =>
        bordered &&
        css`
          border-bottom: 2px solid ${theme.palette.blue20};
        `}

      ${({ theme, padded }) =>
        padded &&
        css`
          padding: ${theme.space.xs} ${theme.space.sm} ${theme.space.xs} 0;
        `}

      &:first-child {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }
  }
`

export const Box = styled.div`
  ${H3} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  ${H4} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

export const EPContainer = styled.div`
  height: 226px;
`

export const TextContainer = styled.div`
  word-break: normal;
`
