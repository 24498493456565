import Button from '@berlitz/button'
import { H1 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import InfoIcon from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/14-Alerts/InformationCircle'
import CheckIcon from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/33-Form-Validation/CheckCircle1'
import { Case } from '@interfaces/Cases'
import { PUT_CASE } from '@mutations/cases'
import omitDeep from '@utils/omit-deep'
import * as React from 'react'
import { Mutation } from 'react-apollo'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { ModalFooterWrapper, ModalIconWrapper, ModalWrapper } from './style'

interface ResolveCaseProps extends InjectedIntlProps {
  open: boolean
  onClose: Function
  onSuccess: Function
  data: Case
}

const ResolveCaseModal: React.FC<ResolveCaseProps> = ({ open, onClose, onSuccess, data }) => {
  const [reason, setReason] = React.useState('No longer valid')
  const [caseDone, setCaseDone] = React.useState(false)
  const closeModal = () => {
    setReason('No longer valid')
    setCaseDone(false)
    onClose()
  }

  const handleCasePostSubmit = () => {
    onSuccess()
    setCaseDone(true)
  }

  return (
    <Modal open={open} size="sm" onClose={closeModal}>
      {caseDone ? (
        <ModalWrapper>
          <ModalIconWrapper type="success">
            <CheckIcon />
          </ModalIconWrapper>
          <Spacer size="lg" />
          <H1 disableMargin>
            <FormattedMessage id="Your case has been resolved" />
          </H1>
        </ModalWrapper>
      ) : (
        <ModalWrapper>
          <ModalIconWrapper>
            <InfoIcon />
          </ModalIconWrapper>
          <Spacer size="lg" />
          <H1 disableMargin>
            <FormattedMessage id="Resolve case" />
          </H1>
          <Spacer size="md" />
          <FormattedMessage id="Are you sure you want to resolve this case?" />
          <Spacer size="xxs" />
          <FormattedMessage id="Resolved cases cannot be re-opened." />
          <Spacer size="md" />
          <Mutation mutation={PUT_CASE}>
            {(putCase: any, { loading: putCaseLoading }) => {
              // TODO: figure apollo setup to remove __typename that disrupts mutation
              // https://github.com/apollographql/apollo-client/issues/1913#issuecomment-374869527
              // pass original case data here and not the transformed one
              let currentCase = omitDeep(data, '__typename')
              currentCase = omitDeep(currentCase, 'isUser')
              currentCase = omitDeep(currentCase, 'itemId')

              const updateStatus = async () => {
                await putCase({
                  variables: {
                    caseInput: [
                      {
                        FileInfos: [],
                        CaseInfo: {
                          ...currentCase,
                          Status: 'Closed',
                          ClosureReason: reason,
                          LastModifieDatetime: undefined,
                        },
                      },
                    ],
                  },
                })

                handleCasePostSubmit()
              }
              return (
                <>
                  <Spacer size="xs" />
                  <ModalFooterWrapper>
                    <Button onClick={closeModal} disabled={putCaseLoading} outline>
                      <FormattedMessage id="No, keep case open" />
                    </Button>
                    <Button canLoad loading={putCaseLoading ? 1 : 0} onClick={() => updateStatus()}>
                      <FormattedMessage id="Yes, resolve case" />
                    </Button>
                  </ModalFooterWrapper>
                </>
              )
            }}
          </Mutation>
          <Spacer size="lg" />
        </ModalWrapper>
      )}
    </Modal>
  )
}

export default injectIntl(ResolveCaseModal)
