import React from 'react'
import PropTypes from 'prop-types'
import { Consumer } from './GroupContext'
import { Input, GroupLabel } from './style'

const Button = ({ children, ...rest }) => (
  <Consumer>
    {({ name, value, onChange, fullWidth }) => {
      const optional = {}
      if (value !== undefined) {
        optional.checked = rest.value === value
      }

      if (typeof onChange === 'function') {
        optional.onChange = onChange
      }

      return (
        <>
          <Input
            {...rest}
            id={rest.id || rest.value}
            aria-checked={optional.checked}
            type="radio"
            name={name}
            {...optional}
          />
          <GroupLabel
            htmlFor={rest.id || rest.value}
            disabled={rest.disabled}
            pills={rest.pills}
            checked={optional.checked}
            fullWidth={fullWidth}
            small={rest.small}
          >
            {children}
          </GroupLabel>
        </>
      )
    }}
  </Consumer>
)

Button.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  pills: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Button
