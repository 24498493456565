import { UPDATE_SINGLE_LESSON_REDIRECTS } from '@redux/actions/singleLesson'

export const initialState = {
  redirects: [],
}

function intlReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SINGLE_LESSON_REDIRECTS:
      return Object.assign({}, state, {
        ...state,
        redirects: [...state.redirects, action.url],
      })
    default:
      return state
  }
}

export default intlReducer
