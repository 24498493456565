import { useApolloClient } from '@apollo/react-hooks'
import { ApolloError, ApolloQueryResult } from 'apollo-client'
import { useState } from 'react'

type IUseCustomLazyQuery = <Type = any>(
  query: any
) => [
  (variables: object) => Promise<void>,
  { loading: boolean; data: Type | undefined; called: boolean; error: ApolloError | undefined }
]

const useCustomLazyQuery: IUseCustomLazyQuery = <Type>(query: any) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApolloError | undefined>()
  const [data, setData] = useState<Type>()
  const [called, setCalled] = useState(false)
  const client = useApolloClient()

  const getQuery = async (variables: object) => {
    let res: ApolloQueryResult<Type> | undefined
    setCalled(true)
    setLoading(true)
    try {
      res = await client.query<any>({
        query,
        variables,
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      setError(e as ApolloError)
    }
    setData(res?.data)
    setLoading(false)
  }

  return [getQuery, { loading, data, called, error }]
}

export default useCustomLazyQuery
