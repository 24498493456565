import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  border: 2px solid ${({ theme }) => theme.colors.brandPrimary};
  border-radius: ${({ theme }) => theme.radius.md};
  display: flex;
  align-items: stretch;
`

export const Details = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.space.xxs} ${theme.space.xs}`};
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Timezone = styled.div`
  font-weight: normal;
`

export const Label = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const Time = styled.div`
  color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  background: ${({ theme }) => theme.colors.brandPrimary};
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0;
  padding: ${({ theme }) => `0 ${theme.space.xs}`};
  margin-left: auto;
  font-size: ${({ theme }) => theme.fontSize.xs};
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.space.xs};
  row-gap: ${({ theme }) => theme.space.xxxs};
  padding: ${({ theme }) => `0 ${theme.space.xs}`};
`
