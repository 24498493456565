import { WarningModal } from '@components/Modal'
import WithUserTime, { WithUserTimeProps } from '@hocs/WithUserTime'
import { StoreProps } from '@interfaces/StoreState'
import { getTZLocalstorageKey, PROFILE_TYPE } from '@utils/constants'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

const TimezoneCheck: React.FC<WithUserTimeProps> = ({ isProfileTimeSameWithBrowser }) => {
  const KEEP = 'KEEP'
  const { profile, Timezone } = useSelector(({ userProfile }: StoreProps) => ({
    profile: userProfile?.info,
    Timezone: userProfile?.info?.Timezone,
  }))
  const lsKey = getTZLocalstorageKey(profile?.SFId)

  const showModal = () =>
    (profile?.InstructorProfile || profile?.StudentProfile) &&
    !isProfileTimeSameWithBrowser() &&
    localStorage.getItem(lsKey) !== KEEP

  const [open, setOpen] = useState(showModal())

  useEffect(() => {
    setOpen(showModal())
  }, [Timezone])

  const intl = useIntl()
  return (
    <WarningModal
      open={open}
      cancelOutline
      proceedBtnType="default"
      onClose={() => {
        setOpen(false)
      }}
      onCancel={() => {
        localStorage.setItem(lsKey, KEEP)
        setOpen(false)
      }}
      title={intl.formatMessage({ id: 'Timezone mismatch' })}
      description={
        <>
          <FormattedMessage
            id="modal-timezone-warning"
            defaultMessage="Your computer's timezone does not match your account setting of {timezone}"
            values={{
              timezone: (profile?.Timezone ?? '').replace(/_/gi, ' '),
            }}
          />
          <br />
          <br />
          <FormattedMessage id="What would you like to do?" />
        </>
      }
      cancelLabel={intl.formatMessage({ id: 'Keep timezone' })}
      proceedLabel={intl.formatMessage({ id: 'Change timezone' })}
      onProceed={() => {
        setOpen(false)
        Router.push(profile?.Type === PROFILE_TYPE.STUDENT ? '/settings/account' : '/instructors/settings/account')
      }}
    />
  )
}

export default WithUserTime(React.memo(TimezoneCheck))
