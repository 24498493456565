import PortalTheme from '@berlitz/max-theme-portals'
import styled, { css } from 'styled-components'

const backgroundColors = [PortalTheme.colors.brandPrimary, PortalTheme.palette.red100, PortalTheme.palette.purple100]

// leaving this function incase we really need it for the avatar background
const getBgColor = (str) => {
  if (!str) {
    return `transparent`
  }

  const hash: any =
    str &&
    Array.from(str).reduce(
      // eslint-disable-next-line no-bitwise
      // @ts-ignore
      (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
      0
    )

  return backgroundColors[hash % backgroundColors.length]
}

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  border-radius: ${({ theme }) => theme.layout.xxxl};
  background-color: ${({ theme }) => theme.palette.gray100};

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

interface WrapperProps {
  size?: string
  grayed?: boolean
  img?: string
  name?: string
  squared?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  type?: string
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  height: ${({ size, theme }) => size || theme.layout.xxl};
  width: ${({ size, theme }) => size || theme.layout.xxl};
  font-size: ${({ theme }) => theme.space.sm};
  border: ${({ theme, grayed }) => `1px solid ${grayed ? theme.palette.gray100 : theme.palette.blue40}`};
  justify-content: center;
  color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${({ img, size }) =>
    img &&
    css`
      background-image: url(${img});
      background-repeat: no-repeat;
      background-size: ${img.includes('tinygraphs') ? `calc(${size} + 4px) calc(${size} + 4px)` : 'cover'};
      background-position: ${img.includes('tinygraphs') ? `28% center` : 'center'};
    `};
  background-color: ${({ theme }) => theme.palette.red100};
  border-radius: ${({ squared, theme }) => (squared ? theme.space.xxxs : '50%')};
  svg {
    color: ${({ theme, grayed }) => (grayed ? theme.palette.gray100 : theme.palette.blue40)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: ${({ md, size, theme }) => md || size || theme.layout.md};
    width: ${({ md, size, theme }) => md || size || theme.layout.md};
    font-size: ${({ theme }) => theme.space.md};
    svg {
      font-size: ${({ theme }) => theme.space.xxl};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: ${({ lg, md, size, theme }) => lg || md || size || theme.layout.lg};
    width: ${({ lg, md, size, theme }) => lg || md || size || theme.layout.lg};
    font-size: ${({ theme }) => theme.space.lg};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: ${({ xl, lg, md, size, theme }) => xl || lg || md || size || theme.layout.xl};
    width: ${({ xl, lg, md, size, theme }) => xl || lg || md || size || theme.layout.xl};
    font-size: ${({ theme }) => theme.space.xl};
  }
`
/* eslint-enable */
