import * as React from 'react'
import ContentLoader from 'react-content-loader'
import { IconLoaderProp } from './interface'

export const CourseLinkLoader: React.FunctionComponent<IconLoaderProp> = ({ height }) => (
  <ContentLoader height={70} width={120} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" style={{ height }}>
    <rect x="13" y="12" rx="4" ry="4" width="40" height="10" />
    <rect x="4" y="28" rx="4" ry="4" width="49" height="10" />
    <rect x="18" y="44" rx="4" ry="4" width="35" height="10" />
    <circle cx="87" cy="35" r="30" />
  </ContentLoader>
)

export const CircleIconLoader: React.FunctionComponent<IconLoaderProp> = ({ height }) => (
  <ContentLoader height={70} width={70} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" style={{ height }}>
    <circle cx="35" cy="38" r="30" />
  </ContentLoader>
)
