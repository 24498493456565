import { RadioWrapper } from '@components/InstructorScheduler/style'
import React from 'react'
import { useIntl } from 'react-intl'
import { WarningModalContent } from '../WarningModal'

interface IProps {
  onClose: Function
  onProceed: Function
  title: string
}

const AvailabilityBulkDeleteWarning: React.FC<IProps> = ({ onClose = () => {}, onProceed = () => {}, title }) => {
  const intl = useIntl()
  return (
    <WarningModalContent
      onClose={onClose}
      onProceed={onProceed}
      proceedLabel={intl.formatMessage({ id: 'Delete' })}
      proceedBtnType="error"
      title={title || intl.formatMessage({ id: 'Delete availability' })}
      subtitle={
        <RadioWrapper>
          <div>
            {intl.formatMessage({
              id: 'Are you sure you want to continue?',
            })}
          </div>
          <div>
            {intl.formatMessage({
              id: 'This action cannot be undone.',
            })}
          </div>
        </RadioWrapper>
      }
    />
  )
}

export default AvailabilityBulkDeleteWarning
