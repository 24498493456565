import includes from 'lodash/includes'
import { InstructorInfo, InstructorLearningCenters } from '@interfaces/Instructor'
import { LessonTypeOptions, AvailabilityTypeOptions } from './constants'
import get from 'lodash/get'
import { AvailabilitySchedule } from '@components/InstructorScheduler/interface'
import { UserProfileProps } from '@interfaces/UserProfile'
import { canModifyFlex } from '@components/InstructorScheduler/util'
import { isEmpty } from 'lodash'

const tlc = (s: string = '') => s.toLowerCase()
const LiveOnlineVirtualCenter = tlc('Live Online Virtual Center')

export const hideF2FOrFlexOption = (instructorInfo: InstructorInfo, _isAdmin: boolean = false) => {
  const { ContractType = '', AvailabilityType = '', AvailabilityOption = '' } = instructorInfo
  const type = AvailabilityType.split(';').map((i) => tlc(i))
  const option = AvailabilityOption.split(';').map((i) => tlc(i))
  const { Name } = (instructorInfo.learningcenters || []).find(({ IsPrimary }) => IsPrimary) || { Name: '' }

  const isCG = tlc(ContractType) === tlc('Contracted Guaranteed')
  const isCnotG = tlc(ContractType) === tlc('Contracted Not Guaranteed')
  const isFreelance = tlc(ContractType) === tlc('Freelance')
  const isCore = includes(type, tlc('Core'))
  const isTentativeOnline = includes(option, tlc('Tentative - Online'))
  const isTentativeF2F = includes(option, tlc('Tentative - Face to Face'))

  const isAvailableGuaranteedF2F = includes(option, tlc('Available(Guaranteed) - F2F'))
  const isAvailableContractedF2F = includes(option, tlc('Available(Contracted) - F2F'))
  const isAvailableContractedOnline = includes(option, tlc('Available(Contracted) - Online'))
  const isLiveOnlineVC = tlc(Name) === LiveOnlineVirtualCenter

  // 3 Flex
  const Scenario1 = isCnotG && !isCore && isAvailableContractedOnline && isLiveOnlineVC

  // 4 Flex
  const Scenario2 = isCnotG && !isCore && isAvailableContractedF2F

  // 5 Flex
  const Scenario3 = isFreelance && !isCore && isTentativeOnline && isLiveOnlineVC

  // 6 Flex
  const Scenario4 = isFreelance && !isCore && isTentativeF2F

  // 1 Core
  const Scenario5 = isCG && isCore && isAvailableGuaranteedF2F && isLiveOnlineVC && !isFreelance

  return Scenario1 || Scenario2 || Scenario3 || Scenario4 || Scenario5
}

export const getPrimaryLC = (learningcenters: InstructorLearningCenters[] = []): InstructorLearningCenters => {
  return (
    learningcenters.find(({ IsPrimary }) => IsPrimary !== void 0) || {
      Id: '',
      IsPrimary: false,
      Name: '',
      TimeZone: '',
    }
  )
}

export const getLessonTypeOptions = (instructorInfo: InstructorInfo, isAdmin: boolean = false) => {
  const isFreelance = tlc(instructorInfo.ContractType || '') === tlc('Freelance')
  const primaryLC = getPrimaryLC(instructorInfo.learningcenters)
  return (hideF2FOrFlexOption(instructorInfo, isAdmin) &&
    tlc(get(primaryLC, 'Name', '') || '') === LiveOnlineVirtualCenter) ||
    tlc(get(primaryLC, 'Name', '') || '') === LiveOnlineVirtualCenter
    ? LessonTypeOptions.filter(({ id }) => id === 'Online')
    : isFreelance
      ? LessonTypeOptions.filter(({ id }) => id === 'Online')
      : LessonTypeOptions
}

export const getAvailabilityTypeOptions = (instructorInfo: InstructorInfo, isAdmin: boolean = false) => {
  const isFreelance = tlc(instructorInfo.ContractType || '') === tlc('Freelance')
  const primaryLC = getPrimaryLC(instructorInfo.learningcenters)
  return hideF2FOrFlexOption(instructorInfo, isAdmin) &&
    !isAdmin &&
    tlc(get(primaryLC, 'Name', '') || '') === LiveOnlineVirtualCenter
    ? AvailabilityTypeOptions.filter(({ id }) => id !== 'Flex')
    : isFreelance
      ? AvailabilityTypeOptions.filter(({ id }) => id === 'Flex')
      : AvailabilityTypeOptions
}

export const hideAvailabilityForm = (
  profile: UserProfileProps,
  isAdmin: boolean = false,
  resource?: AvailabilitySchedule
) => {
  const instructorInfo = profile.InstructorProfile?.instructorInfo
  const ContractType = instructorInfo?.ContractType
  const AvailabilityType = instructorInfo?.AvailabilityType || ''
  const AvailabilityOption = instructorInfo?.AvailabilityOption || ''
  const type = AvailabilityType.split(';').map((i) => tlc(i))
  const option = AvailabilityOption.split(';').map((i) => tlc(i))
  const { Name } = (instructorInfo?.learningcenters || []).find(({ IsPrimary }) => IsPrimary) || { Name: '' }

  const isCG = tlc(ContractType) === tlc('Contracted Guaranteed')
  const isCore = includes(type, tlc('Core'))
  const isAvailableGuaranteedF2F = includes(option, tlc('Available(Guaranteed) - F2F'))
  const isAvailableGuaranteedOnline = includes(option, tlc('Available(Guaranteed) - Online'))

  const isLiveOnlineVC = tlc(Name) === LiveOnlineVirtualCenter

  // 1 Flex
  const Scenario1 = isCG && !isCore && isAvailableGuaranteedF2F

  // 2 Flex
  const Scenario2 = isCG && !isCore && isAvailableGuaranteedOnline

  // 1 Core
  const Scenario3 = isCG && isCore && isAvailableGuaranteedF2F && !isAdmin

  // 2 Core
  const Scenario4 = isCG && isCore && isAvailableGuaranteedOnline && isLiveOnlineVC && !isAdmin

  return (
    Scenario1 ||
    Scenario2 ||
    Scenario3 ||
    Scenario4 ||
    (!canModifyFlex(profile, isAdmin, resource) && isEmpty(resource))
  )
}
